import React, { useEffect, useState } from "react";
import AccordianTab from "../../styledComponent/Form/AccordianTab";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import ItrStatus from "./ItrStatus";
import { getItrStatusAction } from "../../redux/profile/action";
import LoadingBox from "../DialogBox/LoadingBox";
export default function TrackStatus() {
  const [trackStatus, setTrackStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      getItrStatusAction((e) => {
        if (e.status == 200) {
          setTrackStatus(e.data);
        }
        setLoading(false);
      })
    );
  }, []);

  return (
    <Root>
      <LoadingBox open={loading} />

      <AccordianTab title={"Track Status"}>
        <div>
          {trackStatus.map((i, ix) => {
            return (
              <div key={ix}>
                <ItrStatus data={i} />
              </div>
            );
          })}
        </div>
      </AccordianTab>
    </Root>
  );
}
const Root = styled.div``;
