import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { removeEmptyKeys } from "../../../../../utils/helpers";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import Input from "../../../../../styledComponent/Inputs/Input";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../../styledComponent/Inputs/Select";
import AccordianBox from "../../../../../styledComponent/Form/AccordianBox";
import {
  getScheduleOIAction,
  saveScheduleOIAction,
} from "../../../../../redux/itr/action";
import { toast } from "react-toastify";
import * as Yup from "yup";

export default function ScheduleOI({  handleCallback }) {
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState({});
  const [scheduleOIData, setScheduleOIData] = useState({});
  const [scheduleFormData, setScheduleFormData] = useState({});

  const { ItrData } = useSelector((s) => s.itr);
  const { strings } = useSelector((s) => s.platformSetting);

  const handleSubmitForm = (obj) => {
    setLoader(true);
    let { type, id, ...data } = obj;
    dispatch(
      saveScheduleOIAction(data, (e) => {
        if (e.status === 200) {
          handleCallback(e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const validationSchema = Yup.object().shape({
    methodOfAccounting: Yup.string().required(
      strings.validation_message.required
    ),
    changeInMethodOfAccounting: Yup.string().required(
      strings.validation_message.required
    ),
    changeInMethodOfStockValuation: Yup.string().required(
      strings.validation_message.required
    ),
    methodOfValuaitonFinishedGoods: Yup.string().required(
      strings.validation_message.required
    ),
    methodOfValuationRawMaterial: Yup.string().required(
      strings.validation_message.required
    ),
    decreaseIncreaseLossSection145A: Yup.number()
      .required(strings.validation_message.required)
      .typeError(strings.validation_message.invalid_number),
    increaseDecreaseProfitSection145A: Yup.number()
      .required(strings.validation_message.required)
      .typeError(strings.validation_message.invalid_number),
  });

  const dispatch = useDispatch();

  const getScheduleOIData = async () => {
    setLoader(true);
    dispatch(
      getScheduleOIAction(ItrData.id, (e) => {
        if (e.status == 200) {
          let { scheduleOI: data, ...scheduleOptionData } = e.data;
          setScheduleOIData(scheduleOptionData);
          setScheduleFormData(data);
          setFormData({
            section33AB_13i: data?.section33AB_13i || "",
            section33ABA_13ii: data?.section33ABA_13ii || 0,
            amountOfProfitSection41_14: data?.amountOfProfitSection41_14 || "",
            amountPriorPeriodCreditDebit_15:
              data?.amountPriorPeriodCreditDebit_15 || "",
            amountOfExpenditureDisallowed14A_16:
              data?.amountOfExpenditureDisallowed14A_16 || "",
            taxAndDuty_12a: data?.taxAndDuty_12a || "",
            taxAndDuty_12b: data?.taxAndDuty_12b || "",
            taxAndDuty_12c: data?.taxAndDuty_12c || "",
            taxAndDuty_12d: data?.taxAndDuty_12d || "",
            taxAndDuty_12e: data?.taxAndDuty_12e || "",
            taxAndDuty_12f: data?.taxAndDuty_12f || "",
            taxAndDuty_12g: data?.taxAndDuty_12g || "",
            taxAndDuty_12h: data?.taxAndDuty_12h || "",
            amountNotCreditedProfitLoss5a:
              data?.amountNotCreditedProfitLoss5a || "",
            amountNotCreditedProfitLoss5b:
              data?.amountNotCreditedProfitLoss5b || "",
            amountNotCreditedProfitLoss5c:
              data?.amountNotCreditedProfitLoss5c || "",
            amountNotCreditedProfitLoss5d:
              data?.amountNotCreditedProfitLoss5d || "",
            decreaseIncreaseLossSection145A:
              data?.decreaseIncreaseLossSection145A || "",
            increaseDecreaseProfitSection145A:
              data?.increaseDecreaseProfitSection145A || "",
            methodOfValuaitonFinishedGoods:
              data?.methodOfValuaitonFinishedGoods || "",
            changeInMethodOfStockValuation:
              data?.changeInMethodOfStockValuation || "",
            methodOfValuationRawMaterial:
              data?.methodOfValuationRawMaterial || "",
            effectOnProfitSection145_2: data?.effectOnProfitSection145_2 || "",
            changeInMethodOfAccounting: data?.changeInMethodOfAccounting || "",
            methodOfAccounting: data?.methodOfAccounting || "",
            itrId: ItrData?.id,
          });

          setLoader(false);
        } else {
          toast.error(e.message);
          setLoader(false);
        }
      })
    );
  };

  useEffect(() => {
    getScheduleOIData();
  }, []);

  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <StyleFormBox>
              <div className="flex_input my-3">
                <label>
                  1. Method of accounting employed in the previous year *
                </label>
                <Select
                  name={"methodOfAccounting"}
                  error={errors.methodOfAccounting}
                  value={values?.methodOfAccounting}
                  onChange={handleChange}
                >
                  <option value={""} disabled>
                    {strings.label.choose_option}
                  </option>
                  {scheduleOIData?.methodOfAccounting?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="flex_input my-3">
                <label>2. Is there any change in Method of Accounting * </label>
                <Select
                  name={"changeInMethodOfAccounting"}
                  error={errors.changeInMethodOfAccounting}
                  value={values?.changeInMethodOfAccounting}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {scheduleOIData?.changeInMethodOfAccounting?.map(
                    (item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    )
                  )}
                </Select>
              </div>
              <div className="flex_input my-3">
                <label>
                  3. Effect on the profit because of deviation, if any, as per
                  Income Computation Disclosure Standards notified under section
                  145(2) *
                </label>
                <h4 className="text-right">$0</h4>
              </div>
              <label>
                4. Method of valuation of closing stock employed in the previous
                year *
              </label>
              <div className="px-4">
                <div className="flex_input my-3">
                  <label>
                    4(a). Raw Material (if at cost or market rates whichever is
                    less write 1, if at cost write 2, if at market rate write 3)
                  </label>
                  <Select
                    name={"methodOfValuationRawMaterial"}
                    error={errors.methodOfValuationRawMaterial}
                    value={values?.methodOfValuationRawMaterial}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>

                    {scheduleOIData?.rawMaterial?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="flex_input my-3">
                  <label>
                    4(b). Finished goods (if at cost or market rates whichever
                    is less write 1, if at cost write 2, if at market rate write
                    3) *
                  </label>
                  <Select
                    name={"methodOfValuaitonFinishedGoods"}
                    error={errors.methodOfValuaitonFinishedGoods}
                    value={values?.methodOfValuaitonFinishedGoods}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>
                    {scheduleOIData?.finishedGoods?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="flex_input my-3">
                  <label>
                    4(c). Is there any change in Method of Stock Valuation *
                  </label>
                  <Select
                    name={"changeInMethodOfStockValuation"}
                    error={errors.changeInMethodOfStockValuation}
                    value={values?.changeInMethodOfStockValuation}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>

                    {scheduleOIData?.changeInMethodOfStockValuation?.map(
                      (item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      )
                    )}
                  </Select>
                </div>
                <div className="flex_input my-3">
                  <label>
                    4(d). Increase in the profit or decrease in loss because of
                    deviation, if any, from the method of valuation specified
                    under section 145A *
                  </label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="increaseDecreaseProfitSection145A"
                    error={errors.increaseDecreaseProfitSection145A}
                    value={values.increaseDecreaseProfitSection145A}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>
                    4(e). Decrease in the profit or increase in loss because of
                    deviation, if any, from the method of valuation specified
                    under section 145A *
                  </label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="decreaseIncreaseLossSection145A"
                    error={errors.decreaseIncreaseLossSection145A}
                    value={values.decreaseIncreaseLossSection145A}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </StyleFormBox>

            {/* Amounts not credited to the profit and loss account ---------  */}
            <AccordianBox
              title={"Amounts not credited to the profit and loss account : $0"}
            >
              <h4>
                5. Amounts not credited to the profit and loss account, being
              </h4>
              <div className="px-4">
                <div className="flex_input my-3">
                  <label>
                    5(a). The items falling within the scope of section 28 *
                  </label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="amountNotCreditedProfitLoss5a"
                    error={errors.amountNotCreditedProfitLoss5a}
                    value={values.amountNotCreditedProfitLoss5a}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>
                    5(b). The proforma credits, drawbacks, refund of duty of
                    customs or excise or service tax, or refund of sales tax or
                    value added tax, where such credits, drawbacks or refunds
                    are admitted as due by the authorities concerned
                  </label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="amountNotCreditedProfitLoss5b"
                    error={errors.amountNotCreditedProfitLoss5b}
                    value={values.amountNotCreditedProfitLoss5b}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>
                    5(c). Escalation claims accepted during the previous year
                  </label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="amountNotCreditedProfitLoss5c"
                    error={errors.amountNotCreditedProfitLoss5c}
                    value={values.amountNotCreditedProfitLoss5c}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>5(d). Any other item of income</label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="amountNotCreditedProfitLoss5d"
                    error={errors.amountNotCreditedProfitLoss5d}
                    value={values.amountNotCreditedProfitLoss5d}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>5(e). Capital receipt, if any</label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="amountNotCreditedProfitLoss5e"
                    error={errors.amountNotCreditedProfitLoss5e}
                    value={values.amountNotCreditedProfitLoss5e}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>
                    5(f). Total of amounts not credited to profit and loss
                    account(5a+5b+5c +5d+5e)
                  </label>
                  <h4 className="text-right">$0</h4>
                </div>
              </div>
            </AccordianBox>

            {/* Section 36 -------- */}
            <AccordianBox
              title={"Section 36  : $0"}
              initial={false}
              description={
                "Amounts debited to the profit and loss account, to the extent disallowable under section 36 due to non-fulfilment of conditions specified in relevant clauses"
              }
            >
              <p>
                6. Amounts debited to the profit and loss account, to the extent
                disallowable under section 36 due to non-fulfilment of
                conditions specified in relevant clauses
              </p>
              <div className="flex_input my-3">
                <label>
                  6(a). Premium paid for insurance against risk of damage or
                  dest-ruction of stocks or store [36(1)(i)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6a}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(b). Premium paid for insurance on the health of employees
                  [36(1)(ib)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6b}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(c). Any sum paid to an employee as bonus or commission for
                  services rendered, where such sum was otherwise payable to him
                  as profits or dividend [36(1)(ii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6c}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(d). Any amount of interest paid in respect of borrowed
                  capital [36(1)(iii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6d}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(e). Amount of discount on a zero-coupon bond [36(1)(iiia)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6e}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(f). Amount of contributions to a recognised provident fund
                  [36(1)(iv)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6f}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(g). Amount of contributions to an approved superannuation
                  fund [36(1)(iv)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6g}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(h). Amount of contribution to a pension scheme referred to
                  in section 80CCD [36(1)(iva)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6h}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(i). Amount of contributions to an approved gratuity fund
                  [36(1)(v)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6i}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>6(j). Amount of contributions to any other fund</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6j}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(k). Any sum received from employees as contribution to any
                  provident fund or superannuation fund or any fund set up under
                  ESI Act or any other fund for the welfare of employees to the
                  extent not credited to the employees account on or before the
                  due date [36(1)(va)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6k}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(l). Amount of bad and doubtful debts [36(1)(vii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6l}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(m). Provision for bad and doubtful debts [36(1)(viia)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6m}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(n). Amount transferred to any special reserve [36(1)(viii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6n}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(o). Expenditure for the purposes of promoting family
                  planning amongst employees [36(1)(ix)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6o}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(p). Amount of securities transaction paid in respect of
                  transaction in securities if such income is not included in
                  business income [36(1)(xv)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6p}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(q). Marked to market loss or other expected loss as computed
                  in accordance with the ICDS notified u/s 145(2) [36(1)(xviii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6q}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>6(r). Any other disallowance</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6r}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(s). Total amount disallowable under section 36 (total of 6a
                  to 6r)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section36_6s}
                </h4>
              </div>
            </AccordianBox>

            {/* Section 37 -------- */}
            <AccordianBox
              title={"Section 37  : $0"}
              initial={false}
              description={
                "Amounts debited to the profit and loss account, to the extent disallo-wable under section 37"
              }
            >
              <p>
                7. Amounts debited to the profit and loss account, to the extent
                disallowable under section 37
              </p>
              <div className="flex_input my-3">
                <label>7(a). Expenditure of capital nature [37(1)]</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section37_7a}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>7(b). Expenditure of personal nature [37(1)]</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section37_7b}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(c). Expenditure laid out or expended wholly and exclusively
                  NOT for the purpose of business or profession [37(1)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section37_7c}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(d). Expenditure on advertisement in any souvenir, brochure,
                  tract, pamphlet or the like, published by a political party
                  [37(2B)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section37_7d}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(e). Expenditure by way of penalty or fine for violation of
                  any law for the time being in force
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section37_7e}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>7(f). Any other penalty or fine</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section37_7f}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(g). Expenditure incurred for any purpose which is an offence
                  or which is prohibited by law
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section37_7g}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(h). Amount of any liability of a contingent nature
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section37_7h}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(i). Any other amount not allowable under section 37
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section37_7i}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(j). Total amount disallowable under section 37(total of 7a
                  to 7i)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section37_7j}
                </h4>
              </div>
            </AccordianBox>

            {/* Section 40 -------- */}
            <AccordianBox
              title={"Section 40  : $0"}
              initial={false}
              description={
                "Amounts debited to the profit and loss account, to the extent disallowable under section 40"
              }
            >
              <p>
                8(A). Amounts debited to the profit and loss account, to the
                extent disallowable under section 40
              </p>
              <div className="flex_input my-3">
                <label>
                  8(Aa). Disallowance of expenditure for payment outside India
                  or to a non-resident on which TDS is not deducted/deposited
                  u/s [40(a)(i)] on account of non-compliance with provisions of
                  Chapter XVII-B
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40_8Aa}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ab). Disallowance of expenditure for payment to a resident
                  on which TDS is not deducted/deposited u/s [40(a)(ia)] on
                  account of non-compliance with the provisions of Chapter
                  XVII-B
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40_8Ab}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ac). Disallowance of expenditure for non-deduction and
                  payment of equalization levy u/s [40(a)(ib)], on account of
                  non-compliance with the provisions of Chapter VIII
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.equalizationLevySection40_8Ac}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ac). Disallowance of expenditure for payment of salary
                  outside India or non-resident on which TDS is not
                  deducted/deposited u/s [40(a)(iii)] on account of
                  non-compliance with the provisions of Chapter XVII-B
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.outsideIndiaSection40_8Ac}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ad). Disallowance of amount paid as direct taxes like income
                  tax, agriculture tax, interest paid on income tax, etc. u/s
                  [40(a)(ii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40_8Ad}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ae). Disallowance of amount paid as wealth tax u/s
                  [40(a)(iia)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40_8Ae}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Af). Disallowance of amount paid by way of royalty, license
                  fee, service fee etc. By the state government undertaking to
                  state government u/s [40(a)(iib)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40_8Af}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ag). Disallowance of amount paid as interest, salary, bonus,
                  commission or remuneration paid to any partner or member u/s
                  [40(b)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40_8Ag}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ai). Total amount disallowable under section 40(total of Aa
                  to Ah)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40_8Ah}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(B). Any amount disallowed under section 40 in any preceding
                  previous year but allowable during the previous year
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40_8B}
                </h4>
              </div>
            </AccordianBox>

            {/* Section 40A ------- */}
            <AccordianBox
              title={"Section 40A  : $0"}
              initial={false}
              description={
                " Amounts debited to the profit and loss account, to the extent disallowable under section 40A"
              }
            >
              <p>
                9. Amounts debited to the profit and loss account, to the extent
                disallowable under section 40A
              </p>

              <div className="flex_input my-3">
                <label>
                  9(a). Disallowance of excessive expenditure made to relatives
                  or close associates u/s 40A(2)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40A_9a}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  9(b). Disallowance of amount paid in excess of ten thousand
                  rupees otherwise than by account payee cheque or account payee
                  bank draft u/s [40A(3)] 100% disallowable
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40A_9b}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  9(c). Disallowance of provision for payment of gratuity u/s
                  [40A(7)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40A_9c}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  9(d). Disallowance of any sum paid by the assessed as an
                  employer for setting up or as contribution to any fund, trust,
                  company, AOP, or BOI or society or any other institution u/s
                  [40A(9)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40A_9d}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>9(e). Any other disallowance</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + scheduleFormData?.section40A_9e}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  9(f). Total amount disallowable under section 40A (total of 9a
                  to 9e)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.totalAmountsection40A_9ato9e}
                </h4>
              </div>
            </AccordianBox>

            {/* Section 43B Allowed -------  */}

            <AccordianBox
              title={"Section 43B Allowed  : $0"}
              initial={false}
              description={
                "Any amount disallowed under section 43B in any preceding previous year but allowable during the previous year"
              }
            >
              <p>
                10. Any amount disallowed under section 43B in any preceding
                previous year but allowable during the previous year
              </p>

              <div className="flex_input my-3">
                <label>
                  10(a). Any sum in the nature of tax, duty, cess or fee under
                  any law
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43BAllowed_10a}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  10(b). Any sum payable by way of contribution to any provident
                  fund or superannuation fund or gratuity fund or any other fund
                  for the welfare of employees
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43BAllowed_10b}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  10(c). Any sum payable to an employee as bonus or commi- ssion
                  for services rendered
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43BAllowed_10c}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  10(d). Any sum payable as interest on any loan or borrowing
                  from any public financial institution or a State financial
                  corporation or a State Industrial investment corporation
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43BAllowed_10d}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  10(e). Any sum payable as interest on any loan or borrowing
                  from any scheduled bank
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43BAllowed_10e}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>10(f). Any sum payable towards leave encashment</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43BAllowed_10f}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  10(g). Any sum payable to the Indian Railways for the use of
                  railway assets
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43BAllowed_10g}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  10(h). Total amount allowable under section 43B (total of 10a
                  to 10g)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.totalAmountAllowed_10ato10g}
                </h4>
              </div>
            </AccordianBox>

            {/* Section 43B Disallowed --------- */}
            <AccordianBox
              title={"Section 43B Disallowed  : $0"}
              initial={false}
              description={
                "Any amount debited to profit and loss account of the previous year but disallowable under section 43B"
              }
            >
              <p>
                11. Any amount debited to profit and loss account of the
                previous year but disallowable under section 43B
              </p>

              <div className="flex_input my-3">
                <label>
                  11(a). Any sum in the nature of tax, duty, cess or fee under
                  any law
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43Bdisallowed_11a}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  11(b) Any sum payable by way of contribution to any provident
                  fund or superannuation fund or gratuity fund or any other fund
                  for the welfare of employees
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43Bdisallowed_11b}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  11(c) Any sum payable to an employee as bonus or commission
                  for services rendered
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43Bdisallowed_11c}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  11(d). Any sum payable as interest on any loan or borrowing
                  from any public financial institution or a State financial
                  corporation or a State Industrial investment corporation
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43Bdisallowed_11d}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  11(e). Any sum payable as interest on any loan or borrowing
                  from any scheduled bank
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43Bdisallowed_11e}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>11(f). Any sum payable towards leave encashment</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43Bdisallowed_11f}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  11(g). Any sum payable to the Indian Railways for the use of
                  railway assets
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.section43Bdisallowed_11g}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  11(h). Total amount disallowable under Section 43B(total of
                  11a to 11g)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.totalAmountDisallowed_11ato11g}
                </h4>
              </div>
            </AccordianBox>

            {/* Tax and Duty - Credit and other sections -------  */}

            <AccordianBox
              title={"Tax and Duty - Credit and other sections  : $0"}
              initial={false}
              description={
                "Amount of credit outstanding in the accounts in respect of"
              }
            >
              <p>
                12. Amount of credit outstanding in the accounts in respect of
              </p>

              <div className="flex_input my-3">
                <label>12(a). Union Excise Duty</label>
                <Input
                  inputType={"currency"}
                  name={"taxAndDuty_12a"}
                  error={errors.taxAndDuty_12a}
                  value={values.taxAndDuty_12a}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>12(b). Service tax</label>
                <Input
                  inputType={"currency"}
                  name={"taxAndDuty_12b"}
                  error={errors.taxAndDuty_12b}
                  value={values.taxAndDuty_12b}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>12(c). VAT/Sales tax</label>
                <Input
                  inputType={"currency"}
                  name={"taxAndDuty_12c"}
                  error={errors.taxAndDuty_12c}
                  value={values.taxAndDuty_12c}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>12(d). Central Goods & Service Tax (CGST)</label>
                <Input
                  inputType={"currency"}
                  name={"taxAndDuty_12d"}
                  error={errors.taxAndDuty_12d}
                  value={values.taxAndDuty_12d}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>12(e). State Goods & Services Tax (SGST)</label>
                <Input
                  inputType={"currency"}
                  name={"taxAndDuty_12e"}
                  error={errors.taxAndDuty_12e}
                  value={values.taxAndDuty_12e}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>12(f). Integrated Goods & Services Tax (IGST)</label>
                <Input
                  inputType={"currency"}
                  name={"taxAndDuty_12f"}
                  error={errors.taxAndDuty_12f}
                  value={values.taxAndDuty_12f}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>
                  12(g). Union Territory Goods & Services Tax (UTGST)
                </label>
                <Input
                  inputType={"currency"}
                  name={"taxAndDuty_12g"}
                  error={errors.taxAndDuty_12g}
                  value={values.taxAndDuty_12g}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>12(h). Any other tax</label>
                <Input
                  inputType={"currency"}
                  name={"taxAndDuty_12h"}
                  error={errors.taxAndDuty_12h}
                  value={values.taxAndDuty_12h}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>
                  12(i). Total amount outstanding (total of 12a to 12h)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.totalTaxAndDuty_12ato12h}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  13. Amounts deemed to be profits and gains under section 33AB
                  or 33ABA
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.amountsDeemedToBeProfits}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>13(i) Section 33AB</label>
                <Input
                  inputType={"currency"}
                  name={"section33AB_13i"}
                  error={errors.section33AB_13i}
                  value={values.section33AB_13i}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>13(ii) Section 33ABA</label>
                <Input
                  inputType={"currency"}
                  name={"section33ABA_13ii"}
                  error={errors.section33ABA_13ii}
                  value={values.section33ABA_13ii}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>
                  14. Any amount of profit chargeable to tax under section 41
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.amountOfProfitSection41_14}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  15. Amount of income or expenditure of prior period credited
                  or debited to the profit and loss account (net)
                </label>
                <Input
                  inputType={"currency"}
                  name={"amountPriorPeriodCreditDebit_15"}
                  error={errors.amountPriorPeriodCreditDebit_15}
                  value={values.amountPriorPeriodCreditDebit_15}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>16. Amount of Expenditure disallowed u/s 14A</label>
                <Input
                  inputType={"currency"}
                  name={"amountOfExpenditureDisallowed14A_16"}
                  error={errors.amountOfExpenditureDisallowed14A_16}
                  value={values.amountOfExpenditureDisallowed14A_16}
                  onChange={handleChange}
                />
              </div>

              <hr className="my-4" />
              <p>
                21. Deemed income under section
                32AD/33AB/33ABA/35ABA/35ABB/40A(3A)/72A/80HHD/80-IA of Schedule
                BP
              </p>

              <div className="flex_input my-3">
                <label>21(i). Deemed income under Section 32AD</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.deemedIncomeSection32AD_21i}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>21(ii) Deemed income under Section 33AB</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.deemedIncomeSection33AB_21ii}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>21(iii). Deemed income under Section 33ABA</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.deemedIncomeSection33ABA_21iii}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>21(iv). Deemed income under Section 35ABA</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.deemedIncomeSection35ABA_21iv}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>21(v). Deemed income under Section 35ABB</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.deemedIncomeSection35ABB_21v}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>21(vi). Deemed income under Section 40A(3A)</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.deemedIncomeSection40A3A_21vi}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>21(vii). Deemed income under Section 72A</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.deemedIncomeSection72A_21vii}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>21v(iii). Deemed income under Section 80HHD</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.deemedIncomeSection80HHD_21viii}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>21(ix). Deemed income under Section 80-IA</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.deemedIncomeSection80IA_21ix}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>21. Total deemed income (total of 21i to 21ix)</label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    scheduleFormData?.totalDeemedIncome_21ito21ix}
                </h4>
              </div>
            </AccordianBox>

            <button className="primary_button my-4" type="submit">
              {strings.button.save_and_close}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}
