import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import GrossIncome from "./GrossIncome";
import ProgressBar from "../Form/ProgressBar";
import { useNavigate, useParams } from "react-router-dom";
import SelfDeclaration from "../ITR/SelfDeclaration";
import MakePayment from "../ITR/MakePayment";
import EverifyReturn from "../ITR/VerifyReturn";
import TaxSummary from "../CommonScreens/TaxSummary";
import TaxSaving from "../CommonScreens/TaxSaving";
import PersonalInfo from "../CommonScreens/PersonalInfo/PersonalInfo";
import PersonalInfoHuf from "../CommonScreens/PersonalInfo/PersonalInfoHUF";
import PersonalInfoFirm from "../CommonScreens/PersonalInfo/PersonalInfoFirm/PersonalInfoFirm";
import PersonalInfoCompany from "../CommonScreens/PersonalInfo/PersonalInfoCompany";
export default function ITR6Form() {
  const [activeTab, setActiveTab] = useState(0);
  const [activeScreen, setActiveScreen] = useState("itr_form");
  const [stepsDone, setStepsDone] = useState([]);

  const { theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);

  const navigate = useNavigate();
  const { active_tab } = useParams();

  const handleNextClick = () => {
    if (activeTab < 4) {
      setActiveTab(activeTab + 1);
      setStepsDone([...stepsDone, activeTab]);
      if (active_tab === "personalInfo") {
        navigate("/form/filing/ITR_6/grossIncome");
      } else if (active_tab === "grossIncome") {
        navigate("/form/filing/ITR_6/taxSaving");
      } else if (active_tab === "taxSaving") {
        navigate("/form/filing/ITR_6/taxSummary");
      } else if (active_tab === "taxSummary") {
        navigate("/form/filing/ITR_6/taxSummary");
      }
    } else {
      setActiveScreen("self_declaration");
      navigate("/form/filing/ITR_6/selfDeclaration");
    }
  };

  const handleTabClick = (click) => {
    console.log("click", click);
    if (click === "personalInfo") {
      navigate("/form/filing/ITR_6/personalInfo");
    } else if (click === "grossIncome") {
      navigate("/form/filing/ITR_6/grossIncome");
    } else if (click === "taxSaving") {
      navigate("/form/filing/ITR_6/taxSaving");
    } else if (click === "taxSummary") {
      navigate("/form/filing/ITR_6/taxSummary");
    }
  };

  const steps = [
    {
      id: 1,
      title: "Personal info",
      link: "personalInfo",
    },
    {
      id: 2,
      title: "Gross Income",
      link: "grossIncome",
    },
    {
      id: 3,
      title: "Tax Saving",
      link: "taxSaving",
    },
    {
      id: 4,
      title: "Tax Summary",
      link: "taxSummary",
    },
  ];

  useEffect(() => {
    if (active_tab === "personalInfo") {
      setActiveTab(1);
      setStepsDone([]);
    } else if (active_tab === "grossIncome") {
      setActiveTab(2);
      setStepsDone([1]);
    } else if (active_tab === "taxSaving") {
      setActiveTab(3);
      setStepsDone([1, 2]);
    } else if (active_tab === "taxSummary") {
      setActiveTab(4);
      setStepsDone([1, 2, 3]);
    } else if (active_tab === "selfDeclaration") {
      setActiveScreen("self_declaration");
    }
  }, [active_tab]);

  return (
    <Root theme={theme} className="container_box">
      {activeScreen === "itr_form" ? (
        <>
          <div className="progress_bar">
            <ProgressBar
              active={activeTab}
              stepDone={stepsDone}
              options={steps}
              handleClick={(e) => handleTabClick(e)}
            />
          </div>
          <div className="main_container">
            <div className="content_form_box">
              {activeTab === 1 ? (
                <PersonalInfoCompany handleNext={() => handleNextClick()} />
              ) : // ItrData.userType == 1 ? (
              //   <PersonalInfo handleNext={() => handleNextClick()} />
              // ) : ItrData.userType == 2 ||
              //   ItrData.userType == 3 ||
              //   ItrData.userType == 5 ? (
              //   <PersonalInfoHuf handleNext={() => handleNextClick()} />
              // ) : (
              //   ItrData.userType == 4  ? (
              //     <PersonalInfoFirm handleNext={() => handleNextClick()} />
              //    ) :
              //   <PersonalInfo handleNext={() => handleNextClick()} />
              // )
              activeTab === 2 ? (
                <GrossIncome handleNext={() => handleNextClick()} />
              ) : activeTab === 3 ? (
                <TaxSaving handleNext={() => handleNextClick()} />
              ) : activeTab === 4 ? (
                <TaxSummary handleNext={() => handleNextClick()} />
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : activeScreen === "self_declaration" ? (
        <>
          <SelfDeclaration
            handleClick={(e) => {
              setActiveScreen(e);
            }}
          />
        </>
      ) : activeScreen === "make_payment" ? (
        <>
          <MakePayment
            handleClick={(e) => {
              setActiveScreen(e);
            }}
          />
        </>
      ) : activeScreen === "e_verify_return" ? (
        <>
          <EverifyReturn />
        </>
      ) : (
        ""
      )}
    </Root>
  );
}
const Root = styled.section`
  .progress_bar {
    margin: 20px 0px;
  }
  .main_container {
    display: flex;
    gap: 10px;
    .content_form_box {
      flex: 3;
    }
    .action_button {
      flex: 1;
      display: flex;
      align-items: end;
    }
  }
`;
