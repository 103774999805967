import {
  SET_ALL_STATES,
  SET_CATEGORIES,
  SET_PLATFORM_STRING,
  SET_PLATFORM_THEME,
} from "../constant";

const initialState = {
  strings: {
    label: {
      rupee: "₹",
      phone_number: "Phone Number",
      email: "Email",
      password: "Password",
      confirm_password: "Confirm Password",
      name: "Name",
      first_name: "First Name",
      middle_name: "Middle Name",
      last_name: "Last Name",
      full_name: "Full Name",
      date_of_birth: "Date of Birth",
      pan_number: "Pan Number",
      nature_of_employment: "Nature of Employment",
      select_bank: "Select Bank",
      account_number: "Account Number",
      ifsc_code: "IFSC Code",
      account_name: "Account Name",
      account_type: "Account Type",
      base_pay: "Basic Pay",
      house_rent_allowace: "House Rent Allowance",
      house_rent_exemption: "House Rent Exemption",
      lta_allowance: "LTA Allowance",
      lta_exemption: "LTA Exemption",
      standard_deductions_16ia: "Standard Deductions 16(ia)",
      professional_tax_under_section_16iii:
        "Professional tax under section 16(iii)",
      relief_under_section_89: "4B Relief under section 89",
      income_claimed_for_relief_under_sectio_89a:
        "4C Income claimed for relief under section 89A",
      employer_name: "Employer Name",
      employer_category: "Employer Category",
      total_tax_deduxted_source: "Total tax deducted at source",
      employer_tax: "Employer TAN",
      gross_salary: "Gross Salary",
      balance: "Balance",
      residential_status: "Residential Status",
      aadhar_number: "Aadhaar Number",
      aadhaar_enrollement_no: "Aadhaar Enrollment No",
      pan: "PAN",
      secandary_mobile_number: "Secondary mobile number",
      secandary_email_address: "Secondary email address",
      landline_number: "Landline number",
      father_name: "Father’s Name",
      choose_option: "Select a Option",
      type: "Type",
      description: "Description",
      time_period: "Time Period",
      max_limit: "Max Limit",
      your_message: "Your Message",
      not_applicable: "Not Applicable",
      this_field_is_optional: " (This field is optional) ",
      other_allowances: "Other Allowances",
      other_exemption: "Other Exemption",
      glossary_section: "Glossary section",
      due_date: "Due Date",
      filing_date: "Filing Date",
    },
    home: {
      upload_form_description:
        "Simplify the Income Tax Return filing process at SamosaTax. Just upload your Form 16 and our system will prepare ITR automatically for you",
      upload_form: "upload form",
      our_services: "Our Services",
      looking_for: "Looking for",
      tax_services: "Tax services",
      we_here_to_help: "-we’re here to help",
      file_itr_5_min: "File your ITR yourself in 5 minutes",
      file_itr_on_own:
        " You can file your ITR on your own. It's Easy, quick & free to file with SamosaTax on your own.",
      file_now: "File Now",
      simplify_income_tax_return:
        "Simplify the Income Tax Return filing process at SamosaTax. Just upload your Form 16 and get the ITR prepared automatically.",
    },
    personal_info: {
      name_of_firm: "Name of Firm",
      date_of_formation: "Date of Formation",
      verification_information: "Verification Information",
      enter_your_verification_information:
        "Please enter your Verification Information here.",
      gender: "Gender",
      male: "Male",
      female: "Female",
      members: "Members",
      additional_info_about_partner_member:
        "Additional Information about Partner/Member of Firm, Co-op Society, LLP, etc.",
      choose_status_of_member_partner: "Choose the Status of Member/Partner",
      share_percentage: "Share Percentage",
      partner_remuneration: "Partner Remuneration",
      partner_interest: "Partner Interest",
      partner_interest_allowable: "Partner Interest Allowable",
      partner_interest_percent: "Partner Interest Percent",
      partner_member_address: "Partner (or Member) Address",
      was_and_change_in_previous_year:
        "Was there any change during the previous year in the Partners / Members of the Firm/AOP/BOI/LLP etc",
      partner_name: "Partner Name",
      admitted_retired: "Admitted/Retired",
      date_of_admission_retirement: "Date Of Admission Or Retirement",
      remuneration_paid_in_retiring_partner:
        "Remuneration paid/payable in case of retiring Partner",
      percent_of_share: "Percent Of Share",
      recognition_number_alootted_by_dpiit_for_startup:
        "Recognition number allotted by the DPIIT for a startup",
      you_enter_either_current_or_permanent_address:
        "You can enter either your current or permanent address here.",
      name_of_huf: "Name of HUF",
      llp_pin_by_mca: "LLP PIN issued By MCA",
    },
    tax: {
      personal_info: "Personal info",
      gross_income: "Gross Income",
      tax_saving: "Tax Saving",
      tax_summary: "Tax Summary",
      assessment_year: "Assessment Year",
      permanent_information: "Permanent Information",
      please_provide_all_info_as_per_your_govt_id:
        "Please provide all info as per your government identity documents(PAN, Aadhaar etc.)",
      identification_and_contact_Details: "Identification & Contact details",
      to_efile_your_returns_please_provide_your_aadhaar_pan:
        "To e-file your returns, please provide your Aadhaar, PAN and contact details",
      your_address: "Your Address",
      you_can_provide_either_your_current_address_or_permanent_address:
        "You can provide either your current address or permanent address of residence.",
      link_your_pan: "Link your PAN",
      pan_linking_is_mandatory_requirement_to_efile:
        "PAN Linking is a mandatory requirement to e-file your returns.",
      add_bank_details: "Add Bank Details",
      salary_breakup: "Salary Breakup",
      salary_per_section_17: "Salary as per section 17(1)",
      deductions_and_relief: "Deductions and Relief",
      employer_tds_details: "Employer & TDS Details",
      popular_deduction: "Popular Deduction",
      health_insurance_nps_interest_earned:
        "80C (PPF, Life Insurance, ELSS Mutual funds etc.), Health Insurance, NPS, Interest earned on Savings Bank Account",
      more_deductions: "More Deductions",
      donations_to_charitable_organizations:
        "Donations to charitable organizations, educational loan, house rent for self employed and more",
      any_other_deductions: "Any other Deductions",
      interest_on_home_loan_electric_vehicle_loan:
        "Interest on home loan, electric vehicle loan, royalty received on books and more",
      taxes_paid_tds_tcss: "Taxes Paid, TDS and TCSS",
      taxes_deducted_collected_source_advance_tax_or_sel_assessment:
        "Taxes deducted or collected at source, Advance Tax or Self Assessment Tax already paid; you can Upload Form 26ASto fetch these details.",
      your_tax_summary: "Your tax summary",
      gross_salary: "Gross Salary",
      taxable_income: "Taxable Income",
      tax_liability: "Tax Liability",
      taxes_paid: "Taxes Paid",
      taxes_payable: "Taxes Payable",
      personal_information: "Personal Information",
      additional_information: "Additional Information (Optional)",
      leave_empty_if_you_dont_have:
        "Leave empty if you don’t have additional information",
      pan_details: "PAN Details",
      pan_linking_is_mandatory_requirement:
        "PAN Linking is a mandatory requirement to e-file your returns.",
      salary_income: "Salary Income",
      you_can_add_salary_income: "You can add salary income from multiple jobs",
      total_amount: "Total Amount",
      perquisites_under_section: "Perquisites under section 17(2)",
      profits_in_lieu_of_salary:
        "Profits in lieu of salary under section 17(3)",
      income_under_section_89a_notified_country:
        "Income under section 89A - notified country",
      income_under_section_89a_other_country:
        "Income under section 89A - other than notified country",
      income_taxable_claimed_in_earlier_year:
        "Income taxable during the previous year on which relief u/s 89A was claimed in any earlier previous year.",
      exempt_allowances_under_section_10: "Exempt allowances under section 10",
      exempt_allowances: "Exempt allowances",
      income_chargeable_under_head_salaries:
        "Income chargeable under head salaries (3-4A-4C)",
      deduction_under_section_16: "4A Deduction under section 16",
      other_source_income: "Other Source Income",
      fixed_deposite_savings_bank_interest_agriculture:
        "Fixed Deposits, Savings Bank Interest, Agriculture, Online Gaming and any Other Income Sources",
      house_property_income: "House Property Income",
      add_details_earned_rent_from_property:
        "Please add the details if you earned rent from your house property or paid interest on housing loan",
      detailed_computation: "Detailed Computation",
      switch_regime: "Switch Regime",
      old_regime: "Old Regime",
      new_regime: "New Regime",
      gst_details: "GST Details",
      information_regarding_turnover_receipt_report:
        "Information Regarding Turnover/Gross Receipt Reported For GST",
      gstn_number: "GSTN Number",
      gross_receipts: "Gross Receipts",
    },
    income: {
      interest_income: "Interest income",
      dividend_income: "Dividend Income",
      agricultural_income: "Agricultural Income",
      exempt_income: "Exempt Income",
      any_other_income: "Any Other Income",
      interest_income_from_saving_bank: "Interest Income from Savings Bank",
      interest_from_deposit: "Interest Income from Deposits",
      interest_income_from_depoits:
        "Interest from sweep accounts converted to FDs post-office fixed deposits etc",
      please_add_details_if_you_have_recurring_income:
        "Please add the details if you have any recurring income as interest earned from your bank including post-office savings bank",
      bank_name: "Name of Bank",
      amount: "Amount",
      interest_income_on_pf: "Interest Income on PF",
      please_add_details_of_taxable_interest_from_pf:
        "Please add the details if you have any Taxable interest from PF",
      interest_on_income_tax_refund: "Interest on Income Tax Refund",
      income_tax_refund_last_year:
        "If you had an income tax refund last year, you may have received some interest amount along with the refund amount",
      enter_the_interest_amount:
        "Enter the interest amount only, do not enter the entire refund amount",
      any_other_interest_income: "Any Other Interest Income",
      bonds_unsecured_loans_national_certificates:
        "Bonds, unsecured loans, national savings certificates etc",
      choose_income_type: "Choose Your Income Type",
      income_type: "Income Type",
      choose_property_type: "Choose Your Property Type",
      dividend_income_from_equities:
        "Dividend Income from Equities, Stocks, Mutual Funds, etc",
      dividend_from_investment: "Dividend from investments in Equities, Stocks",
      total_dividend_income: "Total Dividend Income",
      narration: "Narration",
      date_of_receipt: "Date of Receipt",
      income_details: "Income Details",
      gross_agricultural_receipt: "Gross Agricultural Receipt",
      expenditure_on_agricultural: "Expenditure On Agricultural",
      unabsorbed_agricultural_loss: "Unabsorbed Agricultural Loss",
      agriculture_land_details: "Agriculture Land Details",
      optional_fields_enter_if_receipt_exceeds_5_lakhs:
        "These fields are optional. Enter these values if Net Agriculture Receipt exceeds Rs. 5 Lakhs",
      interest_income_from_ppf: "Interest Income from PPF",
      example_interest_earned_on_ppf: "Example: Interest earned on PPF",
      interest_income_from_nre_account: "Interest Income from NRE Accounts",
      interest_earned_from_non_residential_external:
        "Interest earned from Non Residential External Accounts",
      source: "Source",
      online_gaming_lottery_gifts_and_more:
        "Online Gaming, Lottery, Gifts and more",
      income_lottery_winnings_games_crossword_puzzles_horse_races:
        "Income from Lottery Winnings, Games, Crossword Puzzles, Horse Races, Gambling, Betting and more under Section 115BB ",
      income_from_other_source: "Income from Other Sources",
      report_other_income_which_not_part_of_income_from_salary:
        "Report any other income which is not part of Income from Salary, House Property, Capital Gain or Business and Profession. Gifts can be declared as Income here.",
      income_from_retirement_account_usa_uk_canada:
        "Income from Retirement Accounts (USA, UK, Canada)",
      applicable_for_claiming_relief_under_section_89a:
        "Applicable for claiming relief under Section 89A",
      income_claimed_for_relief_from_taxtation:
        "Income claimed for relief from taxtation u/s 89A",
      note_that_you_should_submit_form_10ef_the_income_tax_department_order:
        "Note that you should submit Form 10EE to the Income Tax Department in order to claim this relief.",
      income_from_retirement_benefit_account_maintained:
        "Income from retirement benefits account maintained in a non-notified country",
      income_taxable_during_the_previous_year_which_relief_89a_was_claimed:
        "Income taxable during the previous year on which relief u/s 89A was claimed in any earlier previous year",
      deductible_expenses_and_depreciation:
        "Deductible Expenses and Depreciation (Section 57) ",
      claim_deductions_for_expenses_related_to_income:
        "Claim deductions for expenses related to income from other sources",
      self_occupied_property: "Self-occupied Property",
      rental_property: "Rental Property",
      deemed_let_out_proprty: "Deemed Let Out Property",
      interest_paid_housing_loan_for_this:
        "Interest paid on housing loan for this house property",
      interest_paid_on_loan_for_this_house_property:
        "Interest paid on loan for this house property",
      interest_paid_during_the_pre_construction:
        "Interest paid during the pre-construction period of house property",
      financial_year_previous_complete_house_property:
        "Financial year previous to completion of house property",
      your_house_address: "Your House Address",
      annual_rent_recieved: "Annual Rent Received by you",
      municipal_tax: "Municipal Tax",
      total_interest_amount_paid_during_pre_construction:
        "Total interest amount paid during pre-construction period",
      tenant_details: "Tenant Details",
      name_of_the_tenant: "Name of the Tenant",
      pan_tan_of_the_tenant: "PAN/TAN of the Tenant",
      aadhaar_of_the_tenant: "Aadhaar of the Tenant",
      estimated_annual_rent_receivable: "Estimated Annual Rent Receivable",
      professional_freelencing_business_income:
        "Professional, Freelancing and Business Income",
      for_all_professionals:
        "For Doctors, Lawyers, CAs, Other Professionals, Freelancers, Small & Medium businesses, Tutors, Influencers etc.",
      professional_income_total: "Professional Income (Total Expenses)",
      financial_particulars: "Financial Particulars",
      mandatory_presumptive_income:
        "Mandatory if you have presumptive income from professions or businesses",
      total_assets: "Total Assets",
      total_liabilities: "Total Liabilities",
      add_gst_details: "Add GST Details",
      capital_gain_income: "Capital Gain Income",
      stocks_funds_and_more:
        "Stocks, Mutual Funds, Land, Bonds, RSUs, US Stocks, Jewellery and more",
      professional_income: "Professional Income",
      first_select_profession_type:
        "You can first select the profession type and then specify the nature of profession",
      profession_type: "Profession Type",
      nature_of_profession: "Nature of Profession",
      gross_revenue_total: "Gross Revenue(Total Sales or Turnover)",
      total_expenses: "Total Expenses",
      taxable_income_under_44ADA:
        "Net taxable income u/s 44ADA(Gross Revenue - Total Expenses)",
      business_income: "Business Income",
      goods_carriages_section_44AE: "Goods Carriages Under Section 44AE ",
      regular_business_professional_income:
        "Regular Business & Profession Income",
      other_business_income: "Other Business Income",
      business_type: "Business Type",
      nature_of_business: "Nature of Business",
      nature_of_business_details: "Nature of Business Details",
      name_of_business: "Name of Business",
      total_gross_revenue_sale: "Total Gross Revenue(Total Sales or Turnover)",
      total_gross_revenue: "Total Gross Revenue",
      revenue_recived_via_online:
        "Revenue received via Online or Digital Modes",
      revenue_received_via_cash: "Revenue received via Cash/Non-Digital Modes",
      total_income_profit_your_calculation:
        "Total Income/Profit as per your calculation",
      from_online_digital_modes: "From online or digital modes",
      business_details: "Business Details",
      goods_carriage_details: "Goods Carriage Details",
      stocks_mutual_funds_land_bonds_jewellery:
        "Stocks, Mutual Funds, Land, Bonds,  Jewellery and more",
      crypto_income: "Crypto Income (Virtual Digital Assets - VDA)",
      add_income_from_crypto_trades_staking_minting:
        "Add income from Crypto - trades, staking, mining, minting, gifts, rewards, airdrops, NFT or other VDA assets",
      stocks_mutual_funds: "Stocks & Mutual Funds",
      import_transactions_from_all_stocks:
        "Import transactions from all your stocks or mutual funds brokers seamlessly with just one click. You can upload P&L files too or add data yourself.",
      sale_of_land_building: "Sale of Land or Building",
      gains_for_sale_of_land_residential_commercial_buildings:
        "Gains from sale of land, residential or commercial buildings and other real estate properties",
      stock_options_rsu: "Stock Options & RSUs",
      gains_from_sale_of_vested_restricted_stock_units:
        "Gains from Sale of vested Restricted Stock Units (RSUs) and exercised Stock Options, ESOPs",
      bonds_and_debentures: "Bonds and Debentures",
      gains_loss_from_bonds_and_debentures_including_govt:
        "Gains or losses from Bonds and Debentures including Government, Corporate and Tax-free Bonds",
      deemed_capitial_gains: "Deemed Capital Gains",
      capital_gains_previously_exempted_subject_to_taxation:
        "Capital gains previously exempted but are now subject to taxation",
      any_other_assets: "Any Other Assets",
      jewellery_paintings_sculptures_archaeological_collections:
        "Jewellery, Paintings, Sculptures, Archaeological Collections, and any other relevant capital assets",
      add_equity_shares_mutual_funds: "Add Equity Shares & Mutual Funds",
      stocks: "Stocks",
      mutual_funds: "Mutual Funds",
      select_asset: "Select type of asset",
      date_of_sale: "Date of Sale",
      date_of_exercise: "Date of Exercise",
      add_details_asset: "Add Details of the asset",
      description_of_asset_sold: "Description of Asset Sold",
      total_sale_price: "Total Sale Price",
      transfer_expenses: "Transfer Expenses",
      did_you_pay_stt: "Did you pay STT ?",
      add_property_details: "Add Property Details",
      applicable_if_you_have_sold_land:
        "Applicable if you have sold land or building(s)",
      date_of_purchase: "Date of Purchase",
      total_purchase_price: "Total Purchase Price ",
      value_pf_property_which_stamp_duty:
        "Value of Property on which stamp duty paid (in Rs.)",
      do_you_want_add_cose_improvment_details:
        "Do you want to add cost of improvement details ?",
      expenses_incurred_increasing_value_of_property:
        "Expenses incurred for increasing the value of the property",
      buyer_details: "Buyer Details",
      please_provide_details_of_property_buyer:
        "Please provide details of the property buyer.",
      buyer_name: "Buyer Name",
      ownership_percentage: "Ownership Percentage",
      amount_paid: "Amount Paid ",
      add_stock_options_rsu: "Add Stock Options & RSUs",
      type_of_stock_option: "Type of Stock Option / RSUs",
      add_transaction_details: "Add transaction details",
      value_as_date_of_exercise: "Value as on date of exercise",
      bonds_debentures: "Bonds & Debentures",
      add_bonds_debentures: "Add Bonds & Debentures",
      specify_those_you_sold_redeemed:
        "Specify those that you've sold or redeemed in 2022-23",
      select_type_bond: "Select type of bond",
      sale_value: "Sale Value",
      purchase_price: "Purchase Price",
      transfer_expenses_if_any: "Transfer Expenses if any",
      short_term_capital_gain: "Short Term Capital Gain",
      long_term_captial_gain: "Long Term Capital Gain",
      whether_amount_captial_gain_during_previous_year:
        "Whether any amount of unutilized capital gain on asset transferred during the previous years shown below was deposited in the Capital Gains Accounts Scheme within due date for that year?",
      prev_year_asset_transferred: "Prev. year in which asset transferred",
      section_under_deduction_claimed_in_year:
        "Section under which deduction claimed in that year",
      year_which_new_asset_acquired:
        "Year in which new asset acquired/ constructed",
      amount_utilised_captial_gains:
        "Amount utilised out of capital Gains Account",
      amount_used_for_new_asset: "Amount not used for new asset",
      amount_deemed_to_short_term_captial_gain:
        "Any other amount deemed to be short term capital gain",
      total_amount_deemed_to_short_term_captial:
        "Total amount deemed to be short term capital gains",
      add_other_assets: "Add Any Other Assets",
      add_crypto_income_yourself: "Add Crypto / VDA income yourself",
      choose_option_shared_by_crypto_exchange:
        "Choose this option in-case you don't have reports shared by your Crypto exchange or you prefer adding data yourself through form",
      add_gains_nft: "Add gains from NFT",
      enter_crypto_vda_asset_name: "Enter Crypto / VDA asset name",
      gains: "Gains",
      enter_nft_asset_name: "Enter NFT asset name",
      add_business_details: "Add Business Details",
      business_code: "Business Code ",
      choose_business_code: "Choose Business Code",
      number_of_months: "Number of Months",
      income_per_vehicle_per_month: "Income Per Vehicle Per Month",
      registration_number: "Registration Number",
      ownership_status: "Ownership Status",
      add_goods_carriage_details: "Add Goods Carriage Details",
      provide_details_of_profession:
        "Please provide details of your profession(s)",
      provide_details_of_profession_or_business:
        "Please provide details of your profession(s) or Business(s)",
      balance_sheet: "Balance Sheet",
      details_of_assets_liabilities_of_business:
        "Details of assets and liabilities of your business/ profession as on 31st March, 2023",
      p_l_account: "P&L Account",
      details_income_expenses_of_your_business_profession_for_period:
        "Details of income and expenses of your business/ profession for the period - April 2022 to March 2023",
      depreciation: "Depreciation",
      summary_depreciation_of_assets_under_income_tax_act:
        "Summary of depreciation on all assets under the Income-Tax Act.",
      schedule_icds: "Schedule ICDS",
      effect_of_income_computation:
        "Effect of Income Computation and Disclosure Standards on Profit",
      schedule_esr: "Schedule ESR",
      deduction_under_section_35:
        "Deduction under section 35 (expenditure on scientific research)",
      remuneration_from_partnership_firms:
        "Remuneration from Partnership Firms",
      partner_in_firm_remuneration_details:
        "If you are partner in a firm(s), enter the details of the remuneration. income or profits from it.",
      book_of_account_not_maintained: "Books of Account are Not Maintained",
      business_gross_receipt_less_than_5_lakhs:
        "This is applicable to Business/Profession whose gross receipts /revenue is less than or equal to 05 Lakhs (10 Lakhs for Profession) and profit is less than or equal to 2.5 Lakhs (I .5 Lakhs for Profession).",
      speculative_income: "Speculative Income",
      section_for_intraday_trading:
        "This section is for intraday share trading etc.",
      financial_statements_schedules: "Financial Statements & Schedules",
      schedule_bp: "Schedule BP",
      adjustment_income_from_sources_other_than_business:
        "Adjustment of income from sources other than business and profession.",
      schedule_oi: "Schedule OI (Other Information)",
      section_includes_addition_pl_in_schedule:
        "This section includes addition to P&L in Schedule BP, disallowances from P&L under section 36, 37, 40, 40A, 43B and other adjustments.",
      audit_information: "Audit Information",
      auditor_details: "Auditor Details",
      applicable_audit_book_of_accounts:
        "This is applicable when Audit of books of accounts is required.",
      quantitative_details: "Quantitative Details",
      details_of_trading_concern: "Details of Trading Concern",
      details_manufacturing_concern_raw_materials:
        "Details of Manufacturing Concern (Raw Materials)",
      details_manufacturing_concern_finished_products:
        "Details of Manufacturing Concern (Finished Products)",
      remunerationDetails: "Remuneration Details",
      name_of_partnership_firm: "Name of Partnership Firm",
      share_percentage_partnership_firm: "Share percentage in Partnership Firm",
      pan_of_partnership_firm: "PAN of Partnership Firm",
      remuneration_from_firm: "Remuneration from Firm",
      interest_received: "Interest Received",
      profit_amount_received_firm: "Profit amount received from Firm (Rs.) ",
      capital_balance_firm_as_31_march:
        "Capital Balance in Firm as of 31st March 2023",
      amount_allowable_as_deduction: "Any other amount allowable as deduction",
      firm_liable_audit: "Is the firm liable for Audit?",
      firm_liable_furnish_report_under_92e:
        "Is the firm liable to furnish report under 92E?",
      trade_name: "Trade Name",
      regular_book_account_not_maintained:
        "In case regular Books of Account were not maintained",
      regular_book_account_not_maintained_description:
        "In a case where regular books of account of business or profession are not maintained - (furnish the following information as on 31st day of March, 2023, in respect of business or profession).",
      a_sundry_debtors: "1a: Amount of total sundry debtors",
      b_sundry_creditors: "1b: Amount of total sundry creditors",
      c_amount_total_stock_trade: "1c: Amount of total stock-in-trade",
      d_amount_cash_balance: "1d: Amount of the cash balance",
      regular_book_account_not_maintained_prev_year_description:
        "If regular books of account of business or profession are not maintained, furnish the following information for previous year 2022-2023 in respect of business or profession:",
      i_carrying_on_business: "2i: For assessee carrying on Business",
      ia_gross_turnover_receipt: "2ia: Gross turnover/Gross receipts (a1+a2)",
      iai_payee_cheque_or_bank:
        "2iai: Through a/c payee cheque or a/c payee bank draft or bank....",
      iaii_any_other_mode: "2iaii: Any other mode",
      ib_gross_profite: "2ib: Gross profit",
      ic_expenses: "2ic: Expenses",
      id_net_profit: "2id: Net profit",
      ii_carrying_profession: "2ii: For assessee carrying on Profession",
      iia_gross_receipt: "2iia: Gross receipts (a1 + a2)",
      iiai_through_payee_cheque:
        "2iiai: Through a/c payee cheque or a/c payee bank draft or bank...",
      iiaii_any_other_mode: "2iiaii: Any other mode",
      iib_gross_profite: "2iib: Gross profit",
      iic_expenses: "2iic: Expenses",
      iid_net_profit: "2iid: Net profit",
      iii_total_profit: "2iii: Total Profit (2(i)d + 2(ii)d)",

      turnover_from_speculative_activity: "Turnover from speculative activity",
      gross_profit: "Gross Profit",
      expenditure_if_any: "Expenditure, if any",
      income_from_intraday_trading:
        "Income From Intraday Trading - transferred to profit and loss account",
      net_speculative_income: "Net Speculative Income is",
      addition_to_profit_loss: "Additions to Profit and Loss Account",
      page_enter_information_related_section_36_37_40a_41:
        "On this page, enter information related to Section 36, 37, 40A and 41 of Schedule OI and certain sections of Schedule BP.",
      details_section_43b: "To enter details of Section 43B",
      details_section_40: "To enter details of Section 40",
      profit_before_tax_as_pl_account: "Profit before tax as per P/L Account",
      income_from_other_source_credit_to_pl:
        "Income from other sources credited to P&L",
      salaries: "Salaries",
      house_properties: "House Properties",
      captial_gains: "Capital Gains",
      other_divident_income: "Other Than Dividend Income",
      us_115bbf: "u/s 115BBF",
      us_115bbg: "u/s 115BBG",
      us_115bbh: "u/s 115BBH",
      expenses_from_other_source_debited_to_pl:
        "Expenses from other sources debited to P&L",
      any_other_source: "Any Other Sources",
      expenses_debited_pl_account_to_exempt_income:
        "Expenses debited to profit and loss account which relate to exempt income",
      expenses_debited_pl_account_exempt_disallowed:
        "Expenses debited to profit and loss account which relate to exempt income and disallowed u/s 14A",
      exempt_income_credited_to_pl: "Exempt Income credited to P&L",
      share_income_from_firm: "Share of income from firm(s)",
      share_income_from_aop_boi: "Share of income from AOP/BOI",
      any_other_exempt_income: "Any other exempt income",
      specify_other_exempt_income: "Specify any other exempt income.",
      depreciation_per_book_debited_pl:
        "Depreciation as per books (debited to P&L)",
      depreciation_it_act_sec_321ii_321iia:
        "Depreciation as per IT Act- Sec 32(1)(ii), 32(1)(iia)",
      depreciation_under_section_321i:
        "Depreciation allowable under Section 32(1)(i)",
      remuneration_deduction: "Remuneration deduction",
      income_in_pl_account:
        "Any other income not included in profit and loss account",
      bonus: "Bonus",
      commission: "Commission",
      interest_from_firm: "Interest From Firm",
      any_other_interest: "Any Other Interest",
      others: "Others",
      allowances_under_other_sections:
        "Allowances / Deductions under other sections",
      deduction_under_section_321iii:
        "Deduction allowable under section 32(1)(iii)",
      profit_of_business_deemed_to_under:
        "Profits and gains of business or profession deemed to be under",
      section_44b: "Section 44B",
      section_44bb: "Section 44B",
      section_44bba: "Section 44BBA",
      section_44bda: "Section 44BDA",
      profite_activities_covered_under:
        "Profit from activities covered under rule 7, 7A, 7B(1), 7B(1A)",
      income_not_included_in_pl: "Any other income not included in P&L",
      income_under_rule7: "Income under rule-7",
      income_under_rule7a: "Income under rule-7A",
      income_under_rule7b1: "Income under rule-7B(1)",
      income_under_rule7b1a: "Income under rule-7B(1A)",
      income_under_rule8: "Income under rule-8",
      section: "Section",
      sub_section: "Sub Section",
      particulars: "Particulars",
      brought_forward_financial_year:
        "Brought forward from which Financial Year? Example: 2010-2011",
      amount_brought_forward: "Amount Brought Forward",
      amount_disallowed_preceding_for_current_year:
        "Amount disallowed in any preceding year but allowable for current year",
      amount_debited_to_pl_account:
        "Amounts debited to the profit and loss account",
      amount_debited_to_pl_disallowed_for_current_year:
        "Amounts debited to the profit and loss account but disallowed for current year",
      summary_income_under_section_43b: "Summary of Incomes under section 43B",
      liable_maintain_account_section_44aa:
        "Are you liable to maintain accounts as per section 44AA?",
      declaring_income_under_section_44ae_44ad_44ada:
        "Are you declaring income only under section 44AE/44AD/44ADA/44B/44BB/44BBA?",
      liable_for_audit_under_section_44ab:
        "Are you liable for audit under section 44AB?",
      name_of_auditor_proprietorship_firm:
        "Name of the auditor (proprietorship/ firm)",
      auditor_name: "Auditor Name",
      membership_number_of_auditor: "Membership Number of Auditor",
      auditor_registration_number: "Auditor Registration Number",
      audit_firm_pan: "Audit Firm PAN",
      aadhar_of_auditor: "Aadhar of Auditor (Optional)",
      date_of_report_the_audit: "Date of report of the Audit",
      date_of_funishing_audit_report: "Date of furnishing the Audit Report",
      taxpayer_liable_furnish_under_section_92e:
        "Is Taxpayer liable to furnish a report under Section 92E? If you are liable to furnish this report, please mention Date of Furnishing report in the 'Other audits' section on the right.",
      case_of_nonresident_permanent_establishment:
        "In the case of non-resident, is there a permanent establishment (PE) in India?",
      other_audits: "Other audits",
      liable_furnish_audit_report_under_income_tax:
        " if you are liable to furnish an audit report under the Income Tax Act to specify Section Code and Date of Furnishing the report.",
      audit_under_act_it_act: "Audit under Act other than I-T Act",
      liable_furnish_audit_report_under_act:
        "if you are liable to furnish an audit report under any Act other than the Income Tax Act to specify Section, Act, and Date of Furnishing the report.",
      total_sale_of_business_in_between:
        "Does your total sales/turnover/gross receipts of business, not profession, is between 1 to 10 Crore Rupees?",
      no_turnover_does_not_exceed_1cr: " No, turnover does not exceed 1 crore",
      no_turnover_exceeds_10cr: " No, turnover exceeds 10 crore",
      liable_for_audit_under_section_44ab_by_accountant:
        "If liable for audit under section 44AB, were the accounts audited by an accountant?",
      aggregate_amount_received:
        "Whether the aggregate of all amounts received (including sales/turnover/gross  receipts/capital contributions/loans etc) in cash, does not exceeds 5% of total amount?",
      aggregate_all_payment_mode_in_cash:
        "Whether the aggregate of all payments made (including expenses incurred/capital account/asset acquisition/repayment of loans etc) in cash, does not exceeds 5% of total amount?",
      additional_audits: "Additional Audits",
      act_code: "Act Code",
      other_act_description: "Other Act Description",
      trading_concern: "Trading Concern",
      add_trading_company_details: "Add Trading Company Details",
      unit: "Unit",
      opening_stock: "Opening stock",
      sales_during_previous_year: "Sales during the previous year",
      purchase_during_previous_year: "Purchase during the previous year",
      closing_stock: "Closing stock",
      shortage_excess: "Shortage / Excess",
      manufacture_raw_materials: "Manufacture Raw Materials",
      add_manufacture_raw_materials: "Add Manufacture Raw Materials",
      unit_of_measure: "Unit of measure",
      consuption_during_previous_year: "Consumption during the previous year",
      quantity_manufactured_during_pre_year:"Quantity Manufactured during the Previous year",
      yield_finished_products: "Yield Finished Products",
      percentage_of_yield: "Percentage of Yield ",
      manufacture_finished_product: "Manufacture Finished Product",
      add_manufacture_finished_product: "Add Manufacture Finished Product",
      add_details_liabilities: "Add details of Liabilities",
      unsettled_payables: "Unsettled Payables",
      outstanding_principal_secured_loans:
        "Outstanding Principal - Secured Loans",
      advances: "Advances",
      other_liabilities: "Other Liabilities",
      add_details_of_assets: "Add details of Assets",
      uncollected_receivables: "Uncollected Receivables",
      total_inventory_value: "Total Inventory Value",
      cash_in_hand: "Cash-in-hand",
      fixed_assets: "Fixed Assets",
      closing_balance_with_banks: "Closing Balance With Banks",
      loans_and_advances: "Loans And Advances",
      other_assets: "Other Assets",
      unrealized_rent: "Unrealized Rent",
      arrears_unrealized_rent_received:
        "Arrears/Unrealized Rent received during the year Less 30%",
      income_under_44ada: "Professional Income under Section 44ADA",
      income_under_44ad: "Business Income under Section 44AD",
      carriage_under_44ae: "Goods Carriages Under Section 44AE ",
      please_provide_business_details:
        "Please provide details of your business(s)",
      name_business_company: "Name of Business/Company",
      should_at_least_6_per: "Should be at least 6% of gross revenue",
      add_financial_particulars_of_business:
        "Please add the financial particulars of your Business(es) and Profession(s)",
      exclude_personal_assets_liabilities:
        "Exclude personal assets and liabilities.",
      include_business_profession_related_assets_liabilities:
        "Include only business or profession-related assets and liabilities.",
      business_has_no_inventory_payables_receivables:
        "If your business has no inventory, payables, or receivables, you can declare them as 0.",
      multiple_businesses_combine_assets:
        "If you have multiple businesses, you can combine assets and liabilities of all of them",
      businessDetails:
        "Please provide details of your Profession(s) or Business(s)",
    },
    // Pending
    scheduleOi:{
      methord_of_accounting:"1. Method of accounting employed in the previous year",
      change_in_method_accounting_2:"2. Is there any change in Method of Accounting ",
      effect_on_profile_section_145_2:"3. Effect on the profit because of deviation, if any, as per Income Computation Disclosure Standards notified under section 145(2) ",
      method_of_valuation_4:"4. Method of valuation of closing stock employed in the previous year",
      raw_material_:"4(a). Raw Material (if at cost or market rates whichever is less write 1, if at cost write 2, if at market rate write 3)",
    },
    balance_sheet: {
      you_run_sme_msme_business_provide_balance_sheet:
        "Do you run a SME/MSME business? Do you have more details to provide in Balance Sheet?",
      assets: "Assets",
      enter_details_of_assets:
        "Enter details of all assets owned by you - immovable property, movable property and financial assets.",
      have_you_invested_in_shares_mutual_funds:
        "Have you invested in shares or mutual funds?",
      long_term_investments_shares_mf: "Long term investments (Shares/MFs)",
      short_term_investments_share_mf: "Short term investments (Shares/MFs)",
      short_term_investments_debentures: "Short term investments (Debentures)",
      amount_receivable_from_your_clients:
        "Amount receivable from your clients",
      cash_in_hand: "Cash in hand",
      balance_in_bank: "Balance in bank",
      loan_given_to_friends_and_family: "Loan given to friends and family",
      liabilities: "Liabilities",
      enter_details_of_payables_long_term_and_short_term_loans:
        "Enter details of payables, long term and short term loans and advances",
      capital_investment: "Capital Investment",
      secured_loan: "Secured Loan",
      unsecured_loan: "Unsecured loan",
      are_you_see_you_want_to_fill_the_balance_sheet:
        "Are you sure you want to fill the Detailed Balance Sheet?",
      applicable_only_you_have_these:
        "Applicable only if you have any of these:",
      reserves_surplus: "Reserves & Surplus",
      foreign_bank_loans: "Foreign Bank Loans",
      finished_goods_inventories_other_assets:
        "Finished goods inventories & Other assets",
      sme_msme_businesses: "SME/MSME businesses",
      professions_with_revenue_above_lakhs:
        "Professions with revenue above 50 Lakhs",
      balance_sheet_for_itr3: "Balance Sheet for ITR-3",
      sources_of_funds: "Sources of Funds",
      applications_of_funds: "Applications of Funds",
      manufacturing_account: "Manufacturing Account",
      trading_account: "Trading Account",
      profit_and_loss_account: "Profit & Loss Account",
      profit_loss: "Profit And Loss",
      run_sme_msme_business_details_to_provide_in_profit_loss:
        "Do you run a SME/MSME business? Do you have more  details to provide in Profit and Loss?",
      profit_income_expenses: "Profit(Income - Expenses)",
      expenses: "Expenses",
      enter_details_expenses_incurred_business_trading_activities:
        "Enter details of expenses incurred wrt businesses or trading activities",
      purchase_value_futures: "Purchase value of futures",
      purchase_value_of_options: "Purchase value of options",
      purchase_value_of_currency_derivatives:
        "Purchase value of currency derivatives",
      purchase_value_of_commodity_derivatives:
        "Purchase value of commodity derivatives",
      other_brokerage_expenses: "Other Brokerage Expenses",
      income: "Income",
      total_income: "Total Income",
      details_income_earned_from_business_professional_services_trading:
        "Details of income earned from the businesses, professional services, trading activities etc.",
      turnover_from_options: "Turnover from options",
      turnover_from_futures: "Turnover from futures",
      turnover_from_currency_derivatives: "Turnover from currency derivatives",
      turnover_from_commodity_derivatives:
        "Turnover from commodity derivatives",
      add_depreciation_entry: "Add Depreciation Entry",
      depreciation_block: "Depreciation Block",
      opening_amount: "Opening Amount",
      purchase_amount_upto_oct_3: "Purchase Amount (Upto October 3)",
      purchase_amount_upto_oct_4: "Purchase Amount (Upto October 4)",
      sale_amount_on_assets_purchased_upto_oct_3:
        "Sale Amount (On assets purchased upto October 3)",
      sale_amount_on_assets_purchased_upto_oct_4:
        "Sale Amount (On assets purchased upto October 4)",
      date_on_which_sale_was_made: "Date on which sale was made, if any",
      personal_usage_percentage: "Personal Usage Percentage",
      additional_depreciation_claimed_during_previous_ay:
        "Additional depreciation claimed during the previous A.Y.",
      is_block_nil: "Is Block Nil?",
      additional_depreciation: "Additional Depreciation?",
      schedule_icds_effect_of_income_computation_disclosure_standards_on_profit:
        "Schedule ICDS: Effect of Income Computation Disclosure Standards on profit",
      accounting_policies: "Accounting Policies",
      valuation_of_inventories: "Valuation of Inventories",
      construction_contracts: "Construction Contracts",
      revenue_recognition: "Revenue Recognition",
      tangible_fixed_assets: "Tangible Fixed Assets",
      changes_foreign_exchange_rates: "Changes in Foreign Exchange Rates",
      government_grants: "Government Grants",
      securities: "Securities",
      borrowing_costs: "Borrowing Costs",
      provisions_contingent_liabilities_contingent_assets:
        "Provisions, Contingent Liabilities and Contingent Assets",
      esr_details: "ESR Details",
      section_35_1_i: "Section : 35(1)(i)",
      section_35_1_ii: "Section : 35(1)(ii)",
      section_35_1_iii: "Section : 35(1)(iii)",
      section_35_1_iv: "Section : 35(1)(iv)",
      section_35_1_2aa: "Section : 35(1)(2AA)",
      section_35_1_2ab: "Section : 35(1)(2AB)",
      amount_debited: "Amount Debited to P&L",
      amount_deduction_allowable: "Amount of Deduction allowable",

      proprietor_fund_1: "1: Proprietor's fund",
      proprietor_capital: "1a: Proprietor's capital",
      reserves_and_surpus: "1b: Reserves and Surplus",
      revaluation_reserve: "1bi: Revaluation Reserve",
      capital_reserve: "1bii: Capital Reserve",
      statutory_reserve: "1biii: Statutory Reserve",
      any_other_reserve: "1biv: Any other Reserve",
      total_: "1bv: Total (bi+bii+biii+biv)",
      // :"1c: Total proprietor's fund (a + bv)",
      // :"2: Loan funds",
      // :"2a: Secured loans",
    },
    detail_balance_sheet: {
      sourcesOffunds: "Sources of Funds",
      proprietorTitle: "1: Proprietor's fund",
      proprietorCapital: "1a: Proprietor's capital",
      reservesAndSurplus1b: "1b: Reserves and Surplus",
      reserveAndSurplus1bi: "1bi: Revaluation Reserve",
      capitalReserve: "1bii: Capital Reserve",
      statutoryReserve: "ibiii: Statutory Reserve",
      anyOtherReserve: "1biv: Any other Reserve",
      total1bv: "1bv: Total (bi+bii+biii+biv)",
      total1c: "1c: Total proprietor's fund (a + bv)",
      loanFund: "2: Loan funds",
      securedLoan2a: "2a: Secured loans",
      foreignCurrencyLoan: "2ai: Foreign Currency Loans",
      rupeeLoans2aii: "2aii: Rupee Loans",
      formBank: "2aiiA: From Banks",
      fromOther2aiiB: "2aiiB From Others",
      totalaiic: "2aiiC: Total(iiA + iiB)",
      total2aii: "2aiii: Total(ai + iiC)",
      unsecuredLoanDeposits: "2b: Unsecured loans (including deposits)",
      fromBank2bi: "2bi: From Banks",
      fromOthers2bii: "2bii: From Others",
      total2biii: "2biii: Total(bi + bii)",
      total2c: "2c: Total Loan Funds (aiii + biii)",
      deferredTaxLiability3: "3: Deferred tax liability",
      adavances4: "4: Avanances",
      fromPerson4i:
        "4i: From persons specified in section 40A(2)(b of I.T.Act)",
      fromOther4ii: "4ii: From others",
      total4iii: "4iii: Total Advances (i + ii)",
      sourcesFund5: "5: Sources of funds (1c + 2c + 3 + 4iii)",

      applicationsOfFunds: "Applications of Funds",
      fixedAssets1: "1: Fixed assets",
      grossBlock1a: "1a: Gross Block",
      depreciation1b: "1b: Depreciation",
      netBlock1c: "1c: Net Block (1a-1b)",
      capitalWorkInProgress1d: "1d: Capital work in progress",
      total1e: "1e: Total (1c + 1d)",
      investments2: "2: Investments",
      longTermInvestment2a: "2a: Long-term investments",
      govtAndOtherQuoted2ai: "2ai: Government and other Securities - Quoted",
      govtAndOtherUnquoted2aii:
        "2aii: Government and other Securities - Unquoted",
      total2aiii: "2aiii: Total (ai + aii)",
      shortTermInvestments2b: "2b: Short-term investments",
      equityShares2bi: "2bi: Equity shares including share application money",
      preferenceShares2bii: "2bii: Preference Shares",
      debentures2biii: "2biii: Debentures",
      total2biv: "2biv: Total (bi + bii + biii)",
      totalInvestment2c: "2c: Total investments (aiii + biv)",
      currentAssets3: "3: Current assets, loans and advances",
      currentAssets3a: "3a: Current assets",
      inventories3ai: "3ai: Inventories",
      storesConsumables3aiA:
        "3aiA: Stores/Consumables including packing material",
      rawMaterials3aiB: "3aiB: Raw materials",
      stockInProgress3aiC: "3aiC: Stock-in-process",
      finishedGoods3aiD: "3aiD: Finished Goods/Traded Goods",
      total3aiE: "3aiE: Total (iA + iB + ic + iD)",
      sundryDebtorseaii: "3aii: Sundry Debtors",
      cashAndBankBalance3aiii: "3aiii: Cash and Bank Balances",
      cashInHand3aiiiA: "3aiiiA: Cash-in-hand",
      balanceWithBanks3aiiiB: "3aiiiB: Balance with banks",
      total3aiiiC: "3aiiiC: Total (iiiA + iiiB)",
      otherCurrentAssets3aiv: "3aiv: Other Current Assets",
      totalCurrentAssets3av:
        "3av: Total current assets (iE + aii + iiic + aiv)",
      loansAndAdvances3b: "3b: Loans and advances",
      advancesRecoverable3bi:
        "3bi: Advances recoverable in cash or in kind of for value to be received",
      depositsLoans3bii:
        "3bii: Deposits, loans and advances to corporates and others",
      balanceWithRevenue3biii: "3biii: Balance with Revenue Authorities",
      total3biv: "3biv: Total (bi + bii + biii)",
      totalOfCurrentAsssetAdvances3c:
        "3c: Total of current assets, loans and advances (av + biv)",
      currentLiabilities3di: "3di: Current liabilities",
      sundryCreditors3diA: "3diA: Sundry Creditors",
      liabilityLeased3diB: "3diB: Liability for Leased Assets",
      interestAccrued3diC: "3diC: Interest Accrued on above",
      interestAccruedNotLoan: "3diD: Interest accrued but not due on loans",
      total3diE: "3diE: Total (iA + iB + iC + iD)",
      provisions: "3dii: Provisions",
      proForIncome3diiA: "3diiA: Provision for Income Tax",
      proLeaveSuperannuation3diiB:
        "3diiB: Provision for leave encashment/Superannuation/Gratuity",
      otherProvisions3diiC: "3diiC: Other Provisions",
      total3diiD: "3diiD: Total (iiA + iiB + iiC)",
      total3diii: "3diii: Total (iE + iiD)",
      netCurrentAssets3e: "3e: Net current assets (3c - diii)",
      miscellaneous4a:
        "4a: Miscellaneous expenditure not written off or adjusted",
      defferedTaxAsset4b: "4b: Deferred tax asset",
      profitAndLossAccount4c: "4c: Profit and loss account/Accumulated balance",
      total4d: "4d: Total (4a + 4b + 4c)",
      totalApplicationOfFunds5:
        "5: Total application of funds (1e + 2c + 3e + 4d)",

      ManufacturingAccount: "Manufacturing Account",
      openingInventory1: "1: Opening Inventory",
      openingStockRaw1ai: "1ai: Opening stock of raw-material",
      openingStockWork1aii: "1aii: Opening stock of Work in progress",
      total1aiii: "1aiii: Total (i + ii)",
      purchasesNet1b: "1B: Purchases (Net of refunds and duty or tax, if any)",
      directWages1C: "1C: Direct wages",
      directExpenses1D: "1D: Direct expenses",
      carriageInward1di: "1di: Carriage inward",
      powerAndFuel1dii: "1dii: Power and fuel",
      otherDirectExpenses1diii: "1diii: Other direct expenses",
      factoryOverheads1E: "1E: Factory Overheads",
      indirectWages1ei: "1ei: Indirect wages",
      factoryRent1eii: "1eii: Factory rent and rates",
      factoryInsurance1eiii: "1eiii: Factory insurance",
      factoryFuekAndPower1eiv: "1eiv: Factory fuel and power",
      factoryGeneralExpenses1ev: "1ev: Factory general expenses",
      depreciationOfFactory1evi: "1evi: Depreciation of factory machinery",
      total1evii: "1evii: Total (i+ii+iii+iv+v+vi)",
      totalEdbitsToManufacturingiF:
        "1F: Total of Debits to Manufacturing Account (Aiii+B+C+d+Evii)",
      closingStock2: "2: Closing Stock",
      rawMaterial2ai: "2ai: Raw material",
      workInProgress2aii: "2aii: Work-in-progress",
      totalManufacturing2aiii: "2aiii: total (2i+2ii)",
      costOfGoodsProduced3:
        "3: Cost of Goods Produced transferred to Trading Account (IF-2)",

      tradingAccount: "Trading Account",
      revenueFromOperations4: "4: Revenue from operations",
      salesGrossReceipts4A:
        "4A: Sales/ Gross receipts of business (net of returns and refunds and duty or tax, if any)",
      saleOfGoods4ai: "4ai: Sale of goods",
      saleOfService4aii: "4aii: Sale of services",
      otherOperatingRevenues4Aiii:
        "4Aiii: Other operating revenues (specify nature and amount)",
      total4Aiiia: "4Aiiia: Total",
      total4aiv: "4aiv: Total(i+ii+iic)",
      grossReceipts4B: "4B: Gross receipts from Profession",
      dutiesTaxesAndCess4C:
        "4C: Duties, taxes and cess received or receivable in respect of goods and services sold or supplied",
      // unionExise4ci:"4ci: Union Excise duties",
      unionExcise4ci: "4ci: Union Excise duties",
      serviceTax4cii: "4cii: Service Tax",
      vatSales4ciii: "4ciii: VAT/ Sales tax",
      centralGoods4civ: "4civ: Central Goods & Service Tax (CGST)",
      stateGoodsServices4cv: "4cv: State Goods & Services Tax (SGST)",
      integratedGoods4cvi: "4cvi: Integrated Goods & Services Tax (IGST)",
      unionTerritoryGoods4cvii:
        "4cvii: Union Territory Goods & Services Tax (UTGST)",
      anyOtherDuty4cviii: "4cviii: Any other duty, tax and cess",
      total4cix: "Total (i+ii+iii+iv+v+vi+vii+viii)",
      totalRevenue4d: "4d: TotalRevenue from operations (Aiv + B + Cix)",
      closingStock5: "5: Closing Stock of Finished Stocks",
      totalCredits6: "6: Total of credits to Trading Account (4D + 5)",
      openingStock7: "7: Opening Stock of Finished Goods",
      purchases8: "8: Purchases (net of refunds and duty or tax, if any)",
      directExpenses9: "9: Direct Expenses",
      carriageInward9ai: "9ai: Carriage inward",
      powerAndFuel9aii: "9aii: Power and fuel",
      otherDirectExpenses9Aiii: "9Aiii: Other direct expenses",
      total9Aiiia: "9Aiiia: Total",
      dutiesAndTaxes10:
        "10: Duties and taxes, paid or payable, in respect of goods and services purchased",
      customDugy10ai: "10ai: Custom duty",
      counterVeiling10aii: "10aii: Counter veiling duty",
      speicalAdditional10aiii: "10aiii: Special additional duty",
      unionExcise10aiv: "10aiv: Union excise duty",
      serviceTax10av: "10av: Service Tax",
      vatSales10avi: "10avi: VAT/ Sales tax",
      centralGoods10avii: "10avii: Central Goods & Service Tax (CGST)",
      stateGoods10aviii: "10aviii: State Goods & Services Tax (SGST)",
      integratedGoods10aix: "10aix: Integrated Goods & Services Tax (IGST)",
      unionTerritory10ax: "10ax: Union Territory Goods & Services Tax (IJTGST)",
      anyOtherTax10axi: "10axi: Any other tax, paid or payable",
      total10Axii:
        "10Axii: Total (10i + 10ii + 10iv + 10v + 10vi + 10vii + 10viii + 10ix + 10x + 10xi)",
      costOfGoods11:
        "11: Cost of goods produced — Transferred from Manufacturing Account",
      grossProfitFromBusiness12:
        "12: Gross Profit from Business/Profession - transferred to Profit and Loss account (6-7-8-9-10xi)",
      turnoverFromIntraday12a: "12a: Turnover from Intraday Trading",
      incomeFromIntraday12b:
        "12b: Income from Intraday Trading - transferred to Profit and Loss account",

      profitAndLossAccount: "Profit & Loss Account",
      grossProfit13: "13: Gross profit transferred from Trading Account",
      otherIncome14: "14: Other income",
      rent14i: "14i: Rent",
      commission14ii: "14ii: Commission",
      devidendIncome14iii: "14iii Dividend income",
      interestIncome14iv: "14iv: Interest income",
      profitFixedAssets14v: "14v: Profit on sale of fixed assets",
      profitInvestment14vi:
        "14vi: Profit on sale of investment being securities chargeable to Securities Transaction Tax (STT)",
      profitOtherInvestment: "14vii: Profit on sale of other investment",
      gainLossAccount14viii:
        "14viii: Gain (Loss) on account of foreign exchange fluctuation u/s 43AA",
      profitConversionInventory14ix:
        "14ix: Profit on conversion of inventory into capital asset u/s 28(via) (Fair Market Value of inventory as on the date of conversion)",
      agriculturalIncome14x: "14x: Agricultural income",
      anyOtherIncome14xi: "14xi: Any other income (specify nature and amount)",
      total14xii: "14xii: Total",
      totalOFOtherIncome14xii:
        "14xii: Total of other income (i + ii + iii + iv + v + vi + vii + viii + ix + x + xi)",
      totalOfCredits15:
        "15: Total of credits to profit and loss account (13+14xii)",
      freightOUtward16: "16: Freight outward",
      consumptionOfStores17: "17: Consumption of stores and spare parts",
      powerAndFuel18: "18: Power and fuel",
      rents19: "19: Rents",
      repairstoBuilding20: "20: Repairs to building",
      repairstoMachinery21: "21: Repairs to machinery",
      compensationToEmp22: "22: Compensation to employees",
      salariesAndWages22i: "22i: Salaries and wages",
      bonus22ii: "22ii: Bonus",
      reimbursementOfMed22iii: "22iii: Reimbursement of medical expenses",
      leaveEncashment22iv: "22iv: Leave encashment",
      leaveTravel22v: "22v: Leave travel benefits",
      contriApprovedSuper22vi:
        "22vi: Contribution to approved superannuation fund",
      contriRecognisedPF22vii:
        "22vii: Contribution to recognised provident fund",
      contriRecognisedGratuity22viii:
        "22viii: Contribution to recognised gratuity fund",
      contriAnyOther22ix: "22ix: Contribution to any other fund",
      anyOtherBenefit22x:
        "22x: Any other benefits to employees in respect of which an expenditure has been incurred",
      totalCompensation22xi:
        "22xi: Total compensation to employees (22i + 22ii + 22iii + 22iv + 22v + 22vi + 22vii + 22viii + 22ix + 22x)",
      compensationIncluded22xi22xii:
        "22xii: Whether any compensation, included in 22xi, paid to non-residents",
      yesAmountPaid22xiii: "22xiii: If yes, amount paid to non-residents",
      insurance23: "23: Insurance",
      medicalInsurance23i: "23i: Medical Insurance",
      lifeInsurance23ii: "23ii: Life Insurance",
      keyManInsurance23iii: "23iii: Key-man's Insurance",
      otherInsurance23iv:
        "23iv: Other Insurance including factory, office, car, goods etc.",
      totalExpenditure23v:
        "23v: Total expenditure on insurance (23i + 23ii + 23iii + 23iv)",
      workmenAndStaff24: "24: Workmen and staff welfare expenses",
      entertainment25: "25: Entertainment",
      hospitality26: "26: Hospitality",
      conference27: "27: Conference",
      salesPromotion28:
        "28: Sales promotion including publicity (other than advertisement)",
      advertisement29: "29: Advertisement",
      commission30: "30: Commission",
      paidOutsideIndia30i:
        "30i: Paid outside India, or paid in India to a non-resident other than a company or a foreign company",
      toOthers30ii: "30ii: To others",
      total30iii: "30iii: Total (i+ii)",
      royalty31: "31: Royalty",
      paidOutsideIndia31i:
        "31i: Paid outside India, or paid in India to a non-resident other than a company or a foreign company",
      toOthers31ii: "31ii: To others",
      total31iii: "31iii: Total (i+ii)",
      professionalConsultancy31:
        "32: Professional/Consultancy/Fee for technical services",
      paidOutsideIndia32i:
        "32i: Paid outside India, or paid in India to a non-resident other than a company or a foreign company",
      toOthers32ii: "32ii: To others",
      total32iii: "32iii: Total (i+ii)",
      hotelBoarding33: "33: Hotel, boarding and Lodging",
      travelingExpenses34:
        "34: Traveling expenses other than on foreign traveling",
      foreignTravelling35: "35: Foreign travelling expenses",
      conveyanceExp36: "36: Conveyance expenses",
      telephoneExpenses37: "37: Telephone expenses",
      guestHouseExp38: "38: Guest House expenses",
      glubExpenses39: "39: Club expneses",
      festivalCelebrationExp40: "40: Festival celebration expenses",
      scholarship41: "41: Scholarship",
      gift42: "42: Gift",
      donation43: "43: Donation",
      ratesAndTaxes44:
        "44: Rates and taxes, paid or payable to Government or any local body (excluding taxes on income)",
      union44i: "44i: Union excise duty",
      serviceTax44ii: "44ii: Service tax",
      vatSales44iii: "44iii: VAT/Sales tax",
      cess44iv: "44iv: Cess",
      centralGoods44v: "44v: CentralGoods & Service Tax (CGST)",
      stateGoods44vi: "44vi: State Goods & Services Tax (SGST)",
      integratedGoods44vii: "44vii: Integrated Goods & Services Tax (IGST)",
      unionTerritoryGoods44viii:
        "44viii: Union Territor Goods & Services Tax (UTGST)",
      anyOtherRate44ix:
        "44ix: Any other rate, tax duty or cess including STT and CTT",
      totalRates44x:
        "44x: Total rates and taxes paid or payable (44i + 44ii + 44iii + 44iv + 44v + 44vi + 44vii + 44viii + 44ix)",
      auditFee45: "45: Audit fee",
      otherExpenses46: "46: Other expenses (specify nature and amount)",
      total46i: "46i: Total",
      badDebts47: "47: Bad debts",
      badDebts47i:
        "47i: Bad debts (specify PAN of the person, if available, for whom Bad Debts for amount of RS. 1 lakh or more is claimed and amount)",
      others47ii:
        "47ii: Others (more than Rs. 1 lakh) where PAN is not available (provide details using button Add/Edit Bad Debts at the bottom)",
      otherLess47iii: "47iii: Others (amount less than Rs. 1 lakh)",
      totalBadDebts47iv: "47iv: Total Bad Debt (47i (All PAN) + 47ii + 47iii)",
      provisionBadDoutful48: "48: Provision for bad and doutful debts",
      otherProvision49: "49: Other provisions",
      ProfitBeforeInterest50:
        "50: Profit before interest, depreciation and taxes [15 - (16 to 21 + 22xi + 23v + 24 to 29 + 30iii +31iii + 32iii + 33 to 43 + 44x + 45 + 46 + 47iv + 48 + 49)]",
      interest51: "51: Interest",
      paidOutsideIndia51i:
        "51i: Paid outside India, or paid in India to a non-resident other than a company or a foreign company",
      toOthers51ii: "51ii: To others",
      total51iii: "51iii: Total (i+ii)",
      depreciation52: "52: Depreciation and amortisation",
      netProfit53: "53: Net Profit before taxes (50-51iii-52)",
      provisionCurrentTax54: "54: Provision for current tax",
      provisionDeferredTax55: "55: Provision for deferred tax",
      profitAfterTax56: "56: Profit after tax (53 - 54 - 55)",
      balanceBroughtForward57: "57: Balance brought forward from previous year",
      amountAvailable58: "58: Amount available for appropriation (56 + 57)",
      transferredToReserves59: "59: Transferred to reserves and surplus",
      balanceCarriedtoBalanceSheet60:
        "60: Balance carried to balance sheet in proprietor's account (58-59)",

      //Bad Debts
      fillDetails: "Fill Your Details",
      name: "Name",
      amount: "Amount",
      flatDoorBlock: "Flat/Door/Block Number *",
      premiseName: "Premise Name",
      optionalMsg: "(This field is optional)",
      roadStreet: "Road/Street",
      pincode: "Pincode *",
      areaLocality: "Area Locality *",
      townCity: "Town/City *",
      state: "State *",
      country: "Country *",
      india: "India",

      //New Updates as per ITR5

      investmentInProperty: "2ai: Investment in property",
      equityInstrument2aii:"2aii: Equity instruments",

      listedEquityLT: "2aiiA: Listed equities",
      unlistedEquityLT: "2aiiB: Unlisted equities",
      total2aiiC :"2aiiC: Total",

      preferenceShareLT: "2aiii: Preference Shares",
      govtSecuritiesLT: "2aiv: Government or trust securities",
      debentureOrBondsLT: "2av: Debenture or bonds",
      mutualFundsLT: "2avi: Mutual Funds",
      OtherLTInvestment: "2avii: Others",
      total3aviii : "3aviii: Total Long-term investments (i + iiC + iii + iv + v + vi + vii)",

      equityInstrument2bi:"2bi: Equity instruments",
      listedEquityST: "2biA: Listed equities",
      unlistedEquityST: "2biB: Unlisted equities",
      total2biC: "2biC: Total",

      shortTermGovtSecurities: "2biii: Government or trust securities",
      shortTermDebentures: "2biv: Debenture or bonds",
      shortTermMutualFunds: "2bv: Mutual Funds",
      shortTermOthers: "2bvi: Others",
      total2bvii: "2bvii: Total Short-term investments (iC + ii + iii + iv + v + vi)",
      totalInvestment2c_5: "2c: Total investments (aviii + bvii)",

      currentAssetRawMaterial: "3aiA: Raw materials",
      currentAssetWorkInProgress: "3aiA: Work in progress",
      currentAssetFinishedGoods: "3aiA: Finished Goods",
      currentAssetStock: "3aiA: Stock-in-trade (in respect of goods acquired for trading)",
      currentAssetStore: "3aiA: Stores/Consumables including packing material",
      currentAssetLoseTools: "3aiA: Lose tools",
      currentAssetOthers: "3aiA: Others",
      total3aiH: "3aiH: Total (iA + iB + iC + iD + iE + iF + iG)",

      sundryDebtors: "3aiiA: Outstanding for more than one year",
      sundraDebtorsOther: "3aiiB: Others",
      total3aiiC: "3aiiC: Total Sundry Debetors",

      loanAndAdvances3bc:"3bv: Loans and advances inclused in biv which is",
      otherCashBalance: "3aiiC: Others",
      total3aiiiD: "3aiiD: Total Cash and cash equivalents (iiA + iiB + iiC)",
      loanForBusinessPurpose: "3bvA: for the purpose of business or profession",
      loanNotForBusinessPurpose: "3bvB: not for the purpose of business or profession",
      currentLiabilitiesSundry: "3diA1: Outstanding for more than one year",
      currentLiabilitiesOther: "3diA2: Other",
      total3diA3: "3diA3: Total",
      incomeReceivedInAdvance: "3diE: Income received in advance",
      otherPayable: "3diF: Other payables",
      total3diG: "3diG: Total (iA + iB + iC + iD + iE + iF)",
      profitAndLossAccount_5: "4c: Debit balance in profit and loss account/Accumulated balance",

      salaryRemunerationToPartner: "46: Salary/Remuneration to Partners of the firm",
      otherExpense47: "47: Other expenses (specify nature and amount)",
      badDebtsWithPan: "48i: Bad debts (specify PAN of the person, if available, for whom Bad Debts for amount of RS. 1 lakh or more is claimed and amount)",
      others48ii: "48ii: Others (more than Rs. 1 lakh) where PAN is not available (provide details using button Add/Edit Bad Debts at the bottom)",
      otherAmountDownOneLakh: "48iii: Others (amount less than Rs. 1 lakh)",
      totalBadDebts47iv_5: "48iv: Total Bad Debt (47i (All PAN) + 47ii + 47iii)",
      provisionForBadAndDoubtful: "49: Provision for bad and doutful debts",
      otherProvisions: "50: Other provisions",
      ProfitBeforeInterest50_5: "51: Profit before interest, depreciation and taxes [15 - (16 to 21 + 22xi + 23v + 24 to 29 + 30iii +31iii + 32iii + 33 to 43 + 44x + 45 + 46 + 47i + 48iv + 49 + 50)]",
      interest52: "52: Interest",
      paidOutside52a: "52a: Paid outside India, or paid in India to a non-resident other than a company or a foreign company",
      interestPainOutsideIndia: "52ai: To Partners",
      interestPaidOutSideIndiaOther: "52aii: To Others",
      painInIndia52b: "52b: Paid in India, or paid to a resident",
      interestPainInIndia: "52bi: To Partners",
      interestPaidInIndiaOther: "52bii: To Others",
      total52c: "52c: total (ai + aii + bi + bii)",
      depreciationAndAmortisation: "53: Depreciation and amortisation",
      netProfit54: "54: Net Profit before taxes (51-52iii-53)",
      provisionForCurrentTax: "55: Provision for current tax",
      provisionForFeferredTax: "56: Provision for deferred tax",
      profitAfterTax57: "57: Profit after tax (54 - 55 - 56)",
      BalanceBroughtForwardFromPreviousYear: "58: Balance brought forward from previous year",
      amountAvailable59: "59: Amount available for appropriation (57 + 58)",
      TransferredToReservesAndSurplus: "60: Transferred to reserves and surplus",
      balanceCarriedtoBalanceSheet61: "61: Balance carried to balance sheet in proprietor's account (59-60)",

    },
    tax_saving: {
      section_80c_investment: "Section 80c - Investment ",
      add_up_all_your_80c_deductions_specify:
        "Add up all your 80C deductions and specify the total amount here",
      section_80c_medical_insurance:
        "Section 80D - Medical Insurance and Preventive Health Checkup",
      decuction_for_medical_insurance:
        "Deductions for Medical Insurance or Preventive Health Check-Up fees or Medical Expenditure incurred by you.",
      do_you_have_medical_insurance:
        "Do you have a medical insurance for self, family or parents?",
      medical_insurance_premium: "Medical insurance Premium",
      self_and_family: "Self and Family",
      parents: "Parents",
      expenditure_can_claimed_only_for_senior_citizens: `This expenditure can be claimed only for senior citizens who don't have medical insurance policy`,
      section_80tta_deduction_for_interest:
        "Section 80TTA - Deduction for Interest earned on Savings Bank Account",
      interest_earned_on_saving_bank_account:
        "Interest earned on Savings Bank Account",
      section_80ccc_contributio_pension_plan:
        "Section 80CCC - Contribution to Pension Plan / Annuity Fund",
      contribution_amount_pension_plan:
        "Contribution amount to Pension Plan / Annuity Fund",
      section_80ccd_employee_contribution_to_pension_scheme:
        "Section 80CCD (1) and (1B) - Employee Contribution to New Pension Scheme (NPS)",
      please_enter_your_own_contribution:
        "please enter your own contribution to NPS",
      section_80ccd_employer_contribution:
        "Section 80CCD(2) - Employer contribution to NPS",
      enter_employer_contribution_to_nps:
        "Enter the employer contribution to NPS",
      section_80g_donations_charitable_organizations:
        "Section 80G - Donations to charitable organizations",
      did_you_donate_any_amount_to_charitable_organization_in_last_financial_year:
        "Did you donate any amount to charitable organizations in the last financial year?",
      section_80e_education_loan_on_higher_studies:
        "Section 80E - Education Loan on higher studies (Graduate or PostGraduate)",
      interest_on_higher_education_loan_paid_this_year:
        "Enter the interest on higher education loan paid this year",
      section_80gg_deduction_for_house_rent:
        "Section 80GG - Deduction for House Rent (Self-employed or Salaried with no HRA)",
      enter_rent_paid_per_month: "Enter the rent paid per month",
      number_of_months: "Number of months",
      section_80gga_donations_for_scientific_research_or_rural_development:
        "Section 80GGA - Donations for Scientific Research or Rural Development",
      govt_requires_itemized_details_donations_for_section_80gga:
        "The government requires itemized details of donations for Section 80GGA.",
      section_80dd_disable_dependent_deducton:
        "Section 80DD - Disable dependent deduction. (Spouse/Children/Parents)",
      add_dependent_with_40_disability: "Add Dependent with 40% Disability",
      add_dependent_with_80_disability: "Add Dependent with 80% Disability",
      per_40_disability: "40% Disability",
      per_80_disability: "80% Disability",
      section_80u_self_disability: "Section 80U - Self Disability",
      offers_tax_benefits_if_individual_suffers_disability:
        "Offers tax benefits if an individual suffers a disability.",
      disability: "Disability",
      section_80ee_interest_on_home_loan:
        "Section 80EE - Interest on Home Loan",
      interest_on_first_house_loan_2016_2017:
        "Interest on first-house loan(loan should be sanctioned between Apr 2016 and Mar 2017)",
      section_80eea_interest_on_home_loan:
        "Section 80EEA - Interest on Home Loan",
      interest_on_first_house_loan_2019_2022:
        "Interest on first-house loan(loan should be sanctioned between Apr 2019 and Mar 2022),",
      section_80eeb_electric_vehicle_loan:
        "Section 80EEB - Electric Vehicle Loan",
      criteria_of_section_80eeb:
        "Read about the eligibility criteria of Section 80EEB: Deduction under section 80EEB is available on interest on loan taken for the purchase of an electric vehicle",
      section_80qqb_royalty_received_on_books:
        "Section 80QQB - Royalty received on books",
      enter_the_royaly_fee_received_by_authors:
        "Enter the royalty/copyright fee or lumpsum received by authors of literary/scientific/artistic books",
      section_80rrb_income_on_parents:
        "Section 80RRB - Income on Parents/Inventions",
      deductions_amount_for_patentee: "Enter the deduction amount for patentee",
      section_80ggc_contribution_to_political_party:
        "Section 80GGC - Contribution to political party",
      enter_the_cotribution_made_to_political_party:
        "Enter the contribution or donation made to a political party",
      section_80jja_bio_degradable:
        "Section 80JJA - Bio-degradable waste business",
      deduction_exempting_profits_from_bio_degradable_waste_business:
        "Enter the deduction exempting profits from bio-degradable waste business",
      section_80ddb_deductons_for_treatment_specified_diseases:
        "Section 80DDB: Deductions for treatment of specified diseases and ailments",
      age_of_person_who_deduction_being_claimed:
        "Age of person for whom deduction is being claimed",
      medical_treatment_costs_for_specified_deseases:
        "Medical treatment costs for specified diseases under Section 80DDB",
      section_80cch: "Section 80CCH",
      contribution_to_agnipath_scheme: "Contribution to Agnipath scheme",
      tax_paid_tds_tcs: "Taxes Paid, TDS and TCS",
      taxes_collected_source_advance_tax_from_26as:
        "Taxes deducted or collected at source, Advance Tax or Self Assessment Tax already paid; you can Upload Form 26ASto fetch these details.",
      tds_on_salary: "TDS on Salary",
      salary_tds_entries_if_anything_missing:
        "This is your Salary TDS entries. If there’s anything missing, kindly check the income sources page",
      non_salary_tds: "Non Salary TDS",
      add_entries_from_tds_on_income_professional_income:
        "Add the entries from TDS on interest income, professional or consulting income ",
      tds_on_sale_of_immovable_property:
        "TDS on Sale/Rent of Immovable Property",
      if_sell_land_the_buyer_have_deducted_tds_at_rate_1:
        "If you sell/rent land, house, property or building etc (immovable property) etc, the buyer/tenant may have deducted TDS at the rate of 1% on the sale price of the property/rental and issued you a TDS certificate.You need to specify those TDS deduction details here.",
      taxes_collected_at_source: "Taxes collected at source",
      add_all_tcs_entries_for:
        "Add all the TCS entries for ex : on purchase of cars",
      self_tax_payments: "Self tax payments",
      add_entries_here_if_voluntarily_deposited_taxes:
        "Add entries here if you voluntarily deposited taxes to the government via Challan 280.",
      donation_details: "Donation Details",
      comman_80g_organizations: "Common 80G Organizations",
      name_of_donee: "Name of Donee",
      donation_amount_cash: "Donation Amount (Cash)",
      donation_amount_non_cash: "Donation Amount (Non Cash)",
      pan_of_donee: "PAN of Donee",
      limit_on_deduction: "Limit on Deduction",
      no_limit: "No Limit",
      subject_to_income: "Subject To income",
      qualifying_percentage: "Qualifying Percentage",
      deduction_80gga: "Deduction 80GGA",
      clause_under_which_donation_is_made:
        "Clause under which donation is made",
      tds_non_salary: "TDS - Non Salary",
      tax_deducted_at_source: "Tax Deducted at source",
      provide_this_information_for_tds:
        "Provide this information from TDS certificate provided by deductor",
      tan_of_deductor: "TAN of Deductor",
      name_of_deductor: "Name of Deductor",
      total_tax_deducted: "Total Tax Deducted",
      income_against_which_tds_was_deducted:
        "Income against which TDS was deducted",
      type_of_income: "Type of Income",
      financial_year_tds_was_deducted:
        "Financial Year in which TDS was deducted",
      tds_sale_rent_immovavle_property: "TDS - Sale/Rent of Immovable Property",
      pan_of_property_buyer: "PAN of Property Buyer / Tenant",
      name_of_property_buyer: "Name of Property Buyer / Tenant",
      claim_transfer_tds_credits:
        "Claim ot Transfer TDS credits from another assessee",
      claim_transfer_tcs_credits:
        "Claim ot Transfer TCS credits from another assessee",
      tax_deducted_claimed: "Tax Deducted claimed for this year",
      tax_collected_source: "Tax Collected At Source",
      provide_information_from_tds:
        "Provide this information from TCS certificate provided by deductor",
      tan_of_collector: "TAN of Collector",
      name_of_collector: "Name of Collector",
      total_tax_collector: "Total Tax Collector",
      tax_collected_claimed_year: "Tax Collected claimed for this year",
      expenditure_year_tcs_deducted:
        "Expenditure Year in which TCS was deducted",
      financial_year_tcs_deducted: "Financial Year in which TCS was deducted",
      tax_paid_challan: "Tax Paid Challan Details",
      add_tax_paid_challan: "Add Tax Paid Challan Details",
      bsr_code: "BSR Code",
      challan_serial_no: "Challan Serial No",
      carry_forward_loss: "Carry Forward of Losses",
      loss_from_capital_gains_declared_in_last_8years:
        "Losses from capital gains, businesses you've declared in the last 8 years can offset income or gains you're declaring in the current ITR.",
      tds_credit_relating_to: "TDS credit relating to",
      tds_credit: "TDS Credit",
      income_relating_tds_credit: "Income relating to TDS Credit",
      pan_other_person: "PAN of Other Person",
      are_you_of_these_applicable_to_you:
        "Are any of these following applicable to you? If not, you can just skip this section",
      details_previous_years_losses: "Details of Previous Years' Losses",
      losses_from_previous_years_against_income_of_ay:
        "Add any losses from the previous years to set off against the income for AY 2023-2024.",
      depreciation_loss_from_previous_years:
        "Depreciation Loss from Previous Years",
      add_depriciation_losses_from_prev_year:
        "Add any Depriciation losses from the previous years to set off againt the income for AY 2023-2024 .",
      depriciation_losses_previous_years_set_off_againt:
        "Add any Depreciation losses from the previous years to set off against the income for AY 2023-2024 .",
      filing_due_date_loss_in_previous_years:
        "Filing and Due Dates for Loss Set-off in Previous Years",
      below_60: "Below 60 Years",
      above_60: "Above 60 Years",
      prevents_health_checkup_covered_insurance:
        "Is preventive health check-up covered by your insurance ? ",
      upload_tax_paid_challan: "Upload Tax Paid Challan",
      sever_disability: "Severe Disability",
      forty_percent_disability: "Forty Percent Disability",
      type_of_dependent: "Type of Dependent",
      pan_of_dependent: "PAN of the dependent",
      section_80ttb_intrest_on_deposites:
        "Section 80TTB - Interest on Deposits",
      interest_earned_deposites: "Interest earned on Deposits",
      details_contribution_made: "Details of the Contribution  Made",
      amount_of_contribution: "Amount of Contribution",
      eligible_amount_of_contribution: "Eligible Amount Of Contribution",
      transaction_details: "Transaction Details ",
      reference_number:
        "Reference  Number*(UPI Transfer/ Cheque Number/IMPS/NFT/RTGS",
      assessment_year_loss: "Assessment Year of the Loss",
      loss_category: "Loss Category",
      loss_amount: "Loss Amount",
    },
    other_disclosures: {
      other_disclosure: "Other Disclosures",
      select_relevent_option: "Please select a relevant option",
      current_residential_status:
        "Current residential status: Resident You are a citizen of India, who left India, for the purpose of employment, as a member of the crew of an Indian ship and were in India for 182 days or more from 1 April 2022 to 31 March 2023 and 365 days or more from 1 April 2018 to 31 March 2022",
      resident_you_were_in_india_for_182_days:
        "Resident: You were in India for 182 days or more from 1 April 2022 to 31 March 2023",
      resident_you_were_in_india_for_60_days:
        "Resident: You were in India for 60 days or more from 1 April 2022 to 31 March 2023, and have been in India for 365 days or more from 1 April 2018 to 31 March 2022",
      resident_you_were_citizen_of_india_who_left_india:
        "Resident: You are a citizen of India, who left India, for the purpose of employment, as a member of the crew of an Indian ship and were in India for 182 days or more from 1 April 2022 to 31 March 2023 and 365 days or more from 1 April 2018 to 31 March 2022R",
      resident_citizen_of_india_or_person_of_india_origin:
        "Resident: You are a citizen of India or a person of Indian origin and have come on a visit to India during the previous year and were in India for a) 182 days or more from 1 April 2022 to 31 March 2023 and 365 days or more from 1 April 2018 to 31 March 2022; or b) 120 days or more from 1 April 2022 to 31 March 2023 and 365 days or more from 1 April 2018 to 31 March 2022 if the total income, other than income from foreign sources, exceeds Rs. 15 lakh",
      resident_but_not_ordinarily_resident:
        "Resident but not Ordinarily Resident: You are a citizen of India or person of Indian origin, who comes on a visit to India, having total income, other than the income from foreign sources, exceeding Rs. 15 lakh and have been in India for 120 days or more but less than 182 days from 1 April 2022 to 31 March 2023",
      resident_but_not_ordinarily_resident_citizen_of_india_having_total_income:
        "Resident but not Ordinarily Resident: You are a citizen of India having total income, other than the income from foreign sources, exceeding Rs. 15 lakh during the previous year and not liable to tax in any other country or territory by reason of your domicile or residence or any other criteria of similar nature",
      not_resident_in_india_in_9_10_years:
        "Resident but not Ordinarily Resident: You have been non-resident in India in 9 out of 10 years from 1 April 2013 to 31 March 2022",
      in_india_for_729_days_or_less:
        "Resident but not Ordinarily Resident: You have been in India for 729 days or less from 1 April 2015 to 31 March 2022",
      non_resident_during_period_2022_to_2023:
        "Non-Resident: You were a non-resident during the period 1 April 2022 to 31 March 2023",
      period_of_stay_in_india_1year:
        "Total period of stay in India during 1 April 2022 & 31 March 2023 (in days)",
      period_of_stay_in_india_4year:
        "Total period of stay in India during 1 April 2018 & 31 March 2022 (in days)",
      jurisdiciton_residence: "Jurisdiction of Residence",
      jurisdiciton_residence_place_of_stay_outside_india:
        "Jurisdiction of Residence is the place of stay outside India. Tax Identification Number is your ID number. E.g. If you live in USA, SSN number is your Tax Identification Number. If you don't have a Tax Identification Number, just enter your Passport Number.",
      jurisdiciton_resident: "Jurisdiction Of Residence",
      tax_identification_no: "Tax Identification Number",
      unlisted_shares: "Unlisted Shares",
      owned_shares_unlisted_company_in_india:
        'If you have owned shares in any unlisted company in India, you need to report the details to the tax department (unlisted company is typically a "Pvt. Ltd" instead of a "Public Ltd.")',
      directorship_details: "Directorship Details",
      director_of_company_details:
        "If you are a director of a company, please fill these details.",
      schedule_assets_liabilities: "Schedule AL: Assets & Liabilities",
      mandatory_schedule_income_more_than_50_lakhs:
        "It is mandatory to fill Schedule AL if your total income is more than ₹50 lakhs. Please enter your assets and liabilities at the end of financial year.",
      current_account_deposits: "Current Account Deposits",
      deposited_more_than_1cr:
        "If you have deposited more than ₹1 crore aggregating to all your current accounts between 1 April 2022 to 31 March 2023, please enter the total amount deposited.",
      expenditure_foreign_travel: "Expenditure on Foreign Travel",
      incurred_expenditure_of_amount_more_than_2lakh:
        "If you have incurred an expenditure of a total amount of more than ₹ 2 lakhs on Foreign Travel between 1 April 2022 to 31 March 2023, please enter the total expense amount.",
      expenditure_electricity_consumption:
        "Expenditure on Electricity Consumption",
      incurred_expenditure_amount_more_than_1lakh:
        "If you have incurred an expenditure of a total amount of more than ₹ 1 lakh on electricity consumption between 1 April 2022 to 31 March 2023, please enter the total expense amount.",
      clause_iv_seventh_proviso_to_139: "Clause(iv), seventh proviso to 139(1)",
      condition_clause_4_seventh_proviso_return_filed:
        "Any of the conditions in clause 4, seventh proviso to returns filed under section 139(1) apply to you:",
      turnover_gross_recipts_person_in_business:
        "Total sales, turnover or gross receipts of the person in business is higher than Rs. 60 lakh during the previous year.",
      gross_receipts_profession:
        "Your total gross receipts from profession is greater than Rs. 10 lakh during the previous year",
      total_tax_deducted_and_collected_source:
        "Total of tax deducted at source and tax collected at source, in case of the person, is greater than Rs. 25 thousand during the previous year.",
      deposited_in_lakh_during_previous_year:
        "You have deposited a total of Rs. 50 lakh or more in savings accounts during the previous year",
      company_details: "Company details",
      name_of_company: "Name of Company",
      company_type: "Company type",
      pan_of_company: "PAN of Company",
      opening_balance: "Opening Balance",
      no_of_shares: "No. of shares",
      cost_of_acquisition: "Cost of acquisition",
      shares_acquired_during_the_year: "Shares acquired during the year",
      face_value_per_share: "Face Value Per Share",
      issue_price_per_share: "Issue Price Per Share (In case of fresh share)",
      purchase_price_per_share: "Purchase Price Per Share",
      shares_transferred_during_year: "Shares transferred during the year",
      sale_consideration: "Sale Consideration",
      closing_balance: "Closing Balance",
      listed_unlisted_shares: "Listed or Unlisted Shares",
      director_identification_no: "Director Identification Number (DIN)",
      financial_assets: "Financial assets",
      deposits_in_bank: "Deposits in Bank",
      total_balance_in_saving_account:
        "Total balance in any savings bank accounts, fixed deposits, current accounts, etc. ",
      shares_and_securities: "Shares and securities",
      stocks_mutual_funds: "Stocks, mutual funds, etc",
      insurance_policies: "Insurance Policies ",
      sum_assured_from_insurance_policies:
        "Sum Assured from any insurance policies you hold",
      total_amount_physical_cash_you_have:
        "Enter the total amount of physical cash you have (Rupee notes, etc).",
      loans_and_advances_given: "Loans and Advances Given",
      if_you_given_loan_to_someone:
        "Fill this if you have given a loan to someone.",
      other_assets: "Other Assets",
      jewellery_bullion: "Jewellery, bullion etc.",
      includes_jewellery_precious_metals_precious_stones:
        "Includes any jewellery, precious  metals (gold, silver) or precious stones (diamonds, etc) that you own.",
      vehicles: "Vehicles",
      cars_motor_vehicles_yachts_boats_aircrafts:
        "Cars, motor vehicles, and even yachts, boats and aircrafts.",
      artwork: "Artwork",
      painting_sculptures_work_of_art_including_archaeological:
        "Painting, sculptures or any work of art, including archaeological collections",
      you_own_immovable_assets_house_land:
        "Do you own any immovable assets (house, land, etc)?",
      immobable_assets_house_land: "Immovable assets (house, land, etc)",
      partner_or_investment_in_firm_llp_aop:
        "Are you a partner or have made an investment in any partnership firm / LLP / AOP?",
      liability_relation_assets: "Liability in Relation to Assets",
      loans_you_have_in_relation:
        "Any loans you may have in relation to these assets.",
      property_details: "Property details",
      amount_cost_ot_purchase: "Amount (Cost of Purchase)",
      firm_details: "Firm details",
      pan_of_firm: "PAN of the firm",
      investment: "Investment",
      address_of_firm_aop: "Address of the firm(s)/ AOP(s)",
      interest_held_in_assets_firm_aop:
        "Interest Held In Assets Of Firm Or AOP",
      sales_turnover_gross_receipts_peron_in_business:
        "If total sales, turnover or gross receipts of the person in business is higher than ₹ 60 lakh between 1 April 2022 to 31 March 2023, please enter the total sales, turnover or gross receipts value. ",
      total_gross_from_profession_greater_than_10_lakh:
        "If your total gross receipts from profession is greater than ₹ 10 lakh between 1 April 2022 to 31 March 2023, please enter the total gross receipts value.",
      total_tax_deducted_source_collected_at_source:
        "If total of tax deducted at source and tax collected at source, in case of the person, is greater than ₹ 25 thousand between 1 April 2022 to 31 March 2023, please enter the total of TDS and TCS amount.",
      deposited_total_50_lakh_or_more:
        "If you have deposited a total of ₹ 50 lakh or more in savings accounts between 1 April 2022 to 31 March 2023, please enter the total deposits amount.",
    },
    form: {
      auto_fetch_details_upload_form_16:
        "Auto-Fetch your details effortlessly by uploading Form 16",
      pre_fill_in_just_min:
        "Don’t have form 16? Pre-fill & e-file in just 3 mins ",
      choose_your_form_16: "Choose your Form-16 PDF to upload",
      upload_password_protected_pdf:
        "You can upload a password protected PDF too. Please choose one file at a time.",
    },
    document: {
      choose_your_document: "Choose Your Document",
      aadhaar_card: "Aadhaar Card",
      verify_aadhaar_card: "Verify your Aadhaar Card",
      aadhaar_card_number: "Aadhaar Card Number",
      enter_12_digit_aadhaar_number: "Enter 12 digit Aadhaar number",
      enter_aadhaar_registered_number:
        "Please enter Aadhaar registered mobile number",
      cards: "Cards",
      upi: "UPI",
      e_verify_return: "E-verify IT Returns",
      validate_and_allow_to_process:
        "Validate and allow the IT department to process your return.",
      option_to_receive_otp_evc: "Choose an option to receive OTP/EVC",
      bank_evc: "Bank EVC (Electronic Verification Code)",
      e_verification: "e-Verification Successful",
      generate_evc: "Generate electronic verification code (EVC)",
      through_bank: "Through Bank Account",
      through_net_banking: "Through Net Banking",
      through_demat: "Through Demat Account",
      net_banking_note:
        "Once you click on Continue. you will be logged out of  e-Filling portal and will be redirected to a page. where you can select bank to login through net banking",
      disclaimer: "Disclaimer",
      filling_details: "Filling Details",
      filling_status: "Filling Status",
      enter_evc: "Enter EVC ",
      allowed_once: "Allowed only once",
    },
    placeholder: {
      enter_12_digit: "Enter 12 digit number",
      enter_10_digit: "Enter 10 digit number",
      enter_28_digit: "Enter 28 digit number",
      enter_landline_no: "Enter your landline tel",
      email_address: "email.123@gmail.com",
      organizations_80g: "80G organizations",
      name_of_donee_organization: "Name of the donee organization",
      pan_of_donee_here: "Enter PAN of Donee here",
      address_of_donee: "Address of Donee",
      enter_pin_code: "Enter PIN Code here",
      enter_name_of_donee: "Enter Name of the Donee here",
      pan_of_tenant: "PAN of Tenant",
      name_of_buyer: "Name of Buyer",
      yes: "Yes",
      no: "No",
      enter_10_digit_pan: "Enter 10 digit PAN",
      type_your_text: "Type your text here...",
      bsr_code_usually_7_dig: "BSR code is usually 7 digits",
      challan_no_is_5_digits: "Challan no. is 5 digits",
      search: "Search ...",
    },
    message: {
      enter_if_you_donated_to_govt:
        "Enter ‘GGGGG0000G’ if you have donated to Government Funds that doesn’t have a PAN",
      amount_is_same_as_tax_deducted:
        "Usually this amount is same as Total Tax Deducted.",
      amount_is_same_as_tax_collected:
        "Usually this amount is same as Total Tax Collected",
      financial_year_of_return:
        "This is the same as financial year of the return (2022-23)",
      leave_if_not_deducted_in_name:
        "Leave it blank if not deducted in the name of assessee",
      this_is_same_as_financial_year:
        "This is the same as financial year of the return (2022-23) ",
      date_format: "Specify date in a format like DD/MM/YYYY",
      max_total_expenses:
        "Total expenses should be less than 50% of gross revenue",
      max_gross_revenue:
        "Gross revenue should be less than 50 Lakhs Total Expenses",
      should_at_least_6per_gross_revenue:
        "Should be at least 6% of gross revenue From Cash or Non-Digital Modes",
      should_at_least_8per_gross_revenue:
        "Should be at least 8% of gross revenue",
      received_any_interest_coupon_payment_in_same_financial_year:
        "NOTE : If you have received any interest or coupon payment for this investment in the same financial year, please specify it in the Interest Income Section",
      specify_date_of_sale_if_you_had_any_asset:
        "Please specify date of sale if you had sold any asset. This date is used for interest calculation purposes in case there is some capital gain. If you do not specify a date, then the capital gain will be attributed to the last quarter for purpose of interest calculation.",
      during_year_for_assets_purchased_from:
        "I.e. during A.Y. 2022-2023, for assets purchased from Oct 04 2021.",
      this_page_give_sheet_way_to_enter_data: `This page gives you a spreadsheet-like way to enter data. Click on each section header ("Sources of Funds","Application of Funds", etc) to expand or collapse that section. In case of multiple businesses, enter each value as the sum of all the businesses.`,
      automatically_copied_pl: "Automatically copied from P&L.",
      automatically_copied_depreciation:
        "Automatically copied from Depreciation.",
      automatically_copied_from_firm_card:
        "Automatically copied from Remuneration from firm card",
      specify_nature_of_expense_incurred:
        "Specify nature of expenses incurred here. (e.g. Insurance premium payment for Arun Gupta)",
      ammount_to_be_paid_: "Amount yet to be paid to suppliers or vendors",
      loans_backed_by_collateral: "Loans backed by collateral or assets",
      amounts_received_in_advance: "Amounts received in advance",
      captial_provided_by_business_partners:
        "Capital provided by business partners",
      amount_collected_from_customers_clients:
        "Amounts yet to be collected from customers/clients.",
      total_worth_goods_held_for_sale: "Total worth of goods held for sale",
      total_cash_readily_available: "Total Cash readily available",
      amount_help_bank_accounts: "Amount held in bank accounts",
      value_entered_should_not_be_less_than_1cr:
        "Value entered should not be less than Rs. 1 Crore",
      value_not_less_than_2lakh:
        "Value entered should not be less than Rs. 2 Lakhs",
      leave_blank_deducted_in_name:
        "Leave it blank if not deducted in the name of assessee.",
      amount_tds_received_transfered: "Amount of TDS received or transfered.",
      select_atleast_one: "Please Select Atleast One Value",
      enter_negative_value: "Enter Negative Value. Example: -2000",
      are_you_sure: "Are you sure?",
      delete_sub_message:
        "Do you really want to delete this record? This process cannot be undone.",
    },
    address: {
      flat: "Flat",
      door_no: "Door No",
      block_no: "Block No",
      premise_name: "Premise Name",
      road: "Road",
      street: "Street",
      area_locality: "Area / Locality",
      pincode: "PIN code",
      city: "City",
      state: "State",
      name_of_district: "Name of District",
      measurement_acers: "Measurement (Acers)",
      ownweship_status: "Ownership Status",
      water_source: "Water Source",
      country: "Country",
      co_owners_details: "Co-Owners Details",
      in_case_this_property_is_jointly:
        "In case this property is jointly owned, please specify co-owners",
      name_of_co_owner: "Name of Co-owner",
      pan_of_co_owner: "PAN of Co-owner",
      percentage_stage: "Percentage Share",
      address_line: "Address Line",
      property_address: "Property Address",
      town: "Town",
      name_of_person_filing: "Name of Person Filing ITR",
      pan_no_of_person_filing: "PAN Number of Person Filing",
      additional_info: "Additional info",
    },
    button: {
      next: "Next",
      sign_in: "Sign In",
      submit: "Submit",
      link_your_pan: "Link Your PAN",
      add_bank: "Add Bank",
      add: "Add",
      save: "Save",
      add_more_item: "+ Add More Items",
      view_sample: "View Sample",
      upload_form_16: "Upload Form 16",
      send_otp: "Send OTP",
      continue: "Continue",
      close: "Close",
      cancel: "Cancel",
      save_and_continue: "Save and Continue",
      save_and_close: "Save and Close",
      save_and_back: "Save and Back",
      view_details: "View details",
      save_amount: "Save Amount",
      add_entry: "Add Entry",
      add_edit_entry: "Add or Edit Entry",
      export_data: "Export data",
      import_data: "Import data",
      download_template: "Download Template",
      add_new_donation: "Add New Donation",
      upload_from_26as: "Upload Form 26AS",
      add_manually: "Add Manually",
      resend: "Resend",
      continue_without_form_16: "Continue without Form 16",
      e_verify: "E Verify",
      download: "Download",
      resend_otp: "Resend OTP",
      edit_profile: "Edit Profile",
      ticketing_inhouse_chat: "Ticketing - In house Chat",
      edit_itr: "Edit  ITR",
      file_itr: "File ITR",
      upload_file: "Upload File",
      upload: "Upload",
      detailed_balance_sheet: "Detailed Balance Sheet",
      yes_fill_detailed: "Yes, Fill Detailed",
      go_back: "Go Back",
      detailed_profit_loss: "Detailed Profit and Loss",
      other_sections: "Other Sections",
      one_of_these_conditions_applies_to_me:
        "One of these conditions applies to me",
      change: "Change",
      import_excel: "Import Excel",
      close_entry: "Close Entry",
      delete: "Delete",
      edit: "Edit",
      addBadDebts: "Add Bad Debts",
    },
    signUp: {
      forgot_password: "Forgot Password",
      old_password: "Old Password",
      new_password: "New Password",
      or_continue_with: "Or continue with",
      enter_number_to_otp: "Enter your mobile number to get OTP",
      enter_opt_sent_to: "Enter the OTP sent to",
      enter_email_or_number:
        "Please enter your email / phone number to receive a verification code",
      verify_otp: "Verify OTP",
      enter_code_sent_to_email:
        "Please enter 4 digit code sent to your email / phone number",
      if_dont_receive_code: "If you don’t receive the code",
      create_new_password: "Create New Password",
      new_pass_must_be_different:
        "New password must be different from previously used password",
      reset_password: "Reset Password",
      verify_your_pan: "Verify your PAN (Mandatory step by IT Department)",
      pan_card_number: "PAN Card Number",
      choose_method_for_verifying_otp:
        "Choose a method for verifying OTP and we will prepare income tax returns in minutes",
      it_department_registered: "IT Department registered mobile number",
      aadhaar_registered_mobile_number: "Aadhaar registered mobile number",
      enter_otp_to_verify: "Enter OTP to Verify",
    },
    settings: {
      chat_support: "Chat Support",
      manage_payment_methods: "Payment Methods",
    },
    profile: {
      documents: "Documents",
      verified_document: "Verified Document",
      successfully_verified_document:
        "You have successfully verified your document and added on your documents.",
      add_document: "Add Document",
      payment_successful: "Payment Successful",
      ticket_number: "Ticket Number",
      select_service: "Select Service ",
      date: "Date",
      contact_us: "Contact Us",
      about_us: "About us",
      no_screen_upload_yet: "No screen uploaded yet.",
      screenshots: "Screenshots",
      update_profile: "Update Profile",
    },
    validation_message: {
      required: "This Field is Required",
      first_name: "First name is required",
      last_name: "Last name is required",
      invalid_email: "Invalid email",
      email: "Email is required",
      phone_number: "Phone number is required",
      password: "Password is required",
      confirm_password: "Confirm password is required",
      password_must_match: "Passwords must match with confirm password",
      phone_email: "Phone Number / Email is required",
      valid_otp: "Enter a valid OTP",
      message: "Message is required",
      invalid_pan: "PAN number is invalid",
      amount_greater_than_0: "Amount must be greater than 0",
      only_string_value: "Only alphabetic characters and spaces are allowed",
    },
    toast_message: {
      login_success: "Successfully Logged in",
      some_thing_went_wrong: "Something Went Wrong",
      successfully_upload_data: "Successfully Upload Data",
    },
  },
  theme: {
    primary_bg: "#FFA53B",
    secandary_bg: "#FFFFFF",
    tertiary_bg: "#EBEBEB",
    primary_txt: "#151515",
    secandary_txt: "#FAFAFA",
    border_clr: "#DDDDDD",
    green_txt: "#58C232",
    red_txt: "#D03838",
  },
  themeMode: "Light",
  activeTheme: "Orange",
  states: [],
  categories: [],
};

export const platformSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLATFORM_STRING:
      return { ...state, strings: action.payload };
    case SET_PLATFORM_THEME:
      return {
        ...state,
        theme: action.payload[0],
        themeMode: action.payload[1],
        activeTheme: action.payload[2],
      };
    case SET_ALL_STATES:
      return { ...state, states: action.payload };
    case SET_CATEGORIES:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};
