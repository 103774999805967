import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../styledComponent/Inputs/Select";
import Input from "../../../../styledComponent/Inputs/Input";
import { Formik } from "formik";
import {
  deleteBusinessDetails44AEAction,
  deleteGoodsCarriagesAction,
  editBusinessDetails44AEAction,
  saveBusinessDetails44AEAction,
  saveGoodsCarriagesAction,
} from "../../../../redux/itr/action";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import FormDialogBox from "../../../DialogBox/FormDialogBox";

export default function Section44AE({ businessCode, data, ownershipStatus }) {
  const [businessBox, setBusinessBox] = useState(false);
  const [businessFormData, setBusinessFormData] = useState({});
  const [goodsCarriageFormData, setGoodsCarriageFormData] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [goodsCarriageBox, setGoodsCarriageBox] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const submitBusinessDetails = async (data) => {
    const action = data.id
      ? editBusinessDetails44AEAction
      : saveBusinessDetails44AEAction;
    const payload = data.id
      ? {
          id: data.id,
          nameOfBusiness: data.nameOfBusiness,
          description: data.description,
          businessCode: data.businessCode.id,
          professionalBusinessId: data.professionalBusinessId,
          itrId: ItrData.id,
        }
      : data;

    dispatch(
      action(payload, (e) => {
        if (e.status === 200) {
          setFormData({
            ...formData,
            businessDetails: data.id
              ? formData.businessDetails.map((i) =>
                  i.id === data.id ? { ...i, ...e.data } : i
                )
              : [...formData.businessDetails, e.data],
          });
          toast.success(`Successfully ${data.id ? "Edit" : "Submit"} data`);
          setBusinessBox(false);
          setBusinessFormData({});
        } else {
          toast.error(e.message);
        }
        setLoading(false);
      })
    );
  };

  const submitGoodsCarriages = async (data) => {
    dispatch(
      saveGoodsCarriagesAction(data, (e) => {
        // if (e.status == 200) {
        //   setOptionData({
        //     ...optionData,
        //     businessProfessionIncome: {
        //       ...optionData.businessProfessionIncome,
        //       professionalIncome: e.data,
        //     },
        //   });
        //   setProfessionalIncomeForm(false);
        //   setLoading(false);
        // } else {
        //   toast.error(e.message);
        //   setLoading(false);
        // }
      })
    );
  };

  const delBusinessDetailsApi = async (type, id, businessId) => {
    if (type == "business") {
      const data = {
        id: id,
        professionalBusinessId: businessId,
      };
      dispatch(
        deleteBusinessDetails44AEAction(data, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              businessDetails: formData.businessDetails.filter(
                (i) => i.id != id
              ),
            });
            toast.success(e.data);
            setLoading(false);
          } else {
            toast.error(e.message);
            setLoading(false);
          }
        })
      );
    } else {
      const data = {
        id: id,
        professionalBusinessId: businessId,
      };
      dispatch(
        deleteGoodsCarriagesAction(data, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              goodsCarriageDetails: formData.goodsCarriageDetails.filter(
                (i) => i.id != id
              ),
            });
            toast.success(e.data);
            setLoading(false);
          } else {
            toast.error(e.message);
            setLoading(false);
          }
        })
      );
    }
  };

  useEffect(() => {
    setBusinessFormData({
      itrId: ItrData.id,
      businessCode: "",
      nameOfBusiness: "",
      description: "",
    });

    setGoodsCarriageFormData({
      numberOfMonths: "",
      // numberOfTons: 40,
      incomePerVehiclePerMonth: "",
      registrationNumber: "",
      ownershipStatus: "",
      itrId: ItrData.id,
    });
  }, []);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  console.log("formData ====> ", formData);
  return (
    <>
      <StyleFormBox>
        <div className="flex-1 flex items-center justify-between">
          <h4 style={{ color: theme.primary_txt }}>
            {strings.income.business_details}
          </h4>

          <button
            className="add_button"
            onClick={() => {
              setBusinessBox(!businessBox);
            }}
          >
            {businessBox ? strings.button.close : strings.button.add}
          </button>
        </div>

        {formData?.businessDetails?.map((i, ix) => (
          <StyleFormBox style={{ padding: "10px" }}>
            <h4>Name Of Business {i.nameOfBusiness}</h4>
            <div className="flex items-center justify-between">
              <p>Description {i.description}</p>
              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setBusinessFormData(i);
                    setBusinessBox(true);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() =>
                    delBusinessDetailsApi(
                      "business",
                      i.id,
                      i.professionalBusinessId
                    )
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          </StyleFormBox>
        ))}
      </StyleFormBox>

      <StyleFormBox>
        <div className="flex-1 flex items-center justify-between">
          <h4 style={{ color: theme.primary_txt }}>
            {strings.income.goods_carriage_details}
          </h4>

          <button
            className="add_button"
            onClick={() => {
              setGoodsCarriageBox(true);
            }}
          >
            {strings.button.add}
          </button>
        </div>

        {formData?.goodsCarriageDetails?.map((i, ix) => (
          <StyleFormBox style={{ padding: "10px" }}>
            <h4>Number of Month : {i.numberOfMonths}</h4>
            <div className="flex items-center justify-between">
              <p>income Per Vehicle PerMonth {i.incomePerVehiclePerMonth}</p>
              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setBusinessFormData(i);
                    setBusinessBox(true);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() =>
                    delBusinessDetailsApi(
                      "goods",
                      i.id,
                      i.professionalBusinessId
                    )
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          </StyleFormBox>
        ))}
      </StyleFormBox>

      {/* Business Details  form */}
      <FormDialogBox
        open={businessBox}
        formTitle={strings.income.add_business_details}
        handleClose={() => {
          setBusinessBox(false);
        }}
      >
        <Formik
          initialValues={businessFormData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            submitBusinessDetails(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <div>
              <StyleFormBox style={{ marginTop: "30px" }}>
                <h4>{strings.income.add_business_details}</h4>
                <hr />
                <div className="flex_input my-2">
                  <label>{strings.income.business_code}</label>
                  <Select
                    name={`businessCode`}
                    value={values?.businessCode}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>
                    {businessCode?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="flex_input my-2">
                  <label>{strings.income.name_of_business}</label>
                  <Input
                    name={`nameOfBusiness`}
                    value={values?.nameOfBusiness}
                    error={errors?.nameOfBusiness}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input mt-4">
                  <label>{strings.label.description}</label>
                  <Input
                    name={`description`}
                    value={values?.description}
                    error={errors?.description}
                    onChange={handleChange}
                  />
                </div>

                <button className="primary_button" onClick={handleSubmit}>
                  {strings.button.save_and_close}
                </button>
              </StyleFormBox>
            </div>
          )}
        </Formik>
      </FormDialogBox>

      {/* Goods Carriage Details */}
      <FormDialogBox
        open={goodsCarriageBox}
        formTitle={strings.income.add_goods_carriage_details}
        handleClose={() => {
          setGoodsCarriageBox(false);
        }}
      >
        <StyleFormBox style={{ marginTop: "30px" }}>
          <Formik
            initialValues={goodsCarriageFormData}
            enableReinitialize={true}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              submitGoodsCarriages(values);
            }}
          >
            {({ values, errors, handleChange, handleSubmit, setValues }) => (
              <div>
                <StyleFormBox style={{ marginTop: "30px" }}>
                  <h4>{strings.income.add_goods_carriage_details}</h4>
                  <hr />

                  <div className="flex_input my-2">
                    <label>{strings.income.number_of_months}</label>
                    <Select
                      name={`numberOfMonths`}
                      value={values?.numberOfMonths}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        {strings.label.choose_option}
                      </option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i, ix) => (
                        <option value={i} key={ix}>
                          {i}
                        </option>
                      ))}
                    </Select>
                  </div>

                  <div className="flex_input my-2">
                    <label>{strings.income.income_per_vehicle_per_month}</label>
                    <Input
                      name={`incomePerVehiclePerMonth`}
                      value={values?.incomePerVehiclePerMonth}
                      error={errors?.incomePerVehiclePerMonth}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex_input my-2">
                    <label>{strings.income.registration_number}</label>
                    <Input
                      name={`registrationNumber`}
                      value={values?.registrationNumber}
                      error={errors?.registrationNumber}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex_input my-2">
                    <label>{strings.income.ownership_status}</label>
                    <Select
                      name={`ownershipStatus`}
                      value={values?.ownershipStatus}
                    >
                      <option value="" disabled>
                        {strings.label.choose_option}
                      </option>
                      {ownershipStatus?.map((i, ix) => (
                        <option value={i.id} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>

                  <button className="primary_button" onClick={handleSubmit}>
                    {strings.button.save_and_close}
                  </button>
                </StyleFormBox>
              </div>
            )}
          </Formik>
        </StyleFormBox>
      </FormDialogBox>
    </>
  );
}
