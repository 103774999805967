import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDonationToResearchAssociationAction,
  deleteDonationsAction,
  deleteTDSImmovablePropertyAction,
  deleteTaxCollectedAction,
  deleteTaxPaidChallanAction,
  deleteTdsNonSalaryAction,
  getDeductionsAction,
  saveDeductionsAction,
} from "../../../redux/itr/action";
import { Formik } from "formik";
import Input from "../../../styledComponent/Inputs/Input";
import ToolTip from "../../../styledComponent/ToolTip";
import Select from "../../../styledComponent/Inputs/Select";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import ToggelButton from "../../../styledComponent/Button/ToggelButton";
import InformationNote from "../../../styledComponent/Button/InformationNote";
import AccordianBox from "../../../styledComponent/Form/AccordianBox";
import CheckBox from "../../../styledComponent/Inputs/CheckBox";
import Donation80G from "./Donation80G";
import Donation80GGA from "./Donation80GGA";
import { addOpacity, removeEmptyKeys } from "../../../utils/helpers";
import { RiDeleteBin6Fill } from "react-icons/ri";
import TaxPaidChallan from "./TaxPaidChallan";
import TaxCollectedSource from "./TaxCollectedSource";
import TdsImmovableProperty from "./TdsImmovableProperty";
import TdsNonSalary from "./TdsNonSalary";
import { FaRegEdit } from "react-icons/fa";
import LoadingBox from "../../DialogBox/LoadingBox";
import OtherDisclosuser from "./OtherDisclosures";
import Donation80GGC from "./Donation80GGC";
import { toast } from "react-toastify";
import FormDialogBox from "../../DialogBox/FormDialogBox";
import PrevYearLosses from "./CarryForwardLoss/PrevYearLosses";
import CarryForwardLoss from "./CarryForwardLoss";
import DeductionIOAA from "./DeductionIOAA";
import DonationsToRA from "./DonationsToRA";
import Deduction80IA from "./Deduction80IA";
import Schedule80LA from "./Schedule80LA";
import Section80P from "./Section80P";

export default function TaxSaving({ handleNext }) {
  const [deductionData, setDeductionData] = useState([]);
  const [formData, setFormData] = useState({});
  const [formLoad, setFormLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  // Section 80G - Donations
  const [donationBox, setDonationBox] = useState(false);
  const [donation80GData, setDonation80GData] = useState([]);

  // Section 80GGA - Donations
  const [donation80GGA, setDonation80GGA] = useState(false);
  const [donation80GGAData, setDonation80GGAData] = useState([]);

  // Section 80GGA - Donations
  const [donation80GGC, setDonation80GGC] = useState(false);
  const [donation80GGCData, setDonation80GGCData] = useState([]);

  // Non Salary TDS
  const [nonSalaryBox, setNonSalaryBox] = useState(false);
  const [nonSalaryData, setNonSalaryData] = useState([]);

  // TDS on Sale/Rent
  const [saleRentTdsBox, setSaleRentTdsBox] = useState(false);
  const [tdsImmovablePropertyData, setTdsImmovablePropertyData] = useState([]);

  // Taxes collected at source
  const [collectedSourceBox, setCollectedSourceBox] = useState(false);
  const [collectedSourceData, setCollectedSourceData] = useState([]);

  // Self tax payments
  const [challanForm, setChallanForm] = useState(false);
  const [challanData, setChallanData] = useState([]);

  // Deduction under section IOAA
  const [deductionIOAA, setDeductionIOAA] = useState(false);

  // Research associations (RA)
  const [donationsToRA, setDonationsToRA] = useState(false);
  const [donationsToRAData, setDonationsToRAData] = useState([]);

  // Deductions under section 8O
  const [deduction80IA, setDeduction80IA] = useState(false);

  // Deduction under section 80LA
  const [schedule80LA, setSchedule80LA] = useState(false);

  // Deduction under section 80P
  const [section80P, setSection80P] = useState(false);

  // For all donations Edit form
  const [editDonationFormData, setEditDonationFormData] = useState({
    donation80G: [],
    donation80GGA: [],
    donation80GGC: [],
    tdsNonSalary: [],
    tdsImmovableProperty: [],
    collectedSource: [],
    tcs: [],
    taxPaidChallan: [],
  });

  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const handleSubmitDeductionsForm = (data) => {
    data = { ...data, itrId: ItrData.id };
    dispatch(
      saveDeductionsAction(removeEmptyKeys(data), (e) => {
        if (e.status === 200) {
          toast.success("Successfully submit data");
          setLoading(false);
          handleNext();
        } else {
          setLoading(false);
        }
      })
    );
  };

  const handleDeleteRecord = (id, deductionId, type) => {
    setLoading(true);
    const ItrId = ItrData.id;
    if (type == "tdsOnSalary") {
      dispatch(
        deleteTdsNonSalaryAction({ id, ItrId }, (e) => {
          if (e.status == 200) {
            setNonSalaryData(nonSalaryData.filter((i) => i.id !== id));
          }
        })
      );
    } else if (type == "ImmovableProperty") {
      dispatch(
        deleteTDSImmovablePropertyAction({ id, ItrId }, (e) => {
          if (e.status == 200) {
            setTdsImmovablePropertyData(
              tdsImmovablePropertyData.filter((i) => i.id !== id)
            );
          }
        })
      );
    } else if (type == "tcs") {
      dispatch(
        deleteTaxCollectedAction({ id, ItrId }, (e) => {
          if (e.status == 200) {
            setTdsImmovablePropertyData(
              tdsImmovablePropertyData.filter((i) => i.id !== id)
            );
          }
        })
      );
    } else if (type == "taxChallan") {
      dispatch(
        deleteTaxPaidChallanAction({ id, ItrId }, (e) => {
          if (e.status == 200) {
            setChallanData(challanData.filter((i) => i.id !== id));
          }
        })
      );
    } else {
      dispatch(
        deleteDonationsAction({ id, deductionId }, (e) => {
          if (e.status == 200) {
            if (type == "80g") {
              setDonation80GData(donation80GData.filter((i) => i.id !== id));
            } else if (type == "80gga") {
              setDonation80GGAData(
                donation80GGAData.filter((i) => i.id !== id)
              );
            }
          }
        })
      );
    }
    toast.success("Succesfully Delete data ");
    setLoading(false);
  };

  const getDeductionsData = () => {
    setLoading(true);
    dispatch(
      getDeductionsAction(ItrData.id, (e) => {
        let data = e.data?.deductions;
        // console.log("getDeductionsData ========>", e.data);
        setFormData({
          anyOthSec80CCH: data?.anyOthSec80CCH || "",
          haveMedicalInsuranceParentsSeniorCitizens:
            data?.haveMedicalInsuranceParentsSeniorCitizens || false,
          haveMedicalInsuranceSelfAndFamily:
            data?.haveMedicalInsuranceSelfAndFamily || false,
          medicalInsuranceParents: data?.medicalInsuranceParents || "",
          medicalInsuranceParentsSeniorCitizens:
            data?.medicalInsuranceParentsSeniorCitizens || "", //no use
          medicalInsuranceSelfAndFamily:
            data?.medicalInsuranceSelfAndFamily || "",
          medicalTreatmentHandicappedDependent:
            data?.medicalTreatmentHandicappedDependent || "", //no use
          medicalTreatmentHandicappedDependentSevereDisability:
            data?.medicalTreatmentHandicappedDependentSevereDisability || "", //no use
          noOfMonthsForWhichRentPaid: data?.noOfMonthsForWhichRentPaid || "",
          havePreventiveMedicalInsuranceSelfAndFamily:
            data?.havePreventiveMedicalInsuranceSelfAndFamily || false,
          preventiveHealthCheckupFeeSelfAndFamily:
            data?.preventiveHealthCheckupFeeSelfAndFamily || "",
          preventiveMedicalInsuranceParents:
            data?.preventiveMedicalInsuranceParents || "",
          section80C: data?.section80C || "",
          section80CCC: data?.section80CCC || "",
          section80CCDEmployeeOrSE: data?.section80CCDEmployeeOrSE || "",
          section80CCDEmployer: data?.section80CCDEmployer || "",
          section80D: data?.section80D || "", //no use
          section80DD: data?.section80DD || "", //no use
          section80DDB: data?.section80DDB || "",
          section80DDBSeniorCitizens: data?.section80DDBSeniorCitizens || false,
          section80E: data?.section80E || "",
          section80EE: data?.section80EE || "",
          section80EEA: data?.section80EEA || "",
          section80EEB: data?.section80EEB || "",
          section80G: data?.section80G || "", // no use
          section80GG: data?.section80GG || "",
          section80GGA: data?.section80GGA || "", // no use
          section80GGC: data?.section80GGC || "",
          section80JJA: data?.section80JJA || "",
          section80QQB: data?.section80QQB || "",
          section80RRB: data?.section80RRB || "",
          section80TTA: data?.section80TTA || "",
          section80TTB: data?.section80TTB || "",
          section80U: data?.section80U || "", //no use
          section80ddDisabilityNormal:
            data?.section80ddDisabilityNormal || false,
          section80ddDisabilitySevere:
            data?.section80ddDisabilitySevere || false,
          section80uDisabilityNormal: data?.section80uDisabilityNormal || false,
          section80uDisabilitySevere: data?.section80uDisabilitySevere || false,
          tdsOnSalary: data?.tdsOnSalary || "",
          totalChapVIADeductions: data?.totalChapVIADeductions || "", //no use
          typeOfDependent80DD: data?.typeOfDependent80DD?.id || "",
          panOfDependent80DD: data?.panOfDependent80DD || "",
        });

        if (e.status == 200) {
          setDeductionData(e.data);
          setDonation80GData(e.data?.deductions?.donations?.donation80G);
          setDonation80GGAData(e.data?.deductions?.donations?.donation80GGA);
          setNonSalaryData(e.data?.deductions?.tdsNonSalary);
          setTdsImmovablePropertyData(e.data?.deductions?.tdsImmovableProperty);
          setCollectedSourceData(e.data?.deductions?.tcs);
          setChallanData(e.data?.deductions?.taxPaidChallan);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    getDeductionsData();
  }, []);

  const parentAge = [
    {
      key: true,
      name: strings.tax_saving.below_60,
    },
    {
      key: false,
      name: strings.tax_saving.above_60,
    },
  ];

  return (
    <div>
      <LoadingBox open={loading} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmitDeductionsForm(values);
        }}
        handleFormUpdate
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <div>
            {/* ---------------------------------------------------------------------- Popular Deduction -------------------- */}
            {ItrData.form === "ITR_5" ? (
              ""
            ) : (
              <AccordianBox
                title={strings.tax.popular_deduction}
                description={strings.tax.health_insurance_nps_interest_earned}
              >
                <div className="flex gap-10 justify-between">
                  <h5>{strings.tax_saving.section_80c_investment}</h5>
                  <ToolTip />
                </div>
                <h4 className="max_limit">
                  {strings.label.max_limit}: 1,50,000
                </h4>
                <div className="flex_input">
                  <label>
                    {strings.tax_saving.add_up_all_your_80c_deductions_specify}
                  </label>
                  <Input
                    name={"section80C"}
                    inputType={"currency"}
                    value={values.section80C}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input mt-8">
                  <h5>{strings.tax_saving.section_80c_medical_insurance}</h5>
                </div>
                <h4 className="max_limit">
                  {strings.label.max_limit}: 1,50,000
                </h4>
                <div className="flex_input">
                  <label>
                    {strings.tax_saving.decuction_for_medical_insurance}
                  </label>

                  <Select
                    value={values.haveMedicalInsuranceParentsSeniorCitizens}
                    name={`haveMedicalInsuranceParentsSeniorCitizens`}
                    onChange={handleChange}
                  >
                    {parentAge?.map((i, ix) => (
                      <option value={i.key} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <StyleFormBox>
                  <div className="flex_input justify-between">
                    <h5>{strings.tax_saving.do_you_have_medical_insurance}</h5>
                    <ToggelButton
                      value={values.haveMedicalInsuranceSelfAndFamily}
                      handleClick={() =>
                        setFieldValue(
                          "haveMedicalInsuranceSelfAndFamily",
                          !values.haveMedicalInsuranceSelfAndFamily
                        )
                      }
                    />
                  </div>
                  <p className="mt-2">
                    {strings.tax_saving.medical_insurance_premium}
                  </p>
                  {values.haveMedicalInsuranceSelfAndFamily && (
                    <>
                      <div className="flex_input ">
                        <label>{strings.tax_saving.self_and_family}</label>
                        <Input
                          inputType={"currency"}
                          name={"medicalInsuranceSelfAndFamily"}
                          value={values.medicalInsuranceSelfAndFamily}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="flex_input mt-2">
                        <label>{strings.tax_saving.parents}</label>
                        <Input
                          name={"medicalInsuranceParents"}
                          inputType={"currency"}
                          value={values.medicalInsuranceParents}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}
                </StyleFormBox>

                <StyleFormBox>
                  <div className="flex_input justify-between mb-3">
                    <h5>
                      {
                        strings.tax_saving
                          .prevents_health_checkup_covered_insurance
                      }
                    </h5>
                    <ToggelButton
                      value={values.havePreventiveMedicalInsuranceSelfAndFamily}
                      handleClick={() =>
                        setFieldValue(
                          "havePreventiveMedicalInsuranceSelfAndFamily",
                          !values.havePreventiveMedicalInsuranceSelfAndFamily
                        )
                      }
                    />
                  </div>
                  {values.havePreventiveMedicalInsuranceSelfAndFamily && (
                    <>
                      <div className="flex_input ">
                        <label>{strings.tax_saving.self_and_family}</label>
                        <Input
                          inputType={"currency"}
                          name={"preventiveHealthCheckupFeeSelfAndFamily"}
                          value={values.preventiveHealthCheckupFeeSelfAndFamily}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="flex_input mt-2">
                        <label>{strings.tax_saving.parents}</label>
                        <Input
                          name={"preventiveMedicalInsuranceParents"}
                          inputType={"currency"}
                          value={values.preventiveMedicalInsuranceParents}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}
                  <InformationNote
                    note={
                      strings.tax_saving
                        .expenditure_can_claimed_only_for_senior_citizens
                    }
                  />
                </StyleFormBox>

                <StyleFormBox>
                  <div className="flex gap-10 justify-between mt-4">
                    <h5>
                      {strings.tax_saving.section_80tta_deduction_for_interest}
                    </h5>
                    <ToolTip />
                  </div>
                  <h4 className="max_limit">
                    {strings.label.max_limit}: 1,50,000
                  </h4>
                  <div className="flex_input">
                    <label>
                      {
                        strings.tax_saving
                          .interest_earned_on_saving_bank_account
                      }
                    </label>
                    <Input
                      name={"section80TTA"}
                      inputType={"currency"}
                      value={values.section80TTA}
                      onChange={handleChange}
                    />
                  </div>
                  <hr />
                  <div className="flex gap-10 justify-between mt-4">
                    <h5>
                      {
                        strings.tax_saving
                          .section_80ccc_contributio_pension_plan
                      }
                    </h5>
                    <ToolTip />
                  </div>
                  <h4 className="max_limit">
                    {strings.label.max_limit}: 1,50,000
                  </h4>
                  <div className="flex_input">
                    <label>
                      {strings.tax_saving.contribution_amount_pension_plan}
                    </label>
                    <Input
                      name={"section80CCC"}
                      inputType={"currency"}
                      value={values.section80CCC}
                      onChange={handleChange}
                    />
                  </div>
                  <hr />
                  <div className="flex gap-10 justify-between mt-4">
                    <h5>
                      {
                        strings.tax_saving
                          .section_80ccd_employee_contribution_to_pension_scheme
                      }
                    </h5>
                    <ToolTip />
                  </div>
                  <h4 className="max_limit">
                    {strings.label.max_limit}: 1,50,000
                  </h4>
                  <div className="flex_input">
                    <label>
                      {strings.tax_saving.please_enter_your_own_contribution}
                    </label>
                    <Input
                      name={"section80CCDEmployeeOrSE"}
                      inputType={"currency"}
                      value={values.section80CCDEmployeeOrSE}
                      onChange={handleChange}
                    />
                  </div>

                  <hr />
                  <div className="flex gap-10 justify-between mt-4">
                    <h5>
                      {strings.tax_saving.section_80ccd_employer_contribution}
                    </h5>
                    <ToolTip />
                  </div>
                  <h4 className="max_limit">
                    {strings.label.max_limit}: 1,50,000
                  </h4>
                  <div className="flex_input">
                    <label>
                      {strings.tax_saving.enter_employer_contribution_to_nps}
                    </label>
                    <Input
                      name={"section80CCDEmployer"}
                      inputType={"currency"}
                      value={values.section80CCDEmployer}
                      onChange={handleChange}
                    />
                  </div>
                </StyleFormBox>
              </AccordianBox>
            )}
            {/* ---------------------------------------------------------------------- More Deductions ------------------------- */}
            <AccordianBox
              title={strings.tax.more_deductions}
              description={strings.tax.donations_to_charitable_organizations}
            >
              <StyleFormBox>
                <div className="flex_input">
                  <div>
                    <h4>
                      {
                        strings.tax_saving
                          .section_80g_donations_charitable_organizations
                      }
                    </h4>
                    <p>
                      {
                        strings.tax_saving
                          .did_you_donate_any_amount_to_charitable_organization_in_last_financial_year
                      }
                    </p>
                  </div>
                  <button
                    className="add_button"
                    style={{ flex: 0 }}
                    onClick={() => {
                      setEditDonationFormData({
                        ...editDonationFormData,
                        donation80G: [],
                      });
                      setDonationBox(!donationBox);
                    }}
                  >
                    {donationBox ? strings.button.cancel : strings.button.add}
                  </button>
                </div>
                {donationBox && (
                  <Donation80G
                    editData={editDonationFormData.donation80G}
                    organizations80g={deductionData.defaultDonation80G}
                    handleCallback={(e) => {
                      if (e.donation) {
                        setDonation80GData([...donation80GData, e.donation]);
                        setDonationBox(false);
                      }
                    }}
                  />
                )}
                {donation80GData?.map((item, ix) => (
                  <div
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                    key={ix}
                  >
                    <h4 className="mr-auto">{item.doneeWithPanName}</h4>

                    <h4>{item.eligibleDonationAmt}</h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditDonationFormData({
                            ...editDonationFormData,
                            donation80G: item,
                          });
                          setDonationBox(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDeleteRecord(item.id, item.deductionId, "80g")
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                ))}
              </StyleFormBox>

              {ItrData.form === "ITR_5" ? (
                ""
              ) : (
                <>
                  {" "}
                  <StyleFormBox>
                    <h5 className="mt-4">
                      {
                        strings.tax_saving
                          .section_80e_education_loan_on_higher_studies
                      }
                    </h5>
                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .interest_on_higher_education_loan_paid_this_year
                        }
                      </label>
                      <Input
                        inputType={"currency"}
                        value={values.section80E}
                        name={"section80E"}
                        onChange={handleChange}
                      />
                    </div>
                  </StyleFormBox>
                  <StyleFormBox>
                    <div className="flex_input justify-between">
                      <h5>
                        {
                          strings.tax_saving
                            .section_80gg_deduction_for_house_rent
                        }
                      </h5>
                      <ToolTip />
                    </div>
                    <div className="flex_input mt-2">
                      <label>
                        {strings.tax_saving.enter_rent_paid_per_month}
                      </label>
                      <Input
                        inputType={"currency"}
                        value={values.section80GG}
                        name={"section80GG"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex_input mt-2">
                      <label>{strings.tax_saving.number_of_months}</label>
                      <Select
                        value={values.noOfMonthsForWhichRentPaid}
                        name={"noOfMonthsForWhichRentPaid"}
                        onChange={handleChange}
                      >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map(
                          (i, ix) => (
                            <option value={i} key={ix}>
                              {i}
                            </option>
                          )
                        )}
                      </Select>
                    </div>
                  </StyleFormBox>
                </>
              )}

              <StyleFormBox>
                <div className="flex_input">
                  <div>
                    <h5>
                      {
                        strings.tax_saving
                          .section_80gga_donations_for_scientific_research_or_rural_development
                      }
                    </h5>
                    <p>
                      {
                        strings.tax_saving
                          .govt_requires_itemized_details_donations_for_section_80gga
                      }
                    </p>
                  </div>
                  <button
                    className="add_button"
                    style={{ flex: 0 }}
                    onClick={() => setDonation80GGA(true)}
                  >
                    {donation80GGA ? strings.button.cancel : strings.button.add}{" "}
                  </button>
                </div>

                {donation80GGA && (
                  <Donation80GGA
                    editData={editDonationFormData.donation80GGA}
                    organizations80g={deductionData.defaultDonation80GGA}
                    handleCallback={(e) => {
                      if (e.donation) {
                        setDonation80GGAData([
                          ...donation80GGAData,
                          e.donation,
                        ]);
                        setDonation80GGA(false);
                      }
                    }}
                  />
                )}
                {donation80GGAData?.map((item, ix) => (
                  <div
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                    key={ix}
                  >
                    <h4 className="mr-auto">{item.doneeWithPanName}</h4>

                    <h4>{item.eligibleDonationAmt}</h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditDonationFormData({
                            ...editDonationFormData,
                            donation80GGA: item,
                          });
                          setDonation80GGA(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDeleteRecord(item.id, item.deductionId, "80gga")
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                ))}
              </StyleFormBox>
            </AccordianBox>

            {/* ---------------------------------------------------------------------- Any other Deductions ------------------------- */}
            <AccordianBox
              title={strings.tax.any_other_deductions}
              description={
                strings.tax.interest_on_home_loan_electric_vehicle_loan
              }
            >
              {/* --------- Section 80DD - Disable dependent deduction -------  */}
              {ItrData.form === "ITR_5" ? (
                ""
              ) : (
                <StyleFormBox>
                  <h4>
                    {strings.tax_saving.section_80dd_disable_dependent_deducton}
                  </h4>
                  <div className="flex_input mt-4">
                    <label>{strings.tax_saving.type_of_dependent}</label>
                    <Select
                      value={values.typeOfDependent80DD}
                      name={"typeOfDependent80DD"}
                      onChange={handleChange}
                    >
                      {" "}
                      <option value="" disabled>
                        {strings.label.choose_option}
                      </option>
                      {deductionData?.typeOfdependent?.map((i, ix) => (
                        <option value={i.id} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="flex_input mt-4">
                    <label>{strings.tax_saving.pan_of_dependent}</label>
                    <Input
                      name={"panOfDependent80DD"}
                      value={values.panOfDependent80DD}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex gap-3">
                    <CheckBox
                      value={values.section80ddDisabilityNormal}
                      label={strings.tax_saving.per_40_disability}
                      handleChange={(e) =>
                        setValues({
                          ...values,
                          section80ddDisabilityNormal: e,
                          section80ddDisabilitySevere: false,
                        })
                      }
                    />
                    <CheckBox
                      value={values.section80ddDisabilitySevere}
                      label={strings.tax_saving.per_80_disability}
                      handleChange={(e) =>
                        setValues({
                          ...values,
                          section80ddDisabilitySevere: e,
                          section80ddDisabilityNormal: false,
                        })
                      }
                    />
                  </div>

                  <hr className="mb-4" />
                  <h4>{strings.tax_saving.section_80u_self_disability}</h4>
                  <p>
                    {
                      strings.tax_saving
                        .offers_tax_benefits_if_individual_suffers_disability
                    }
                  </p>
                  <div className="flex gap-3">
                    <CheckBox
                      value={values.section80uDisabilityNormal}
                      label={strings.tax_saving.per_40_disability}
                      handleChange={(e) =>
                        setValues({
                          ...values,
                          section80uDisabilityNormal: e,
                          section80uDisabilitySevere: false,
                        })
                      }
                    />
                    <CheckBox
                      value={values.section80uDisabilitySevere}
                      label={strings.tax_saving.per_80_disability}
                      handleChange={(e) =>
                        setValues({
                          ...values,
                          section80uDisabilitySevere: e,
                          section80uDisabilityNormal: false,
                        })
                      }
                    />
                  </div>
                </StyleFormBox>
              )}

              <StyleFormBox>
                {ItrData.form === "ITR_5" ? (
                  ""
                ) : (
                  <>
                    {/* ------Section 80EE ----- */}
                    <div className="flex gap-10 justify-between">
                      <h5>
                        {strings.tax_saving.section_80ee_interest_on_home_loan}
                      </h5>
                      <ToolTip />
                    </div>
                    <h4 className="max_limit">
                      {strings.label.max_limit}: 50,000
                    </h4>
                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .interest_on_first_house_loan_2016_2017
                        }
                      </label>
                      <Input
                        inputType={"currency"}
                        name={"section80EE"}
                        value={values.section80EE}
                        onChange={handleChange}
                      />
                    </div>
                    <hr />
                    {/* -----Section 80EEA ------ */}
                    <div className="flex gap-10 justify-between mt-3">
                      <h5>
                        {strings.tax_saving.section_80eea_interest_on_home_loan}
                      </h5>
                      <ToolTip />
                    </div>
                    <h4 className="max_limit">
                      {strings.label.max_limit}: 1,50,000
                    </h4>
                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .interest_on_first_house_loan_2019_2022
                        }
                      </label>
                      <Input
                        inputType={"currency"}
                        name={"section80EEA"}
                        value={values.section80EEA}
                        onChange={handleChange}
                      />
                    </div>

                    <hr />
                    {/* -----Section 80EEB  -------- */}
                    <div className="flex gap-10 justify-between mt-3">
                      <h5>
                        {strings.tax_saving.section_80eeb_electric_vehicle_loan}
                      </h5>
                      <ToolTip />
                    </div>

                    <p>{strings.tax_saving.criteria_of_section_80eeb}</p>
                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .interest_on_first_house_loan_2019_2022
                        }
                      </label>
                      <Input
                        inputType={"currency"}
                        name={"section80EEB"}
                        value={values.section80EEB}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}

                {ItrData.form === "ITR_1" ? (
                  <>
                    <hr />
                    <div className="flex gap-10 justify-between my-3">
                      <h5>
                        {strings.tax_saving.section_80ttb_intrest_on_deposites}
                      </h5>
                      <ToolTip />
                    </div>
                    <div className="flex_input">
                      <label>
                        {strings.tax_saving.interest_earned_deposites}
                      </label>
                      <Input
                        inputType={"currency"}
                        name={"section80TTB"}
                        value={values.section80TTB}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                ) : ItrData.form === "ITR_2" ? (
                  <>
                    <hr />
                    <div className="flex gap-10 justify-between my-3">
                      <h5>
                        {
                          strings.tax_saving
                            .section_80qqb_royalty_received_on_books
                        }
                      </h5>
                      <ToolTip />
                    </div>
                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .enter_the_royaly_fee_received_by_authors
                        }
                      </label>
                      <Input
                        name={"section80QQB"}
                        inputType={"currency"}
                        value={values.section80QQB}
                        onChange={handleChange}
                      />
                    </div>

                    <hr />
                    <div className="flex gap-10 justify-between my-3">
                      <h5>
                        {strings.tax_saving.section_80rrb_income_on_parents}
                      </h5>
                      <ToolTip />
                    </div>
                    <div className="flex_input">
                      <label>
                        {strings.tax_saving.deductions_amount_for_patentee}
                      </label>
                      <Input
                        name={"section80RRB"}
                        inputType={"currency"}
                        value={values.section80RRB}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}

                {/* +++++++++++++++++++++++++++++++++++  Section 80GGC - Contribution to +++++++++++++++++++++++++++++++++++ */}

                {ItrData.form === "ITR_1" || ItrData.form === "ITR_4" ? (
                  <>
                    <hr />
                    <div className="flex_input my-5">
                      <div>
                        <h4>
                          {
                            strings.tax_saving
                              .section_80ggc_contribution_to_political_party
                          }
                        </h4>
                        <p>
                          {
                            strings.tax_saving
                              .enter_the_cotribution_made_to_political_party
                          }
                        </p>
                      </div>
                      <button
                        className="add_button"
                        style={{ flex: 0 }}
                        onClick={() => {
                          setEditDonationFormData({
                            ...editDonationFormData,
                            donation80GGC: [],
                          });
                          setDonation80GGC(!donation80GGC);
                        }}
                      >
                        {donation80GGC
                          ? strings.button.cancel
                          : strings.button.add}
                      </button>
                    </div>
                    {donation80GGC && (
                      <Donation80GGC
                        editData={editDonationFormData.donation80GGA}
                        organizations80g={deductionData.defaultDonation80GGA}
                        handleCallback={(e) => {
                          if (e.donation) {
                            setDonation80GGCData([
                              ...donation80GGCData,
                              e.donation,
                            ]);
                            setDonation80GGC(false);
                          }
                        }}
                      />
                    )}

                    {donation80GGCData?.map((item, ix) => (
                      <div
                        className="flex gap-2 p-3 my-3 align-center"
                        style={{
                          background: addOpacity(theme.primary_bg, 0.2),
                          borderRadius: "10px",
                        }}
                        key={ix}
                      >
                        <h4 className="mr-auto">{item.doneeWithPanName}</h4>

                        <h4>{item.eligibleDonationAmt}</h4>
                        <div className="action_buttons">
                          <button
                            className="edit_btn"
                            onClick={() => {
                              setEditDonationFormData({
                                ...editDonationFormData,
                                donation80GGA: item,
                              });
                              setDonation80GGA(true);
                            }}
                          >
                            <FaRegEdit />
                          </button>
                          <button
                            className="delete_btn"
                            onClick={() =>
                              handleDeleteRecord(
                                item.id,
                                item.deductionId,
                                "80gga"
                              )
                            }
                          >
                            <RiDeleteBin6Fill />
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                ) : ItrData.form === "ITR_2" || ItrData.form === "ITR_3" ? (
                  <>
                    <hr />
                    <h4>
                      {
                        strings.tax_saving
                          .section_80ggc_contribution_to_political_party
                      }
                    </h4>
                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .enter_the_cotribution_made_to_political_party
                        }
                      </label>
                      <Input
                        name={"donation80GGC"}
                        inputType={"currency"}
                        value={values.donation80GGC}
                        onChange={handleChange}
                      />
                    </div>

                    <hr />
                    <h4>{strings.tax_saving.section_80jja_bio_degradable}</h4>
                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .enter_the_cotribution_made_to_political_party
                        }
                      </label>
                      <Input
                        name={"section80JJA"}
                        inputType={"currency"}
                        value={values.section80JJA}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                ) : ItrData.form === "ITR_5" ? (
                  <>
                    <div className="flex_input my-5">
                      <div>
                        <h4>
                          {
                            strings.tax_saving
                              .section_80ggc_contribution_to_political_party
                          }
                        </h4>
                        <p>
                          {
                            strings.tax_saving
                              .enter_the_cotribution_made_to_political_party
                          }
                        </p>
                      </div>
                      <button
                        className="add_button"
                        style={{ flex: 0 }}
                        onClick={() => {
                          setEditDonationFormData({
                            ...editDonationFormData,
                            donation80GGC: [],
                          });
                          setDonation80GGC(!donation80GGC);
                        }}
                      >
                        {donation80GGC
                          ? strings.button.cancel
                          : strings.button.add}
                      </button>
                    </div>
                    {donation80GGC && (
                      <Donation80GGC
                        editData={editDonationFormData.donation80GGA}
                        organizations80g={deductionData.defaultDonation80GGA}
                        handleCallback={(e) => {
                          if (e.donation) {
                            setDonation80GGCData([
                              ...donation80GGCData,
                              e.donation,
                            ]);
                            setDonation80GGC(false);
                          }
                        }}
                      />
                    )}

                    {donation80GGCData?.map((item, ix) => (
                      <div
                        className="flex gap-2 p-3 my-3 align-center"
                        style={{
                          background: addOpacity(theme.primary_bg, 0.2),
                          borderRadius: "10px",
                        }}
                        key={ix}
                      >
                        <h4 className="mr-auto">{item.doneeWithPanName}</h4>

                        <h4>{item.eligibleDonationAmt}</h4>
                        <div className="action_buttons">
                          <button
                            className="edit_btn"
                            onClick={() => {
                              setEditDonationFormData({
                                ...editDonationFormData,
                                donation80GGA: item,
                              });
                              setDonation80GGA(true);
                            }}
                          >
                            <FaRegEdit />
                          </button>
                          <button
                            className="delete_btn"
                            onClick={() =>
                              handleDeleteRecord(
                                item.id,
                                item.deductionId,
                                "80gga"
                              )
                            }
                          >
                            <RiDeleteBin6Fill />
                          </button>
                        </div>
                      </div>
                    ))}

                    <hr className="my-4" />
                    <h4>{strings.tax_saving.section_80jja_bio_degradable}</h4>

                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .enter_the_cotribution_made_to_political_party
                        }
                      </label>
                      <Input
                        name={"section80JJA"}
                        inputType={"currency"}
                        value={values.section80JJA}
                        onChange={handleChange}
                      />
                    </div>

                    {/* ----- Section 80IAB ------ */}

                    <hr className="my-4" />
                    <h4>Section 80IAB</h4>

                    <div className="flex_input">
                      <label>
                        Profits and gains by an undertaking or enterprise
                        engaged in development of Special Economic Zone
                      </label>
                      <Input
                        name={"section80JJA"}
                        inputType={"currency"}
                        value={values.section80JJA}
                        onChange={handleChange}
                      />
                    </div>

                    {/* -----  Section 80IAC ------- */}

                    <hr className="my-4" />
                    <h4>Section 80IAC</h4>

                    <div className="flex_input">
                      <label>
                        Special provision in respect of specified business
                      </label>
                      <Input
                        name={"section80JJA"}
                        inputType={"currency"}
                        value={values.section80JJA}
                        onChange={handleChange}
                      />
                    </div>

                    {/* ----- Section 80IBA ----- */}

                    <hr className="my-4" />
                    <h4>Section 80IBA</h4>

                    <div className="flex_input">
                      <label>Profits and gains from housing projects</label>
                      <Input
                        name={"section80JJA"}
                        inputType={"currency"}
                        value={values.section80JJA}
                        onChange={handleChange}
                      />
                    </div>

                    {/* ----- Section 80JJAA ----- */}

                    <hr className="my-4" />
                    <h4>Section 80JJAA</h4>

                    <div className="flex_input">
                      <label>Employment of new employees</label>
                      <Input
                        name={"section80JJA"}
                        inputType={"currency"}
                        value={values.section80JJA}
                        onChange={handleChange}
                      />
                    </div>

                    <hr className="my-4" />
                    {/* ----------- Deduction under section IOAA -------- */}
                    <div className="flex_input my-5">
                      <div>
                        <h4>Deduction under section IOAA</h4>
                      </div>

                      {!deductionIOAA && (
                        <button
                          className="add_button"
                          style={{ flex: 0 }}
                          onClick={() => {
                            setDeductionIOAA(true);
                          }}
                        >
                          {deductionData.deductions?.donationIOAA
                            ? strings.button.edit
                            : strings.button.add}
                        </button>
                      )}
                    </div>

                    {deductionIOAA && <DeductionIOAA />}

                    <hr className="my-4" />
                    {/* ----------- Research associations (RA) -------- */}
                    <div className="flex_input my-5">
                      <div>
                        <h4>Research associations (RA)</h4>
                      </div>
                      {!donationsToRA && (
                        <button
                          className="add_button"
                          style={{ flex: 0 }}
                          onClick={() => {
                            setDonationsToRA(true);
                          }}
                        >
                          {deductionData.deductions?.donationResearchAssociation
                            ? strings.button.edit
                            : strings.button.add}
                        </button>
                      )}
                    </div>

                    {donationsToRA && <DonationsToRA />}

                    <hr className="my-4" />

                    {/* ----------- Deductions under section 8O -------- */}
                    <div className="flex_input my-5">
                      <div>
                        <h4>Deductions under section 8O</h4>
                      </div>
                      {!deduction80IA && (
                        <button
                          className="add_button"
                          style={{ flex: 0 }}
                          onClick={() => {
                            setDeduction80IA(true);
                          }}
                        >
                          {deductionData.deductions?.deduction80
                            ? strings.button.edit
                            : strings.button.add}
                        </button>
                      )}
                    </div>

                    {deduction80IA && <Deduction80IA />}

                    <hr className="my-4" />

                    {/* ----------- Deduction under section 80LA -------- */}
                    <div className="flex_input my-5">
                      <div>
                        <h4>Deduction under section 80LA</h4>
                      </div>
                      {!schedule80LA && (
                        <button
                          className="add_button"
                          style={{ flex: 0 }}
                          onClick={() => {
                            setSchedule80LA(true);
                          }}
                        >
                          {deductionData.deductions?.deduction80LA
                            ? strings.button.edit
                            : strings.button.add}
                        </button>
                      )}
                    </div>

                    {schedule80LA && (
                      <Schedule80LA
                        LASubSection={deductionData?.LASubSection}
                        LATypeOfEntiry={deductionData?.LATypeOfEntiry}
                        LAIncomeOfUnit={deductionData?.LAIncomeOfUnit}
                        LAAuthorityGrantingRegistration={
                          deductionData?.LAAuthorityGrantingRegistration
                        }
                      />
                    )}

                    <hr className="my-4" />
                    {/* ----------- Deduction under section 80P -------- */}
                    <div className="flex_input my-5">
                      <div>
                        <h4>Deduction under section 80P</h4>
                      </div>
                      {!section80P && (
                        <button
                          className="add_button"
                          style={{ flex: 0 }}
                          onClick={() => {
                            setSection80P(true);
                          }}
                        >
                          {deductionData.deductions?.deduction80P
                            ? strings.button.edit
                            : strings.button.add}
                        </button>
                      )}
                    </div>

                    {section80P && <Section80P />}
                  </>
                ) : (
                  ""
                )}
                {/* --------------------------------------    Section 80GGC - Contribution to ------------------------------------ */}

                <hr />

                {/* ----- Section 80DDB: Deductions for treatment of ---------- */}

                {ItrData.form === "ITR_5" ? (
                  ""
                ) : (
                  <>
                    <div className="flex gap-10 justify-between my-3">
                      <h5>
                        {
                          strings.tax_saving
                            .section_80ddb_deductons_for_treatment_specified_diseases
                        }
                      </h5>
                      <ToolTip />
                    </div>
                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .age_of_person_who_deduction_being_claimed
                        }
                      </label>
                      <Select
                        value={values.section80DDBSeniorCitizens}
                        name={`section80DDBSeniorCitizens`}
                        onChange={handleChange}
                      >
                        {parentAge?.map((i, ix) => (
                          <option value={i.key} key={ix}>
                            {i.name}
                          </option>
                        ))}
                      </Select>
                    </div>

                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .medical_treatment_costs_for_specified_deseases
                        }
                      </label>
                      <Input
                        inputType={"currency"}
                        value={values.section80DDB}
                        name={"section80DDB"}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}

                {ItrData.form === "ITR_2" ? (
                  <>
                    <div className="flex gap-10 justify-between my-3">
                      <h5>{strings.tax_saving.section_80cch}</h5>
                      <ToolTip />
                    </div>
                    <div className="flex_input">
                      <label>
                        {strings.tax_saving.contribution_to_agnipath_scheme}
                      </label>
                      <Input
                        inputType={"currency"}
                        value={values.anyOthSec80CCH}
                        name={"anyOthSec80CCH"}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </StyleFormBox>
            </AccordianBox>

            {/* ---------------------------------------------------- Carry Forward of losses ----------------------  */}
            {ItrData.form === "ITR_3" || ItrData.form === "ITR_2" ? (
              <CarryForwardLoss />
            ) : (
              ""
            )}

            {/* ----------------------------------------------------------------------- Tax Paid  ------------------------------- */}
            <AccordianBox
              title={strings.tax.taxes_paid_tds_tcss}
              description={
                strings.tax
                  .taxes_deducted_collected_source_advance_tax_or_sel_assessment
              }
            >
              <StyleFormBox>
                {ItrData.form === "ITR_5" ? (
                  ""
                ) : (
                  <>
                    <div className="flex gap-10 justify-between mt-3">
                      <h5>{strings.tax_saving.tds_on_salary}</h5>
                      <ToolTip />
                    </div>
                    <div className="flex_input">
                      <label>
                        {
                          strings.tax_saving
                            .salary_tds_entries_if_anything_missing
                        }
                      </label>
                      <div className="flex_input items-center">
                        <Input value={values.tdsOnSalary} />
                        <button className="edit_btn">
                          <FaRegEdit />
                        </button>
                      </div>
                    </div>
                    <hr />
                  </>
                )}

                <div className="flex gap-10 justify-between mt-3">
                  <h5>{strings.tax_saving.non_salary_tds}</h5>
                  <ToolTip />
                </div>
                <p>
                  {
                    strings.tax_saving
                      .add_entries_from_tds_on_income_professional_income
                  }
                </p>

                {nonSalaryData?.map((item, ix) => (
                  <div
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                    key={ix}
                  >
                    <h4 className="mr-auto">{item.nameOfDeductor}</h4>

                    <h4>{item.taxDeducted}</h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditDonationFormData({
                            ...editDonationFormData,
                            tdsNonSalary: item,
                          });
                          setNonSalaryBox(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDeleteRecord(item.id, ItrData.id, "tdsOnSalary")
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                ))}

                {nonSalaryBox && (
                  <TdsNonSalary
                    editData={editDonationFormData.tdsNonSalary}
                    TdsRelatedTo={deductionData.tdsRelatedTo}
                    FinancialYear={deductionData.financialYear}
                    TypeOfIncome={deductionData?.typeOfIncome}
                    handleClose={() => {
                      setNonSalaryBox(false);
                      setEditDonationFormData({
                        ...editDonationFormData,
                        tdsNonSalary: [],
                      });
                    }}
                    handleCallback={(type, data) => {
                      if (type == "add") {
                        setNonSalaryData([...nonSalaryData, data.tdsNonSalary]);
                      } else {
                        setNonSalaryData((pre) =>
                          pre.map((item) =>
                            item.id === data.tdsNonSalary.id
                              ? { ...data.tdsNonSalary }
                              : { ...item }
                          )
                        );
                      }
                      setEditDonationFormData({
                        ...editDonationFormData,
                        tdsNonSalary: [],
                      });
                      setNonSalaryBox(false);
                    }}
                  />
                )}

                <div className="flex_input mt-4">
                  <button className="secandary_button">
                    {strings.button.upload_from_26as}
                  </button>
                  {!nonSalaryBox && (
                    <button
                      className="secandary_button"
                      onClick={() => setNonSalaryBox(true)}
                    >
                      {strings.button.add_manually}
                    </button>
                  )}
                </div>

                <hr />

                <div className="flex_input">
                  <div>
                    <h5 className="my-3">
                      {strings.tax_saving.tds_on_sale_of_immovable_property}
                    </h5>
                    <p>
                      {
                        strings.tax_saving
                          .if_sell_land_the_buyer_have_deducted_tds_at_rate_1
                      }
                    </p>
                  </div>
                  <button
                    className="add_button"
                    style={{ flex: 0 }}
                    onClick={() => setSaleRentTdsBox(!saleRentTdsBox)}
                  >
                    {saleRentTdsBox ? strings.button.close : strings.button.add}{" "}
                  </button>
                </div>

                {saleRentTdsBox && (
                  <TdsImmovableProperty
                    FinancialYear={deductionData?.financialYear}
                    IncomeAgainstTdsDeducted={
                      deductionData?.incomeAgainstTdsDeducted
                    }
                    TdsRelatedTo={deductionData.tdsRelatedTo}
                    handleClose={() => setSaleRentTdsBox(false)}
                    editData={editDonationFormData.tdsImmovableProperty}
                    handleCallback={(e) => {
                      console.log("Received donation:", e);
                      if (e) {
                        setTdsImmovablePropertyData([
                          ...tdsImmovablePropertyData,
                          e,
                        ]);
                        setSaleRentTdsBox(false);
                      }
                    }}
                  />
                )}

                {tdsImmovablePropertyData?.map((item, ix) => (
                  <div
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                    key={ix}
                  >
                    <h4 className="mr-auto">{item.buyerName}</h4>

                    <h4>{item.incomeAgainstTdsDeducted}</h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditDonationFormData({
                            ...editDonationFormData,
                            tdsImmovableProperty: item,
                          });
                          setSaleRentTdsBox(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDeleteRecord(
                            item.id,
                            item.deductionId,
                            "ImmovableProperty"
                          )
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                ))}

                {/*  ++++++++++++++++++++++++++++++++++++++  taxes_collected_at_source ++++++++++++++++++++++++++++++++++++++ */}
                <hr />
                <div className="flex_input">
                  <div>
                    <h5 className="my-3">
                      {strings.tax_saving.taxes_collected_at_source}
                    </h5>
                    <p>{strings.tax_saving.add_all_tcs_entries_for}</p>
                  </div>
                  <button
                    className="add_button"
                    style={{ flex: 0 }}
                    onClick={() => {
                      setCollectedSourceBox(!collectedSourceBox);
                      collectedSourceBox &&
                        setEditDonationFormData({
                          ...editDonationFormData,
                          collectedSource: [],
                        });
                    }}
                  >
                    {collectedSourceBox
                      ? strings.button.close
                      : strings.button.add}{" "}
                  </button>
                </div>

                {collectedSourceData?.map((item, ix) => (
                  <div
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                    key={ix}
                  >
                    <h4 className="mr-auto">{item.nameOfCollector}</h4>

                    <h4>{item.taxDeductedClaimedForTheYear}</h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditDonationFormData({
                            ...editDonationFormData,
                            collectedSource: item,
                          });
                          setCollectedSourceBox(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDeleteRecord(item.id, item.deductionId, "tcs")
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                ))}

                {collectedSourceBox && (
                  <TaxCollectedSource
                    TdsRelatedTo={deductionData.tdsRelatedTo}
                    FinancialYear={deductionData?.financialYear}
                    handleClose={() => setCollectedSourceBox(false)}
                    editData={editDonationFormData.collectedSource}
                    handleCallback={(type, data) => {
                      if (type == "add") {
                        setCollectedSourceData([...collectedSourceData, data]);
                      } else {
                        setCollectedSourceData((pre) =>
                          pre.map((item) =>
                            item.id === data.collectedSource.id
                              ? { ...data.collectedSource }
                              : { ...item }
                          )
                        );
                      }
                      setEditDonationFormData({
                        ...editDonationFormData,
                        collectedSource: [],
                      });
                      setCollectedSourceBox(false);
                    }}
                  />
                )}

                {/* ---------------------------------- taxes_collected_at_source ----------------------- */}
                <hr />
                <h5 className="my-3">{strings.tax_saving.self_tax_payments}</h5>
                <p>
                  {
                    strings.tax_saving
                      .add_entries_here_if_voluntarily_deposited_taxes
                  }
                </p>

                {challanData?.map((item, ix) => (
                  <div
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                    key={ix}
                  >
                    <h4 className="mr-auto">{item.bsrCode}</h4>
                    <h4>{item.amount}</h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditDonationFormData({
                            ...editDonationFormData,
                            taxPaidChallan: item,
                          });
                          setChallanForm(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDeleteRecord(
                            item.id,
                            item.deductionId,
                            "taxChallan"
                          )
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                ))}

                {challanForm ? (
                  <TaxPaidChallan
                    handleClose={() => {
                      setEditDonationFormData({
                        ...editDonationFormData,
                        taxPaidChallan: [],
                      });
                      setChallanForm(false);
                    }}
                    editData={editDonationFormData.taxPaidChallan}
                    handleCallback={(type, data) => {
                      if (type == "add") {
                        setChallanData([...challanData, data.taxPaidChallan]);
                      } else {
                        setChallanData((pre) =>
                          pre.map((item) =>
                            item.id === data.taxPaidChallan.id
                              ? { ...data.taxPaidChallan }
                              : { ...item }
                          )
                        );
                      }
                      setEditDonationFormData({
                        ...editDonationFormData,
                        taxPaidChallan: [],
                      });
                      setChallanForm(false);
                    }}
                  />
                ) : (
                  <div className="flex gap-3">
                    <button
                      className="secandary_button mt-4"
                      // onClick={() => setChallanForm(true)}
                    >
                      {strings.tax_saving.upload_tax_paid_challan}
                    </button>
                    <button
                      className="primary_button mt-4"
                      onClick={() => setChallanForm(true)}
                    >
                      {strings.button.add_manually}
                    </button>
                  </div>
                )}
              </StyleFormBox>
            </AccordianBox>

            {/* ------------ Other Disclosuser  */}
            {ItrData.form === "ITR_5" ? "" : <OtherDisclosuser />}

            <button className="primary_button mt-5" onClick={handleSubmit}>
              {strings.button.save_and_continue}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
