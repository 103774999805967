import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import SelfOccupiedProperty from "./SelfOccupiedProperty";
import RentalProperty from "./RentalProperty";
import DeemedLetProperty from "./DeemedLetProperty";
import {
  deleteHousePropertyIncome,
  getHousePropertyIncome,
  setHousePropertyIncome,
  updateHousePropertyIncome,
} from "../../../../redux/itr/action";
import Select from "../../../../styledComponent/Inputs/Select";
import { addOpacity, removeEmptyKeys } from "../../../../utils/helpers";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import LoadingBox from "../../../DialogBox/LoadingBox";

export default function HousePropertyIncome({ data, handleCallback }) {
  const [incomeData, setIncomeData] = useState();
  const [houseIncomeForm, setHouseIncomeForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [activeOption, setActiveOption] = useState("");
  const [housePropertyData, setHousePropertyData] = useState({});

  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const handleIncomeSourceApi = async (data) => {
    let newdata = { ...data, itrId: ItrData.id };
    removeEmptyKeys(newdata);
    setLoading(true);
    if (editFormData.propertyType) {
      dispatch(
        updateHousePropertyIncome(removeEmptyKeys(data), (e) => {
          if (e.status == 200) {
            setHouseIncomeForm(false);
            toast.success("Successfully Update form");
            // handleCallback()
            // getHouseIncomeApi(e.data.housePropertyIncome.id)
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
      );
    } else {
      dispatch(
        setHousePropertyIncome(newdata, (e) => {
          if (e.status == 200) {
            setHouseIncomeForm(false);
            toast.success("Successfully submit form");
            setIncomeData(e.data.housePropertyIncome);
            // handleCallback()
            // getHouseIncomeApi(e.data.housePropertyIncome.id)
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
      );
    }
  };

  const handleDeleteIncomeRecord = () => {
    let parm = `?hpiId=${incomeData.id}&itrId=${ItrData.id}`;
    setLoading(true);
    dispatch(
      deleteHousePropertyIncome(parm, (e) => {
        if (e.status == 200) {
          setEditFormData({});
          setIncomeData();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    );
  };

  const getHouseIncomeApi = () => {
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getHousePropertyIncome(parm, (e) => {
        if (e.status == 200) {
          if (ItrData.form == "ITR_5") {
            const newData = {
              ...e.data,
              hpiType: e.data.hpiType.filter((i) => i.id !== 100),
            };
            setHousePropertyData(newData);
          } else {
            setHousePropertyData(e.data);
          }
        }
      })
    );
  };

  const editForm = () => {
    setEditFormData(incomeData);
    setActiveOption(incomeData.propertyType.id || incomeData.propertyType);
    setHouseIncomeForm(true);
  };

  useEffect(() => {
    if (data && data[0]) {
      setIncomeData(data[0]);
    }
    if(ItrData.form == "ITR_5"){
      setActiveOption(101);
    }else{
      setActiveOption(100);
    }
  }, [data]);
  useEffect(() => {
    getHouseIncomeApi();
  }, []);


  console.log('housePropertyData ==>',housePropertyData)
  return (
    <>
      <LoadingBox open={loading} />
      <AccordianBox
        title={strings.tax.house_property_income}
        description={strings.tax.add_details_earned_rent_from_property}
      >
        {incomeData && (
          <div
            className="flex gap-2 p-3 my-3 align-center"
            style={{ background: addOpacity(theme.primary_bg, 0.2) }}
          >
            {housePropertyData?.hpiType?.map((item, index) =>
              item.id == incomeData?.propertyType?.id ||
              item.id == incomeData?.propertyType ? (
                <h4 className="mr-auto" key={index}>
                  {item.name}
                </h4>
              ) : (
                ""
              )
            )}

            <h4>{incomeData?.totalAmountCarryForward}</h4>
            <div className="action_buttons">
              <button className="edit_btn" onClick={editForm}>
                <FaRegEdit />
              </button>
              <button className="delete_btn" onClick={handleDeleteIncomeRecord}>
                <RiDeleteBin6Fill />
              </button>
            </div>
          </div>
        )}
        <button
          className="secandary_button"
          onClick={() => {
            if (incomeData) {
              toast.warning("You Already have one record ");
            } else {
              setHouseIncomeForm(true);
            }
          }}
        >
          {houseIncomeForm
            ? strings.button.close_entry
            : strings.button.add_entry}
        </button>

        <FormDialogBox
          open={houseIncomeForm}
          formTitle={strings.tax.house_property_income}
          handleClose={() => {
            setHouseIncomeForm(false);
          }}
        >
          <div className="mt-4">
            {incomeData?.id ? (
              ""
            ) : (
              <div>
                <h4>{strings.income.choose_property_type}</h4>
                <Select
                  value={activeOption}
                  onChange={(e) => {
                    setActiveOption(e.target.value);
                  }}
                  className={"mt-2"}
                >
                  {housePropertyData?.hpiType?.map((item, ix) => (
                    <option value={item.id} key={ix}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
            )}

            {activeOption == 100 ? (
              <SelfOccupiedProperty
                handleCallback={(e) => {
                  handleIncomeSourceApi(e);
                }}
                formsData={editFormData}
                financialYear={housePropertyData?.financialYear}
              />
            ) : activeOption == 101 ? (
              <RentalProperty
                handleCallback={(e) => {
                  console.log("e", e);
                  handleIncomeSourceApi(e);
                }}
                formsData={editFormData}
                financialYear={housePropertyData?.financialYear}
              />
            ) : activeOption == 102 ? (
              <DeemedLetProperty
                handleCallback={(e) => {
                  handleIncomeSourceApi(e);
                }}
                formsData={editFormData}
                financialYear={housePropertyData?.financialYear}
              />
            ) : (
              ""
            )}
          </div>
        </FormDialogBox>
      </AccordianBox>
    </>
  );
}
