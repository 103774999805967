import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Banner from "../component/Home/Banner";
import Service from "../component/Home/Service";
import TaxFilling from "../component/Home/TaxFilling";
import Products from "../component/Home/Products";
import AllProduct from "../component/Home/AllProduct";
import FAQ from "../component/Home/FAQ";
import Customers from "../component/Home/Customers";
import AboutUs from "../component/Home/AboutUs";
import Support from "../component/Home/Support";

export default function Home() {
  
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <Banner />
      <Service />
      <TaxFilling />
      <Products />
      <AllProduct />
      <FAQ />
      <Customers />
      <AboutUs/>
      <Support />
    </Root>
  );
}

const Root = styled.section`
 background-color: ${(p) => p.theme.secandary_bg};
`;
