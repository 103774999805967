import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StyleFormBox from "../styledComponent/Form/StyleFormBox";
import CheckBox from "../styledComponent/Inputs/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getQuestionsAction,
  submitQuestionAction,
} from "../redux/profile/action";
import { setItrData } from "../redux/itr/action";
import LoadingBox from "../component/DialogBox/LoadingBox";
export default function Questions() {
  const [allQuestion, setAllQuestion] = useState([]);
  const { strings } = useSelector((p) => p.platformSetting);

  const [loader, setLoader] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelectOption = (ques, option) => {
    let updateQuestion = allQuestion.map((item, index) =>
      item.id === ques
        ? {
            ...item,
            options: item.isMultipleSelection
              ? item.options.map((i, ix) =>
                  i.id === option ? { ...i, isCorrect: !i.isCorrect } : i
                )
              : item.options.map((i, ix) =>
                  i.id === option
                    ? { ...i, isCorrect: !i.isCorrect }
                    : { ...i, isCorrect: false }
                ),
          }
        : item
    );

    setAllQuestion(updateQuestion);
  };

  const submitForm = () => {
    let newArray = [];
    allQuestion.forEach((question) => {
      const optionIds = question.options
        .filter((option) => option.isCorrect)
        .map((option) => option.id);
      if (optionIds.length > 0) {
        newArray.push({
          questionId: question.id,
          optionIds: optionIds,
        });
      }
    });

    dispatch(
      submitQuestionAction({ answers: newArray }, (e) => {
        if (e.status === 200) {
          const { id, userID } = e.data.itr;
          dispatch(setItrData({ id, userID }));
          navigate(`/filing/itr/itr_form_${e.data.itr.form}`);
        }
      })
    );
    setLoader(false);
  };

  useEffect(() => {
    setSkeletonLoader(true);
    dispatch(
      getQuestionsAction((data) => {
        if (data.status == 200) {
          setAllQuestion(data.data.questions);
        }
        setSkeletonLoader(false);
      })
    );
  }, []);

  return (
    <Root>
      <LoadingBox open={loader} />
      {skeletonLoader ? (
        <>
          {Array(3)
            .fill(0, 0, 3)
            .map((_, ix) => {
              return (
                <div className="skeleton_box my-3" key={ix}>
                  <div className="skeleton h-9"></div>
                  <div className="skeleton h-6 w-[40%]"></div>
                  <div className="skeleton h-6 w-[40%]"></div>
                  <div className="skeleton h-6 w-[40%]"></div>
                  <div className="skeleton h-6 w-[40%]"></div>
                  <div className="skeleton h-6 w-[40%]"></div>
                </div>
              );
            })}
          <div className="skeleton_box mt-3">
            <div className="skeleton h-9 "></div>
          </div>
        </>
      ) : (
        <div>
          {allQuestion.map((item, index) => (
            <div key={index}>
              <StyleFormBox>
                {index + 1} {" . "} {item.question}
                <div>
                  {item.options.map((i, ix) => (
                    <CheckBox
                      key={ix} // or key={i.id} if i.id is unique
                      value={i.isCorrect}
                      label={i.optionText}
                      handleChange={() => {
                        handleSelectOption(item.id, i.id);
                      }}
                    />
                  ))}
                </div>
              </StyleFormBox>
            </div>
          ))}
          <button
            className="primary_button mt-4"
            onClick={() => {
              setLoader(true);
              submitForm();
            }}
          >
            {strings.button.submit}
          </button>
        </div>
      )}
    </Root>
  );
}
const Root = styled.section`
  max-width: 800px;
  margin: auto;
  padding: 10px;
`;
