import React, { useEffect, useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import { useDispatch, useSelector } from "react-redux";
import { addOpacity } from "../../../../utils/helpers";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import Select from "../../../../styledComponent/Inputs/Select";
import {
  deleteIncome44AD,
  deleteIncome44ADA,
  getProfessionalBusiness,
  getProfessionalBusiness3,
  saveIncome44AD,
  saveIncome44ADA,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import Income44AD from "./Income44AD";
import Section44AE from "./Section44AE";
import LoadingBox from "../../../DialogBox/LoadingBox";
import FinancialParticular from "./FinancialParticular";
import AddGst from "./AddGst";
import RegularBusiness from "./RegularBusiness";
import OtherBusinessIncome from "./OtherBusinessIncome";
import { useNavigate } from "react-router-dom";
import Income44ADA from "./Income44ADA";

export default function ProfessionalIncome() {
  const [professionalIncomeForm, setProfessionalIncomeForm] = useState(false);
  const [financialParticularForm, setFinancialParticularForm] = useState(false);
  const [gstFormBox, setGstFormBox] = useState(false);
  const [optionData, setOptionData] = useState({});
  const [activeOption, setActiveOption] = useState("");
  const [editFormData, setEditFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const { strings, theme, categories } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [propertyObj, setPropertyObj] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getProfessionalBusinessApi = async () => {
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness(parm, (e) => {
        if (e.status == 200) {
          setOptionData((prev) => ({ ...prev, ...e.data }));
          if (ItrData.form !== "ITR_3") {
            setPropertyObj(e?.data?.businessIncomeType?.slice(0, 3));
          }
          // setActiveOption(e?.data?.businessIncomeType?.slice(0, 3)[0].id);
        } else {
          toast.error(e.message);
        }
      })
    );
  };

  const getProfessionalBusiness3Api = async () => {
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness3(parm, (e) => {
        if (e.status == 200) {
          setOptionData((prev) => ({ ...prev, ...e.data }));
          setPropertyObj(e?.data?.businessIncomeType?.slice(0, 5));
          // setActiveOption(e?.data?.businessIncomeType?.slice(0, 5)[0].id);
        } else {
          toast.error(e.message);
        }
      })
    );
  };

  const saveSection44ADAApi = async (value) => {
    setLoading(true);
    dispatch(
      saveIncome44ADA(value, (e) => {
        console.log("saveSection44ADAApi =========>", e.data);
        if (e.status == 200) {
          // setOptionData({
          //   ...optionData,
          //   businessProfessionIncome: {
          //     ...optionData.businessProfessionIncome,
          //     professionalIncome: e.data,
          //   },
          // });
          navigate(-1);
          // setProfessionalIncomeForm(false);
          // setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const saveSection44ADApi = async (value) => {
    setLoading(true);
    dispatch(
      saveIncome44AD(value, (e) => {
        console.log("saveSection44ADApi =========>", e);
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              businessIncome: e.data,
            },
          });
          setProfessionalIncomeForm(false);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteSection44ADAApi = async (id) => {
    setLoading(true);
    dispatch(
      deleteIncome44ADA({ id }, (e) => {
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              professionalIncome: null,
            },
          });
          setLoading(false);
        } else {
          // toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteSection44ADApi = async (id) => {
    setLoading(true);
    dispatch(
      deleteIncome44AD({ id }, (e) => {
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              businessIncome: null,
            },
          });
          setLoading(false);
        } else {
          // toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    setLoading(true);
    const fetchApis = async () => {
      const apiCalls = [];
      // Check if 'ITR_3' is selected and add the respective API call to the array
      if (ItrData.form === "ITR_3") {
        apiCalls.push(getProfessionalBusiness3Api());
      }
      apiCalls.push(getProfessionalBusinessApi());
      try {
        await Promise.all(apiCalls);
      } catch (error) {
        console.error("Error fetching data from APIs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchApis();
  }, []);


  return (
    <div className="mt-4 container_box">
      {loading ? (
        <LoadingBox open={loading} />
      ) : (
        <div>
          {" "}
          <div>
            <h4>{strings.income.choose_income_type}</h4>
            <Select
              value={activeOption || ""}
              onChange={(e) => {
                setActiveOption(e.target.value);
              }}
              className={"mt-2"}
            >
              <option value={""}>select an option</option>
              {propertyObj?.map((item, ix) => (
                <option value={item.id} key={ix}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
          {activeOption == 147 ? (
            <Income44ADA
            // handleCallback={(e) => {
            //   saveSection44ADAApi(e);
            // }}
            // formsData={editFormData}
            // optionData={optionData}
            />
          ) : activeOption == 148 ? (
            <Income44AD
              handleCallback={(e) => {
                saveSection44ADApi(e);
              }}
              formsData={editFormData}
              businessType={optionData.businessType}
              optionData={optionData}
            />
          ) : activeOption == 149 ? (
            <Section44AE
              data={optionData?.businessProfessionIncome?.businessDetail44AE}
              businessCode={optionData?.businessCodes}
              ownershipStatus={optionData?.ownershipStatus}
            />
          ) : activeOption == 625 ? (
            <RegularBusiness
              data={optionData?.professionalBusiness}
              businessType={optionData?.businessType}
            />
          ) : activeOption == 760 ? (
            <OtherBusinessIncome />
          ) : (
            ""
          )}
          <button
            className="secandary_button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Previous Page
          </button>
        </div>
      )}
    </div>
  );
}
