import React, { useEffect, useState } from "react";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import styled from "styled-components";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { addOpacity } from "../../utils/helpers";
import { redirect, useNavigate } from "react-router-dom";

export default function ItrUploadForm({ redirect }) {
  const [activeScreen, setActiveScreen] = useState("uploadForm");
  const [redirectLink, setRedirectLink] = useState("");
  const { theme, strings } = useSelector((s) => s.platformSetting);
  const navigate = useNavigate();

  useEffect(() => {
    const redirectMap = {
      ITR_1: "/form/filing/ITR_1/personalInfo",
      ITR_2: "/form/filing/ITR_2/personalInfo",
      ITR_3: "/form/filing/ITR_3/personalInfo",
      ITR_4: "/form/filing/ITR_4/personalInfo",
      ITR_5: "/form/filing/ITR_5/personalInfo",
      ITR_6: "/form/filing/ITR_6/personalInfo",
    };

    if (redirectMap[redirect]) {
      setRedirectLink(redirectMap[redirect]);
    }
  }, [redirect]);

  return (
    <Root theme={theme}>
      <div className="upload_form">
        <StyleFormBox>
          <div className="upload_cloud text-5xl  p-8 rounded-full mx-auto">
            <FaCloudUploadAlt />
          </div>
          <p className="my-4">
            {strings.form.auto_fetch_details_upload_form_16}
          </p>
          <h4 className="">{strings.form.choose_your_form_16}</h4>
          <p className="my-4">{strings.form.upload_password_protected_pdf}</p>
          <div className="upload_form_box">
            <input type="file" accept=".pdf" />
            <span>{strings.form.choose_your_form_16} </span>
          </div>
          <button className="primary_button mt-5">
            {strings.button.submit}
          </button>
        </StyleFormBox>
        <StyleFormBox>
          <p className="my-4">{strings.form.upload_password_protected_pdf}</p>
          <button
            className="secandary_button"
            onClick={() => {
              navigate(redirectLink);
            }}
          >
            {strings.button.continue_without_form_16}
          </button>
        </StyleFormBox>
      </div>
    </Root>
  );
}
const Root = styled.div`
  .upload_form {
    max-width: 500px;
    margin: auto;
    text-align: center;
  }
  .upload_cloud {
    width: fit-content;
    background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.2)};
  }
  .upload_form_box {
    position: relative;
    border: 1px solid ${(p) => p.theme.border_clr};
    color: ${(p) => p.theme.primary_txt};
    background: ${(p) => p.theme.secandary_bg};
    border-radius: 12px;
    overflow: hidden;
    margin: 20px 0px;
    > input {
      border: none;
      background: none;
      z-index: 9;
      margin: 0px;
      opacity: 0;
      position: relative;
      cursor: pointer;
    }
    > span {
      z-index: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transform: translate(-50%, -50%);
    }
  }
`;
