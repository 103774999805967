import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../../../styledComponent/Inputs/Input";
import CheckBox from "../../../../styledComponent/Inputs/CheckBox";
import Select from "../../../../styledComponent/Inputs/Select";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import { addMembersAction, getMembersAction } from "../../../../redux/itr/action";
import { removeEmptyKeys } from "../../../../utils/helpers";
import { toast } from "react-toastify";
import { RiDeleteBin6Fill } from "react-icons/ri";

export default function AdditionalInfo({ handleCallback }) {
  const [formData, setFormData] = useState({});

  const [loader, setLoader] = useState(false);
  const [citiesList, setCitiesList] = useState([]);
  const [activeState, setActiveState] = useState(1);
  const [memberStatus, setMemberStatus] = useState([]);
  const { strings, states } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(strings.validation_message.required),
    lastName: Yup.string().required(strings.validation_message.required),
    dob: Yup.string().required(strings.validation_message.required),
    status: Yup.string().required(strings.validation_message.required),
    address: Yup.object().shape({
      flatDoorNo: Yup.string().required(strings.validation_message.required),
      area: Yup.string().required(strings.validation_message.required),
      city: Yup.string().required(strings.validation_message.required),
      state: Yup.string().required(strings.validation_message.required),
      pinCode: Yup.string().required(strings.validation_message.required),
      country: Yup.string().required(strings.validation_message.required),
    }),
  });

  //   ?itrId=142&id=1

  const handleFormSubmit = (data) => {
    console.log("data =====>", removeEmptyKeys(data));
    dispatch(
      addMembersAction(removeEmptyKeys(data), (e) => {
        if (e.status === 200) {
          console.log("eee=== > ", e);
          handleCallback();
        } else {
        }
      })
    );
  };

  const getMembersData = (id = 0) => {
    setFormData({
        itrId: ItrData.id,
        changesDuringPrevYear: false,
        additionalPartners: [
          {
            partnerName: "Ankit Sharma",
            PAN: "AAAAA5555A",
            dateOfAdmissionRetirement: "1997-11-07",
            RemunerationAmount: 7,
            percentOfShare: 25,
            partnerStatus: 599,
          },
        ],
        llpPin: "AA07AA",
        itrId: 107,
      });



    // const parm = `?itrId=${ItrData.id}&id=${id}`;
    // dispatch(
    //   getMembersAction(parm, (e) => {
    //     console.log("eee=== > ", e);
    //     setMemberStatus(e?.data?.memberStatus);
    //     let editData = e.data?.members;
    //     setFormData({
    //       itrId: ItrData.id,
    //       changesDuringPrevYear: false,
    //       additionalPartners: [
    //         {
    //           partnerName: "Ankit Sharma",
    //           PAN: "AAAAA5555A",
    //           dateOfAdmissionRetirement: "1997-11-07",
    //           RemunerationAmount: 7,
    //           percentOfShare: 25,
    //           partnerStatus: 599,
    //         },
    //       ],
    //       llpPin: "AA07AA",
    //       itrId: 107,
    //     });

    //     setLoader(false);
    //   })
    // );
  };
  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };

  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    setLoader(true);
    getMembersData();
    setLoader(false);
  }, []);

  
  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <>
            <StyleFormBox>
              <p>
                {strings.personal_info.additional_info_about_partner_member}
              </p>
              <hr />
              <div>
                <label>
                  {strings.personal_info.was_and_change_in_previous_year} *
                </label>
                <div className="flex items-center">
                  <CheckBox
                    value={values.changesDuringPrevYear == true}
                    label={strings.placeholder.yes}
                    handleChange={(e) => {
                      setValues({ ...values, changesDuringPrevYear: false });
                    }}
                  />
                  <CheckBox
                    value={values.changesDuringPrevYear == false}
                    label={strings.placeholder.no}
                    handleChange={(e) => {
                      setValues({ ...values, changesDuringPrevYear: true });
                    }}
                  />
                </div>
              </div>
            </StyleFormBox>

            {/* <FieldArray name="additionalPartners">
              {({ insert, remove, push }) => (
                <StyleFormBox>
                  {values?.additionalPartners?.map((item, index) => (
                    <StyleFormBox>
                      <div className="flex_input my-2 items-center" key={index}>
                        <div>
                          <label>{strings.label.description}</label>
                          <Input
                            value={item.description}
                            name={`onlineGamingLottery.${index}.description`}
                            placeholder={strings.label.description}
                            error={
                              errors?.onlineGamingLottery &&
                              errors?.onlineGamingLottery[index]?.description
                            }
                            onChange={handleChange}
                          />
                        </div>

                        <div>
                          <label>{strings.income.amount}</label>
                          <Input
                            type={"number"}
                            inputType={"currency"}
                            name={`onlineGamingLottery.${index}.amount`}
                            value={item.amount}
                            error={
                              errors?.onlineGamingLottery &&
                              errors?.onlineGamingLottery[index]?.amount
                            }
                            onChange={handleChange}
                          />
                        </div>
                        <button
                          className="delete_button mt-6"
                          onClick={() => remove(index)}
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    </StyleFormBox>
                  ))}

                  <div className="text-right mt-5">
                    <button
                      className="add_item_button"
                      onClick={(e) => {
                        e.preventDefault();
                        push({ source: "", description: "", amount: "" });
                      }}
                    >
                      {strings.button.add_more_item}
                    </button>
                  </div>
                </StyleFormBox>
              )}
            </FieldArray> */}

            <StyleFormBox>
              <div className="flex_input">
                <div>
                  <label>{strings.personal_info.partner_name} </label>
                  <Input
                    name={"firstName"}
                    placeholder={strings.label.first_name}
                    error={errors.firstName}
                    value={values?.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.personal_info.admitted_retired} </label>
                  <Select
                    name={"status"}
                    error={errors.status}
                    value={values?.status}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>
                    {memberStatus?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.pan_number} </label>
                  <Input
                    name={"sharePercentage"}
                    placeholder={strings.label.pan_number}
                    error={errors.sharePercentage}
                    value={values?.sharePercentage}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>
                    {strings.personal_info.date_of_admission_retirement} *
                  </label>
                  <Input
                    name={"dob"}
                    type={"date"}
                    placeholder={strings.label.pan_number}
                    error={errors.dob}
                    value={values?.dob}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>
                    {
                      strings.personal_info
                        .remuneration_paid_in_retiring_partner
                    }{" "}
                  </label>
                  <Input
                    name={"firstName"}
                    placeholder={strings.label.first_name}
                    error={errors.firstName}
                    value={values?.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.personal_info.percent_of_share} </label>
                  <Input
                    name={"firstName"}
                    placeholder={strings.label.first_name}
                    error={errors.firstName}
                    value={values?.firstName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </StyleFormBox>

            <StyleFormBox>
              <div className="flex_input">
                <label>
                  {
                    strings.personal_info
                      .recognition_number_alootted_by_dpiit_for_startup
                  }
                </label>
                <Input
                  name={"address.street"}
                  error={errors.address}
                  value={values?.address}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>
            <StyleFormBox>
              <div className="flex_input">
                <label>{strings.personal_info.llp_pin_by_mca}</label>
                <Input
                  name={"address.street"}
                  error={errors.address}
                  value={values?.address}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>
            <button className="secandary_button mt-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </>
        )}
      </Formik>
    </div>
  );
}
