import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationBox from "../DialogBox/ConfirmationBox";
import { FaUser } from "react-icons/fa";
import styled from "styled-components";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../../styledComponent/Inputs/Input";
import { updateUserAction } from "../../redux/profile/action";

export default function EditProfile() {
  const [formData, setFormData] = useState({});
  const [profileEditBox, setProfileEditBox] = useState(false);
  const [userImage, setUserImage] = useState();
  const [loading, setLoading] = useState(false);

  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { userDetails } = useSelector((s) => s.profile);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    userFirstName: Yup.string().required(strings.validation_message.first_name),
    userLastName: Yup.string().required(strings.validation_message.last_name),
    // userEmail: Yup.string()
    //   .email(strings.validation_message.invalid_email)
    //   .required(strings.validation_message.email),
    phoneNumber: Yup.string().required(strings.validation_message.phone_number),
  });

  const handleSubmitForm = (data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    setLoading(true);
    dispatch(
      updateUserAction(formData, (e) => {
        console.log("e", e);
        if (e.status === 200) {
          setProfileEditBox(false);
        } else {
          setProfileEditBox(false);
        }
      })
    );
  };

  useEffect(() => {
    if (userDetails) {
      setFormData({
        userFirstName: userDetails?.userFirstName || "",
        userLastName: userDetails?.userLastName || "",
        userEmail: userDetails?.userEmail || "",
        phoneNumber: userDetails?.phoneNumber || "",
        avatar: userDetails?.avatar || "",
      });
    }
  }, [userDetails]);

  return (
    <Root>
      <button
        style={{ color: theme.primary_bg }}
        onClick={() => {
          setProfileEditBox(true);
        }}
      >
        {strings.button.edit_profile}
      </button>
      <ConfirmationBox
        open={profileEditBox}
        handleClose={() => {
          setProfileEditBox(false);
        }}
      >
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleSubmitForm(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <div className="edit_box">
              <h4 className="mb-5">{strings.profile.update_profile}</h4>
              <div className="image_select w-40 h-40 rounded-full block mx-auto  border-6 overflow-hidden relative">
                <input
                  type="file"
                  accept=".jpeg,.jpg"
                  className="h-full w-full cursor-pointer opacity-0 relative z-20"
                  onChange={(e) => {
                    setFieldValue("avatar", e.currentTarget.files[0]);
                  }}
                />
                {values.avatar ? (
                  <img
                    src={
                      values.avatar.name
                        ? URL.createObjectURL(values?.avatar)
                        : values?.avatar
                    }
                    className="absolute top-0 left-0 h-full w-full object-cover"
                  />
                ) : (
                  <span
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-7xl z-10"
                    style={{ color: theme.primary_bg }}
                  >
                    <FaUser />
                  </span>
                )}

                {/* {userImage ? (
                  <img
                    src={URL.createObjectURL(userImage[0])}
                    className="absolute top-0 left-0 h-full w-full object-cover"
                  />
                ) : (
                  <span
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-7xl z-10"
                    style={{ color: theme.primary_bg }}
                  >
                    <FaUser />
                  </span>
                )} */}
              </div>
              <div className="flex justify-between gap-3">
                <div className="w-full">
                  <label>{strings.label.first_name}</label>
                  <Input
                    name={"userFirstName"}
                    placeholder={strings.label.first_name}
                    error={errors.userFirstName}
                    value={values.userFirstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <label>{strings.label.last_name}</label>
                  <Input
                    name={"userLastName"}
                    placeholder={strings.label.last_name}
                    error={errors.userLastName}
                    value={values.userLastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-3">
                <div className="w-full">
                  <label>{strings.label.email}</label>
                  <Input
                    name={"userEmail"}
                    placeholder={strings.label.email}
                    error={errors.userEmail}
                    value={values.userEmail}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <label>{strings.label.phone_number}</label>
                  <Input
                    inputType={"phone"}
                    name={"phoneNumber"}
                    error={errors.phoneNumber}
                    value={values.phoneNumber}
                    onChange={(phoneNumber) =>
                      handleChange({
                        target: { name: "phoneNumber", value: phoneNumber },
                      })
                    }
                  />
                </div>
              </div>

              <button className="primary_button mt-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </ConfirmationBox>
    </Root>
  );
}
const Root = styled.div`
  .image_select {
    background-color: ${(p) => p.theme.secandary_bg};
    border: 4px solid ${(p) => p.theme.tertiary_bg};
  }
`;
