import {
  ADD_DONATIONS,
  ADD_MEMBERS,
  DEL_BALANCE_SHEET,
  DEL_PROFIT_AND_LOSS,
  DELETE_ADDITION_TO_P_L,
  DELETE_AUDIT_UNDER_IT_ACT,
  DELETE_AUDITOR_DETAILS,
  DELETE_BOOKS_ACCOUNT_NOT_MAINTAINED,
  DELETE_BUSINESS_DETAILS_44AE,
  DELETE_CAPITAL_GAIN,
  DELETE_DEPRECIATION_LOSSE,
  DELETE_DETAILOFPROFESSION_BUSINESS,
  DELETE_DONATIONS,
  DELETE_FILING_BUE_DATE,
  DELETE_GOODS_CARRIAGES,
  DELETE_GST_DETAILS,
  DELETE_HOUSE_INCOME_SOURCE,
  DELETE_INCOME_44AD,
  DELETE_INCOME_44ADA,
  DELETE_MANUFACTURING_CONCERN,
  DELETE_MEMBERS,
  DELETE_OTHER_AUDITS_ACTION,
  DELETE_OTHER_INCOME_SOURCE,
  DELETE_PREVIOUS_YEAR_LOSSES,
  DELETE_REMUNERATION,
  DELETE_SCHEDULE_BP,
  DELETE_SCHEDULE_OI,
  DELETE_SECTION_40_AND_43B,
  DELETE_SPECULATIVE_INCOME,
  DELETE_TAX_COLLECTED,
  DELETE_TAX_PAID_CHALLAN,
  DELETE_TDS_IMMOVABLE_PROPERTY,
  DELETE_TDS_NON_SALARY,
  DELETE_TRADING_CONCERN,
  EDIT_ADDITION_TO_P_L,
  EDIT_AUDIT_UNDER_IT_ACT,
  EDIT_BUSINESS_DETAILS_44AE,
  EDIT_GOODS_CARRIAGES,
  EDIT_GST_DETAILS,
  EDIT_MANUFACTURING_CONCERN,
  EDIT_OTHER_AUDITS_ACTION,
  EDIT_SCHEDULE_BP,
  EDIT_SCHEDULE_OI,
  EDIT_SECTION_40_AND_43B,
  EDIT_SPECULATIVE_INCOME,
  EDIT_TRADING_CONCERN,
  GET_ADDITION_TO_P_L,
  GET_AUDIT_UNDER_IT_ACT,
  GET_AUDITOR_DETAILS,
  GET_BALANCE_SHEET,
  GET_BOOKS_ACCOUNT_NOT_MAINTAINED,
  GET_CAPITAL_GAIN,
  GET_CARRY_FORWARD_LOSS,
  GET_DEDUCTIONS,
  GET_DETAILOFPROFESSION_BUSINESS,
  GET_GROSS_INCOME,
  GET_HOUSE_INCOME_SOURCE,
  GET_LIABILITIES_ASSETS,
  GET_MANUFACTURING_CONCERN,
  GET_MEMBERS,
  GET_OTHER_AUDITS_ACTION,
  GET_OTHER_INCOME_SOURCE,
  GET_PERSONAL_INFO,
  GET_PROFESSIONAL_BUSINESS,
  GET_PROFESSIONAL_BUSINESS3,
  GET_PROFIT_AND_LOSS,
  GET_REMUNERATION,
  GET_SCHEDULE_BP,
  GET_SCHEDULE_OI,
  GET_SECTION_40_AND_43B,
  GET_SPECULATIVE_INCOME,
  GET_TRADING_CONCERN,
  GET_USER_SALARY_BREAKUP,
  SAVE_ADDITION_TO_P_L,
  SAVE_AUDIT_UNDER_IT_ACT,
  SAVE_AUDITOR_DETAILS,
  SAVE_BALANCE_SHEET,
  SAVE_BONDS_DEBENTURES,
  SAVE_BOOKS_ACCOUNT_NOT_MAINTAINED,
  SAVE_BUSINESS_DETAILS_44AE,
  SAVE_CAPITAL_GAIN,
  SAVE_DEDUCTIONS,
  SAVE_DEPRECIATION_LOSSE,
  SAVE_DETAILOFPROFESSION_BUSINESS,
  SAVE_FILING_BUE_DATE,
  SAVE_GOODS_CARRIAGES,
  SAVE_GST_DETAILS,
  SAVE_INCOME_44AD,
  SAVE_INCOME_44ADA,
  SAVE_LIABILITIES_ASSETS,
  SAVE_MANUFACTURING_CONCERN,
  SAVE_OTHER_ASSETS,
  SAVE_OTHER_AUDITS_ACTION,
  SAVE_PREVIOUS_YEAR_LOSSES,
  SAVE_PROFIT_AND_LOSS,
  SAVE_REMUNERATION,
  SAVE_SALE_OF_LAND,
  SAVE_SCHEDULE_BP,
  SAVE_SCHEDULE_OI,
  SAVE_SECTION_40_AND_43B,
  SAVE_SPECULATIVE_INCOME,
  SAVE_STOCKS,
  SAVE_TAX_COLLECTED,
  SAVE_TAX_PAID_CHALLAN,
  SAVE_TDS_IMMOVABLE_PROPERTY,
  SAVE_TDS_NON_SALARY,
  SAVE_TRADING_CONCERN,
  SET_GROSS_INCOME,
  SET_HOUSE_INCOME_SOURCE,
  SET_ITR_ID,
  SET_OTHER_INCOME_SOURCE,
  SET_PERSONAL_INFO,
  UPDATE_BONDS_DEBENTURES,
  UPDATE_BOOKS_ACCOUNT_NOT_MAINTAINED,
  UPDATE_CAPITAL_GAIN,
  UPDATE_DEPRECIATION_LOSSE,
  UPDATE_DETAILOFPROFESSION_BUSINESS,
  UPDATE_DONATIONS,
  UPDATE_FILING_BUE_DATE,
  UPDATE_HOUSE_INCOME_SOURCE,
  UPDATE_MEMBERS,
  UPDATE_OTHER_ASSETS,
  UPDATE_PREVIOUS_YEAR_LOSSES,
  UPDATE_REMUNERATION,
  UPDATE_SALE_OF_LAND,
  UPDATE_STOCKS,
  UPDATE_TAX_COLLECTED,
  UPDATE_TAX_PAID_CHALLAN,
  UPDATE_TDS_IMMOVABLE_PROPERTY,
  UPDATE_TDS_NON_SALARY,
  GET_DETAIL_BALANCE_SHEET,
  SAVE_DETAIL_BALANCE_SHEET,
  UPDATE_DETAIL_BALANCE_SHEET,
  SAVE_BAD_DEBTS,
  DEL_BAD_DEBTS,
  UPDATE_BAD_DEBTS,
  DELETE_PROFESSIONAL_BUSINESS3,
  GET_DEDUCTION_IOAA,
  SAVE_DEDUCTION_IOAA,
  EDIT_DEDUCTION_IOAA,
  GET_DEDUCTION_80,
  SAVE_DEDUCTION_80,
  GET_DONATION_RESEARCH_ASSOCIATION,
  SAVE_DONATION_RESEARCH_ASSOCIATION,
  EDIT_DONATION_RESEARCH_ASSOCIATION,
  DELETE_DONATION_RESEARCH_ASSOCIATION,
  GET_DONATION_TO_LA,
  SAVE_DONATION_TO_LA,
  EDIT_DONATION_TO_LA,
  DELETE_DONATION_TO_LA,
  GET_DEDUCTION_80P,
  SAVE_DEDUCTION_80P,
} from "../constant";

export const getPersonalInfoAction = (id, handleCallback) => ({
  type: GET_PERSONAL_INFO,
  payload: { id, handleCallback },
});

export const setPersonalInfoAction = (data, handleCallback) => ({
  type: SET_PERSONAL_INFO,
  payload: { data, handleCallback },
});

// GROSS INCOME ACTIONS

export const setGrossIncome = (data, handleCallback) => ({
  type: SET_GROSS_INCOME,
  payload: { data, handleCallback },
});

export const getUserSalaryBreakup = (id, handleCallback) => ({
  type: GET_USER_SALARY_BREAKUP,
  payload: { id, handleCallback },
});
export const getGrossIncomeAction = (id, handleCallback) => ({
  type: GET_GROSS_INCOME,
  payload: { id, handleCallback },
});

export const setOtherIncomeSource = (data, handleCallback) => ({
  type: SET_OTHER_INCOME_SOURCE,
  payload: { data, handleCallback },
});
export const getOtherIncomeSource = (id, handleCallback) => ({
  type: GET_OTHER_INCOME_SOURCE,
  payload: { id, handleCallback },
});

export const deleteOtherIncomeSource = (parm, handleCallback) => ({
  type: DELETE_OTHER_INCOME_SOURCE,
  payload: { parm, handleCallback },
});

// House Income Source
export const getHousePropertyIncome = (parm, handleCallback) => ({
  type: GET_HOUSE_INCOME_SOURCE,
  payload: { parm, handleCallback },
});

export const setHousePropertyIncome = (data, handleCallback) => ({
  type: SET_HOUSE_INCOME_SOURCE,
  payload: { data, handleCallback },
});

export const updateHousePropertyIncome = (data, handleCallback) => ({
  type: UPDATE_HOUSE_INCOME_SOURCE,
  payload: { data, handleCallback },
});

export const deleteHousePropertyIncome = (parm, handleCallback) => ({
  type: DELETE_HOUSE_INCOME_SOURCE,
  payload: { parm, handleCallback },
});

// Non Salary TDS
export const saveTdsNonSalary = (data, handleCallback) => ({
  type: SAVE_TDS_NON_SALARY,
  payload: { data, handleCallback },
});

export const updateTdsNonSalaryAction = (data, handleCallback) => ({
  type: UPDATE_TDS_NON_SALARY,
  payload: { data, handleCallback },
});

export const deleteTdsNonSalaryAction = (data, handleCallback) => ({
  type: DELETE_TDS_NON_SALARY,
  payload: { data, handleCallback },
});

// TDS on Sale/Rent of Immovable Property
export const saveTDSImmovablePropertyAction = (data, handleCallback) => ({
  type: SAVE_TDS_IMMOVABLE_PROPERTY,
  payload: { data, handleCallback },
});

export const updateTDSImmovablePropertyAction = (data, handleCallback) => ({
  type: UPDATE_TDS_IMMOVABLE_PROPERTY,
  payload: { data, handleCallback },
});

export const deleteTDSImmovablePropertyAction = (data, handleCallback) => ({
  type: DELETE_TDS_IMMOVABLE_PROPERTY,
  payload: { data, handleCallback },
});

// Taxes collected at source
export const saveTaxCollectedAction = (data, handleCallback) => ({
  type: SAVE_TAX_COLLECTED,
  payload: { data, handleCallback },
});

export const updateTaxCollectedAction = (data, handleCallback) => ({
  type: UPDATE_TAX_COLLECTED,
  payload: { data, handleCallback },
});

export const deleteTaxCollectedAction = (data, handleCallback) => ({
  type: DELETE_TAX_COLLECTED,
  payload: { data, handleCallback },
});

// Self tax payments
export const saveTaxPaidChallanAction = (data, handleCallback) => ({
  type: SAVE_TAX_PAID_CHALLAN,
  payload: { data, handleCallback },
});

export const updateTaxPaidChallanAction = (data, handleCallback) => ({
  type: UPDATE_TAX_PAID_CHALLAN,
  payload: { data, handleCallback },
});

export const deleteTaxPaidChallanAction = (data, handleCallback) => ({
  type: DELETE_TAX_PAID_CHALLAN,
  payload: { data, handleCallback },
});

export const setItrData = (data) => ({
  type: SET_ITR_ID,
  payload: data,
});

// Tax Saving Action

export const postAddDonationsAction = (data, handleCallback) => ({
  type: ADD_DONATIONS,
  payload: { data, handleCallback },
});

export const updateAddDonationsAction = (data, handleCallback) => ({
  type: UPDATE_DONATIONS,
  payload: { data, handleCallback },
});
export const deleteDonationsAction = (data, handleCallback) => ({
  type: DELETE_DONATIONS,
  payload: { data, handleCallback },
});

export const getDeductionsAction = (id, handleCallback) => ({
  type: GET_DEDUCTIONS,
  payload: { id, handleCallback },
});
export const saveDeductionsAction = (data, handleCallback) => ({
  type: SAVE_DEDUCTIONS,
  payload: { data, handleCallback },
});

// Capital gain   Apis  =======================================================

export const getCapitalGainAction = (id, handleCallback) => ({
  type: GET_CAPITAL_GAIN,
  payload: { id, handleCallback },
});

export const deleteCapitalGainAction = (data, handleCallback) => ({
  type: DELETE_CAPITAL_GAIN,
  payload: { data, handleCallback },
});

// Stocks & Mutual Funds  ===========================================
export const saveStocksAction = (data, handleCallback) => ({
  type: SAVE_STOCKS,
  payload: { data, handleCallback },
});

export const updateStocksAction = (data, handleCallback) => ({
  type: UPDATE_STOCKS,
  payload: { data, handleCallback },
});

// Sale of Land or Building =================================
export const saveSaleOfLandAction = (data, handleCallback) => ({
  type: SAVE_SALE_OF_LAND,
  payload: { data, handleCallback },
});

export const updateSaleOfLandAction = (data, handleCallback) => ({
  type: UPDATE_SALE_OF_LAND,
  payload: { data, handleCallback },
});

// Bonds & Debentures =============================================
export const saveBondsAndDebentureAction = (data, handleCallback) => ({
  type: SAVE_BONDS_DEBENTURES,
  payload: { data, handleCallback },
});

export const updateBondsAndDebentureAction = (data, handleCallback) => ({
  type: UPDATE_BONDS_DEBENTURES,
  payload: { data, handleCallback },
});

// Deemed Capital Gains =======================================
export const saveCapitalGainsAction = (data, handleCallback) => ({
  type: SAVE_CAPITAL_GAIN,
  payload: { data, handleCallback },
});

export const updateCapitalGainsAction = (data, handleCallback) => ({
  type: UPDATE_CAPITAL_GAIN,
  payload: { data, handleCallback },
});

// Add Any Other Assets =============================================

export const saveOtherAssetsAction = (data, handleCallback) => ({
  type: SAVE_OTHER_ASSETS,
  payload: { data, handleCallback },
});

export const updateOtherAssetsAction = (data, handleCallback) => ({
  type: UPDATE_OTHER_ASSETS,
  payload: { data, handleCallback },
});

// ------------------------------------ add Members -------------------------

export const getMembersAction = (parm, handleCallback) => ({
  type: GET_MEMBERS,
  payload: { parm, handleCallback },
});

export const addMembersAction = (data, handleCallback) => ({
  type: ADD_MEMBERS,
  payload: { data, handleCallback },
});

export const updateMembersAction = (data, handleCallback) => ({
  type: UPDATE_MEMBERS,
  payload: { data, handleCallback },
});

export const deleteMemberAction = (parm, handleCallback) => ({
  type: DELETE_MEMBERS,
  payload: { parm, handleCallback },
});

// =======================================  Professional, Freelancing and Business Income =====================================

export const getProfessionalBusiness = (parm, handleCallback) => ({
  type: GET_PROFESSIONAL_BUSINESS,
  payload: { parm, handleCallback },
});

export const getProfessionalBusiness3 = (parm, handleCallback) => ({
  type: GET_PROFESSIONAL_BUSINESS3,
  payload: { parm, handleCallback },
});

export const delProfessionalBusiness3 = (data, handleCallback) => ({
  type: DELETE_PROFESSIONAL_BUSINESS3,
  payload: { data, handleCallback },
});

//--------- Regular Business - details of profession and business--------

export const saveDetailOfProfessionBusinessAction = (data, handleCallback) => ({
  type: SAVE_DETAILOFPROFESSION_BUSINESS,
  payload: { data, handleCallback },
});

export const updateDetailOfProfessionBusinessAction = (
  data,
  handleCallback
) => ({
  type: UPDATE_DETAILOFPROFESSION_BUSINESS,
  payload: { data, handleCallback },
});

export const delDetailOfProfessionBusinessAction = (data, handleCallback) => ({
  type: DELETE_DETAILOFPROFESSION_BUSINESS,
  payload: { data, handleCallback },
});

export const getDetailOfProfessionBusinessAction = (data, handleCallback) => ({
  type: GET_DETAILOFPROFESSION_BUSINESS,
  payload: { data, handleCallback },
});

export const getBalanceSheetAction = (data, handleCallback) => ({
  type: GET_BALANCE_SHEET,
  payload: { data, handleCallback },
});

export const saveBalanceSheetAction = (data, handleCallback) => ({
  type: SAVE_BALANCE_SHEET,
  payload: { data, handleCallback },
});

export const delBalanceSheetAction = (data, handleCallback) => ({
  type: DEL_BALANCE_SHEET,
  payload: { data, handleCallback },
});

export const getDetailBalanceSheetAction = (data, handleCallback) => ({
  type: GET_DETAIL_BALANCE_SHEET,
  payload: { data, handleCallback },
});

export const saveDetailBalanceSheetAction = (data, handleCallback) => ({
  type: SAVE_DETAIL_BALANCE_SHEET,
  payload: { data, handleCallback },
});

export const updateDetailBalanceSheetAction = (data, handleCallback) => ({
  type: UPDATE_DETAIL_BALANCE_SHEET,
  payload: { data, handleCallback },
});

export const postBadDebtsAction = (data, handleCallback) => ({
  type: SAVE_BAD_DEBTS,
  payload: { data, handleCallback },
});

export const delBadDebtsAction = (data, handleCallback) => ({
  type: DEL_BAD_DEBTS,
  payload: { data, handleCallback },
});

export const updateBadDebtsAction = (data, handleCallback) => ({
  type: UPDATE_BAD_DEBTS,
  payload: { data, handleCallback },
});

export const getProfitAndLossAction = (data, handleCallback) => ({
  type: GET_PROFIT_AND_LOSS,
  payload: { data, handleCallback },
});

export const saveProfitAndLossAction = (data, handleCallback) => ({
  type: SAVE_PROFIT_AND_LOSS,
  payload: { data, handleCallback },
});

export const delProfitAndLossAction = (data, handleCallback) => ({
  type: DEL_PROFIT_AND_LOSS,
  payload: { data, handleCallback },
});

// -------- Professional Income under Section 44ADA ----------

export const saveIncome44ADA = (data, handleCallback) => ({
  type: SAVE_INCOME_44ADA,
  payload: { data, handleCallback },
});

export const deleteIncome44ADA = (data, handleCallback) => ({
  type: DELETE_INCOME_44ADA,
  payload: { data, handleCallback },
});

// -------- Professional Income under Section 44AD ----------

export const saveIncome44AD = (data, handleCallback) => ({
  type: SAVE_INCOME_44AD,
  payload: { data, handleCallback },
});

export const deleteIncome44AD = (data, handleCallback) => ({
  type: DELETE_INCOME_44AD,
  payload: { data, handleCallback },
});

// -------- Professional Income under Section 44AE ----------

export const saveIncome44AE = (data, handleCallback) => ({
  type: SAVE_INCOME_44AD,
  payload: { data, handleCallback },
});

export const deleteIncome44AE = (data, handleCallback) => ({
  type: DELETE_INCOME_44AD,
  payload: { data, handleCallback },
});

// ======== Business Details =========================

export const saveBusinessDetails44AEAction = (data, handleCallback) => ({
  type: SAVE_BUSINESS_DETAILS_44AE,
  payload: { data, handleCallback },
});
export const editBusinessDetails44AEAction = (data, handleCallback) => ({
  type: EDIT_BUSINESS_DETAILS_44AE,
  payload: { data, handleCallback },
});

export const deleteBusinessDetails44AEAction = (data, handleCallback) => ({
  type: DELETE_BUSINESS_DETAILS_44AE,
  payload: { data, handleCallback },
});

//  ====================== Goods Carriage Details =================

export const saveGoodsCarriagesAction = (data, handleCallback) => ({
  type: SAVE_GOODS_CARRIAGES,
  payload: { data, handleCallback },
});
export const editGoodsCarriagesAction = (data, handleCallback) => ({
  type: EDIT_GOODS_CARRIAGES,
  payload: { data, handleCallback },
});

export const deleteGoodsCarriagesAction = (data, handleCallback) => ({
  type: DELETE_GOODS_CARRIAGES,
  payload: { data, handleCallback },
});



// ================================== Financial Particulars =====================

export const saveLiabilitiesAndAssetsAction = (data, handleCallback) => ({
  type: SAVE_LIABILITIES_ASSETS,
  payload: { data, handleCallback },
});

export const getLiabilitiesAndAssetsAction = (parm, handleCallback) => ({
  type: GET_LIABILITIES_ASSETS,
  payload: { parm, handleCallback },
});

// ==================================== Add GST Details ============================

export const saveaddGSTDetailsAction = (data, handleCallback) => ({
  type: SAVE_GST_DETAILS,
  payload: { data, handleCallback },
});

export const editaddGSTDetailsAction = (data, handleCallback) => ({
  type: EDIT_GST_DETAILS,
  payload: { data, handleCallback },
});
export const deleteaddGSTDetailsAction = (data, handleCallback) => ({
  type: DELETE_GST_DETAILS,
  payload: { data, handleCallback },
});

// ===================================  Carry Forward of Losses  =========================

export const getCarryForwardLossAction = (id, handleCallback) => ({
  type: GET_CARRY_FORWARD_LOSS,
  payload: { id, handleCallback },
});

export const savePreviousYearLossesAction = (data, handleCallback) => ({
  type: SAVE_PREVIOUS_YEAR_LOSSES,
  payload: { data, handleCallback },
});
export const deletePreviousYearLossesAction = (data, handleCallback) => ({
  type: DELETE_PREVIOUS_YEAR_LOSSES,
  payload: { data, handleCallback },
});

export const editPreviousYearLossesAction = (data, handleCallback) => ({
  type: UPDATE_PREVIOUS_YEAR_LOSSES,
  payload: { data, handleCallback },
});

export const saveDepreciationLossAction = (data, handleCallback) => ({
  type: SAVE_DEPRECIATION_LOSSE,
  payload: { data, handleCallback },
});
export const deleteDepreciationLossAction = (data, handleCallback) => ({
  type: DELETE_DEPRECIATION_LOSSE,
  payload: { data, handleCallback },
});
export const editDepreciationLossAction = (data, handleCallback) => ({
  type: UPDATE_DEPRECIATION_LOSSE,
  payload: { data, handleCallback },
});

export const saveFilingDueDateAction = (data, handleCallback) => ({
  type: SAVE_FILING_BUE_DATE,
  payload: { data, handleCallback },
});
export const deleteFilingDueDateAction = (data, handleCallback) => ({
  type: DELETE_FILING_BUE_DATE,
  payload: { data, handleCallback },
});
export const editFilingDueDateAction = (data, handleCallback) => ({
  type: UPDATE_FILING_BUE_DATE,
  payload: { data, handleCallback },
});

// ============================================= Professional, Freelancing and Business Income ===========================

// export const getProfessionalIncomeAction = (id, handleCallback) => ({
//   type: GET_PROFESSIONAL_INCOME,
//   payload: { id, handleCallback },
// });

// --- Remuneration from Partnership Firms

export const getRemunerationAction = (id, handleCallback) => ({
  type: GET_REMUNERATION,
  payload: { id, handleCallback },
});
export const saveRemunerationAction = (data, handleCallback) => ({
  type: SAVE_REMUNERATION,
  payload: { data, handleCallback },
});
export const deleteRemunerationAction = (data, handleCallback) => ({
  type: DELETE_REMUNERATION,
  payload: { data, handleCallback },
});
export const editRemunerationAction = (data, handleCallback) => ({
  type: UPDATE_REMUNERATION,
  payload: { data, handleCallback },
});

// --- Books of Account are Not Maintained

export const getBooksAccountNotMaintained = (id, handleCallback) => ({
  type: GET_BOOKS_ACCOUNT_NOT_MAINTAINED,
  payload: { id, handleCallback },
});
export const saveBooksAccountNotMaintained = (data, handleCallback) => ({
  type: SAVE_BOOKS_ACCOUNT_NOT_MAINTAINED,
  payload: { data, handleCallback },
});
export const deleteBooksAccountNotMaintained = (data, handleCallback) => ({
  type: DELETE_BOOKS_ACCOUNT_NOT_MAINTAINED,
  payload: { data, handleCallback },
});
export const editBooksAccountNotMaintained = (data, handleCallback) => ({
  type: UPDATE_BOOKS_ACCOUNT_NOT_MAINTAINED,
  payload: { data, handleCallback },
});

//  --- Speculative Income ---

export const getSpeculativeIncome = (id, handleCallback) => ({
  type: GET_SPECULATIVE_INCOME,
  payload: { id, handleCallback },
});
export const saveSpeculativeIncome = (data, handleCallback) => ({
  type: SAVE_SPECULATIVE_INCOME,
  payload: { data, handleCallback },
});
export const deleteSpeculativeIncome = (data, handleCallback) => ({
  type: DELETE_SPECULATIVE_INCOME,
  payload: { data, handleCallback },
});
export const editSpeculativeIncome = (data, handleCallback) => ({
  type: EDIT_SPECULATIVE_INCOME,
  payload: { data, handleCallback },
});

// ------- Financial Statements & Schedules >  Schedule BP > add

export const getScheduleBP = (id, handleCallback) => ({
  type: GET_SCHEDULE_BP,
  payload: { id, handleCallback },
});
export const saveScheduleBP = (data, handleCallback) => ({
  type: SAVE_SCHEDULE_BP,
  payload: { data, handleCallback },
});
export const deleteScheduleBP = (data, handleCallback) => ({
  type: DELETE_SCHEDULE_BP,
  payload: { data, handleCallback },
});
export const editScheduleBP = (data, handleCallback) => ({
  type: EDIT_SCHEDULE_BP,
  payload: { data, handleCallback },
});

// Financial Statements & Schedules >  Schedule BP > other sections > Additions to Profit and Loss Account

export const getAdditionToPAndL = (id, handleCallback) => ({
  type: GET_ADDITION_TO_P_L,
  payload: { id, handleCallback },
});
export const saveAdditionToPAndL = (data, handleCallback) => ({
  type: SAVE_ADDITION_TO_P_L,
  payload: { data, handleCallback },
});
export const deleteAdditionToPAndL = (data, handleCallback) => ({
  type: DELETE_ADDITION_TO_P_L,
  payload: { data, handleCallback },
});
export const editAdditionToPAndL = (data, handleCallback) => ({
  type: EDIT_ADDITION_TO_P_L,
  payload: { data, handleCallback },
});

// Financial Statements & Schedules >  Schedule BP > other sections > Summary of Incomes under section 40 and 43B

export const getDetailOfSection40And43B = (id, handleCallback) => ({
  type: GET_SECTION_40_AND_43B,
  payload: { id, handleCallback },
});
export const saveDetailOfSection40And43B = (data, handleCallback) => ({
  type: SAVE_SECTION_40_AND_43B,
  payload: { data, handleCallback },
});
export const deleteDetailOfSection40And43B = (data, handleCallback) => ({
  type: DELETE_SECTION_40_AND_43B,
  payload: { data, handleCallback },
});
export const editDetailOfSection40And43B = (data, handleCallback) => ({
  type: EDIT_SECTION_40_AND_43B,
  payload: { data, handleCallback },
});

// Financial Statements & Schedules >Schedule OI (Other Information)

// otherbusinessincome/getScheduleOI?itrId=4

export const getScheduleOIAction = (id, handleCallback) => ({
  type: GET_SCHEDULE_OI,
  payload: { id, handleCallback },
});
export const saveScheduleOIAction = (data, handleCallback) => ({
  type: SAVE_SCHEDULE_OI,
  payload: { data, handleCallback },
});
export const deleteScheduleOIAction = (data, handleCallback) => ({
  type: DELETE_SCHEDULE_OI,
  payload: { data, handleCallback },
});

// Other business income > Audit Information >  Audit Information
export const getAuditorDetailsAction = (id, handleCallback) => ({
  type: GET_AUDITOR_DETAILS,
  payload: { id, handleCallback },
});
export const saveAuditorDetailsAction = (data, handleCallback) => ({
  type: SAVE_AUDITOR_DETAILS,
  payload: { data, handleCallback },
});
export const deleteAuditorDetailsAction = (data, handleCallback) => ({
  type: DELETE_AUDITOR_DETAILS,
  payload: { data, handleCallback },
});

// Other business income > Audit Information >  Other audits
export const getOtherAuditsAction = (id, handleCallback) => ({
  type: GET_OTHER_AUDITS_ACTION,
  payload: { id, handleCallback },
});
export const saveOtherAuditsAction = (data, handleCallback) => ({
  type: SAVE_OTHER_AUDITS_ACTION,
  payload: { data, handleCallback },
});
export const editOtherAuditsAction = (data, handleCallback) => ({
  type: EDIT_OTHER_AUDITS_ACTION,
  payload: { data, handleCallback },
});
export const deleteOtherAuditsAction = (data, handleCallback) => ({
  type: DELETE_OTHER_AUDITS_ACTION,
  payload: { data, handleCallback },
});

// Other business income > Audit Information > Audit under Act other than I-T Act
export const getAuditUnderITActAction = (id, handleCallback) => ({
  type: GET_AUDIT_UNDER_IT_ACT,
  payload: { id, handleCallback },
});
export const saveAuditUnderITActAction = (data, handleCallback) => ({
  type: SAVE_AUDIT_UNDER_IT_ACT,
  payload: { data, handleCallback },
});
export const editAuditUnderITActAction = (data, handleCallback) => ({
  type: EDIT_AUDIT_UNDER_IT_ACT,
  payload: { data, handleCallback },
});
export const deleteAuditUnderITActAction = (data, handleCallback) => ({
  type: DELETE_AUDIT_UNDER_IT_ACT,
  payload: { data, handleCallback },
});

//  --------------- Quantitative Details ---------------

// Details of Trading Concern
export const getTradingConcernAction = (id, handleCallback) => ({
  type: GET_TRADING_CONCERN,
  payload: { id, handleCallback },
});
export const saveTradingConcernAction = (data, handleCallback) => ({
  type: SAVE_TRADING_CONCERN,
  payload: { data, handleCallback },
});
export const editTradingConcernAction = (data, handleCallback) => ({
  type: EDIT_TRADING_CONCERN,
  payload: { data, handleCallback },
});
export const deleteTradingConcernAction = (data, handleCallback) => ({
  type: DELETE_TRADING_CONCERN,
  payload: { data, handleCallback },
});

// Details of Manufacturing Concern (Raw Materials)  and (Finished Products)

export const getManufacturingConcernAction = (id, handleCallback) => ({
  type: GET_MANUFACTURING_CONCERN,
  payload: { id, handleCallback },
});
export const saveManufacturingConcernAction = (data, handleCallback) => ({
  type: SAVE_MANUFACTURING_CONCERN,
  payload: { data, handleCallback },
});
export const editManufacturingConcernAction = (data, handleCallback) => ({
  type: EDIT_MANUFACTURING_CONCERN,
  payload: { data, handleCallback },
});
export const deleteManufacturingConcernAction = (data, handleCallback) => ({
  type: DELETE_MANUFACTURING_CONCERN,
  payload: { data, handleCallback },
});


//  -------- Deduction under section IOAA

export const getDeductionIOAAAction = (id, handleCallback) => ({
  type: GET_DEDUCTION_IOAA,
  payload: { id, handleCallback },
});

export const saveDeductionIOAAAction = (data, handleCallback) => ({
  type: SAVE_DEDUCTION_IOAA,
  payload: { data, handleCallback },
});

export const editDeductionIOAAAction = (data, handleCallback) => ({
  type: EDIT_DEDUCTION_IOAA,
  payload: { data, handleCallback },
});

// export const deleteDeductionIOAAAction = (data, handleCallback) => ({
//   type: DELETE_MANUFACTURING_CONCERN,
//   payload: { data, handleCallback },
// });

// ---------- Deductions under section 8O ---------------- 


export const getDeduction80Action = (id, handleCallback) => ({
  type: GET_DEDUCTION_80,
  payload: { id, handleCallback },
});

export const saveDeduction80Action = (data, handleCallback) => ({
  type: SAVE_DEDUCTION_80,
  payload: { data, handleCallback },
});

 // --------- Details of donations to research associations ------------- 

 export const getDonationToResearchAssociationAction = (id, handleCallback) => ({
  type: GET_DONATION_RESEARCH_ASSOCIATION,
  payload: { id, handleCallback },
});
export const saveDonationToResearchAssociationAction = (data, handleCallback) => ({
  type: SAVE_DONATION_RESEARCH_ASSOCIATION,
  payload: { data, handleCallback },
});
export const editDonationToResearchAssociationAction = (data, handleCallback) => ({
  type: EDIT_DONATION_RESEARCH_ASSOCIATION,
  payload: { data, handleCallback },
});
export const deleteDonationToResearchAssociationAction = (data, handleCallback) => ({
  type: DELETE_DONATION_RESEARCH_ASSOCIATION,
  payload: { data, handleCallback },
});

// -----------  Deductions under section 80LA ------------- 

export const getDonationToLAAction = (id, handleCallback) => ({
  type: GET_DONATION_TO_LA,
  payload: { id, handleCallback },
});
export const saveDonationToLAAction = (data, handleCallback) => ({
  type: SAVE_DONATION_TO_LA,
  payload: { data, handleCallback },
});
export const editDonationToLAAction = (data, handleCallback) => ({
  type: EDIT_DONATION_TO_LA,
  payload: { data, handleCallback },
});
export const deleteDonationToLAAction = (data, handleCallback) => ({
  type: DELETE_DONATION_TO_LA,
  payload: { data, handleCallback },
});


// ---------- Deductions under section 8OP ---------------- 

export const getDeduction80PAction = (id, handleCallback) => ({
  type: GET_DEDUCTION_80P,
  payload: { id, handleCallback },
});

export const saveDeduction80PAction = (data, handleCallback) => ({
  type: SAVE_DEDUCTION_80P,
  payload: { data, handleCallback },
});