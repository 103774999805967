import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import Input from "../../../styledComponent/Inputs/Input";
import LoadingBox from "../../DialogBox/LoadingBox";
import {
  editDonationToLAAction,
  getDonationToLAAction,
  saveDonationToLAAction,
} from "../../../redux/itr/action";
import Select from "../../../styledComponent/Inputs/Select";
import { addOpacity } from "../../../utils/helpers";
import FormDialogBox from "../../DialogBox/FormDialogBox";
export default function Schedule80LA({
  formsData,
  LASubSection,
  LATypeOfEntiry,
  LAIncomeOfUnit,
  LAAuthorityGrantingRegistration,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({});
  const [formBox, setFormBox] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    totalExpenses: Yup.number().required(strings.validation_message.required),
    grossRevenue: Yup.number().required(strings.validation_message.required),

    professionalIncome44ADA: Yup.array().of(
      Yup.object().shape({
        professionType: Yup.string().required(
          strings.validation_message.required
        ),
        natureOfProfession: Yup.string().required(
          strings.validation_message.required
        ),
        nameOfCompany: Yup.string().required(
          strings.validation_message.required
        ),
      })
    ),
  });

  const saveSchedule80LAApi = async (obj) => {
    setLoader(true);
    const { type, ...data } = obj; // Destructure type and remaining data
    const action =
      type === "edit" ? editDonationToLAAction : saveDonationToLAAction;

    dispatch(
      action(data, (e) => {
        setLoader(false); // Ensure loader is stopped even if API fails
        if (e.status == 200) {
          setData(data);
          setFormBox(false);
        } else {
          toast.error(e.message);
        }
        setLoader(false);
      })
    );
  };

  const getScheduleDataApi = async () => {
    dispatch(
      getDonationToLAAction(ItrData.id, (e) => {
        console.log("getDonationToLAAction =========>", e.data);
        if (e.status == 200) {
          setData(e.data.deductions[0]);
        } else {
          toast.error(e.message);
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    getScheduleDataApi();
  }, []);

  console.log("data ====>", data);
  return (
    <div>
      <LoadingBox open={loader} />

      {data.id ? (
        <div
          style={{
            background: addOpacity(theme.primary_bg, 0.2),
            borderRadius: "10px",
          }}
        >
          <div className="flex gap-2 p-3 my-3 align-center">
            <h4 className="mr-auto">Total deductions under section 8OLA</h4>
            <h4>{data?.amountOfDeductionClaimedCurrent || 0}</h4>
          </div>
        </div>
      ) : (
        ""
      )}

      <button
        className="primary_button"
        style={{ flex: 0 }}
        onClick={() => {
          if (data.id) {
            setFormData({
              itrId: ItrData.id,
              id: data?.id,
              type: "edit",
              subSectionDeductionClaimed:
                data?.subSectionDeductionClaimed.id || "",
              typeOfInput: data?.typeOfInput.id || "",
              typeOfIncome: data?.typeOfIncome.id || "",
              authorityGrantingRegistration:
                data?.authorityGrantingRegistration.id || "",
              dateOfRegistration: data?.dateOfRegistration || "",
              registrationNumber: data?.registrationNumber || "",
              firstAYDuringDeductionClaimed:
                data?.firstAYDuringDeductionClaimed || "",
              amountOfDeductionClaimedCurrent:
                data?.amountOfDeductionClaimedCurrent || "",
            });
          }
          setFormBox(!formBox);
        }}
      >
        {data.id > 0 ? strings.button.edit : strings.button.add}
      </button>

      <FormDialogBox
        open={formBox}
        formTitle={"Deduction under section 80LA"}
        handleClose={() => {
          setFormBox(false);
        }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            saveSchedule80LAApi(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <div>
              <StyleFormBox>
                <h4 className="mb-4">Deductions under section 80LA</h4>
                <h4>Details of donations to research associations</h4>
                <p>
                  Deduction under sections 35(1)(ii) or 35(1)(iia) or 35(1)(iii)
                  or 35(2AA)
                </p>
                <hr className="my-4" />

                <div className="flex_input my-2 items-center">
                  <label>Sub-section in which deduction is claimed*</label>
                  <Select
                    name={"subSectionDeductionClaimed"}
                    value={values?.subSectionDeductionClaimed}
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      {strings.label.choose_option}
                    </option>
                    {LASubSection?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Type of Entity*</label>
                  <Select
                    name={`typeOfInput`}
                    value={values?.typeOfInput}
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      {strings.label.choose_option}
                    </option>
                    {LATypeOfEntiry?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Type of income of the unit*</label>
                  <Select
                    name={`typeOfIncome`}
                    value={values?.typeOfIncome}
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      {strings.label.choose_option}
                    </option>
                    {LAIncomeOfUnit?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Authority granting registration*</label>
                  <Select
                    name={`authorityGrantingRegistration`}
                    value={values?.authorityGrantingRegistration}
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      {strings.label.choose_option}
                    </option>
                    {LAAuthorityGrantingRegistration?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Authority granting registration*</label>
                  <Input
                    inputType={"date"}
                    name={"dateOfRegistration"}
                    error={errors.dateOfRegistration}
                    value={values.dateOfRegistration}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Registration number*</label>
                  <Input
                    name={"registrationNumber"}
                    error={errors.registrationNumber}
                    value={values.registrationNumber}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-2 items-center">
                  <label>First AY during which deduction is claimed*</label>
                  <Input
                    name={"firstAYDuringDeductionClaimed"}
                    error={errors.firstAYDuringDeductionClaimed}
                    value={values.firstAYDuringDeductionClaimed}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Amount of deduction claimed for current*</label>
                  <Input
                    inputType={"currency"}
                    name={"amountOfDeductionClaimedCurrent"}
                    error={errors.amountOfDeductionClaimedCurrent}
                    value={values.amountOfDeductionClaimedCurrent}
                    onChange={handleChange}
                  />
                </div>
              </StyleFormBox>
              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
